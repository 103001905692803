import {browserLanguage} from '../../i18n/trans';

import {Request} from './request';

export class JsonRequest<T> extends Request<T> {
    constructor(method: string, url: string, queryParams?: {[key: string]: any}) {
        super(method, url, queryParams);

        this.addHeader('Accept', 'application/json');
        this.addHeader('Content-Type', 'application/json');
        this.addHeader('Accept-Language', browserLanguage());
    }
}
