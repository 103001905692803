import {deepEqual} from 'fast-equals';

import {PersistedMoment, ServerMoment, UnpersistedMoment} from '../../models/moment';
import {PersistedTrip} from '../../models/trip';
import {MomentRepository} from '../../repositories/moment_repository';
import {arrayToMapObject, MapObject} from '../../support/array_to_generic_map';
import {toUnpersistedMoment} from '../../transformers/moment';
import {MomentInteractor} from '../moment_interactor';

export class MomentSynchroniser {
    private _momentRepository: MomentRepository;
    private _momentInteractor: MomentInteractor;

    constructor(momentRepository: MomentRepository, momentInteractor: MomentInteractor) {
        this._momentRepository = momentRepository;
        this._momentInteractor = momentInteractor;
    }

    public async synchronise(trips: PersistedTrip[], serverMoments: ServerMoment[]): Promise<PersistedMoment[]> {
        try {
            const locallyStoredMoments = await this._momentRepository.all();
            const locallStoredMomentsMap = arrayToMapObject(locallyStoredMoments, m => m.serverId);
            const serverMomentsMap = arrayToMapObject(serverMoments, sm => sm.serverId);
            const tripsMap = arrayToMapObject(trips, t => t.serverId);

            const removedMoments = this.findRemovedMoments(locallyStoredMoments, serverMomentsMap);
            if (removedMoments.length > 0) {
                await this._momentRepository.destroyMultiple(removedMoments.map(m => m.uuid));
            }

            await this.updateOrStoreServerMoments(serverMoments, locallStoredMomentsMap, tripsMap);
            await this.sendMomentsThatNeedSyncingToServer(locallyStoredMoments);
        } catch (error) {
            console.warn(error);
        }

        return this._momentRepository.all();
    }

    private findRemovedMoments(locallyStoredMoments: PersistedMoment[], serverMomentsMap: MapObject<ServerMoment>) {
        return locallyStoredMoments.filter(
            lsm => lsm.serverId !== undefined && serverMomentsMap[lsm.serverId] === undefined
        );
    }

    public async updateOrStoreServerMoments(
        serverMoments: ServerMoment[],
        locallyStoredMomentsMap: MapObject<PersistedMoment> | null,
        tripsMap: MapObject<PersistedTrip>
    ): Promise<PersistedMoment[]> {
        let localStoredMoments = locallyStoredMomentsMap;
        if (localStoredMoments === null) {
            const moments = await this._momentRepository.all();
            localStoredMoments = arrayToMapObject(moments, m => m.serverId);
        }
        const saveableMoments = serverMoments.map(si => this.getSaveableMoment(si, localStoredMoments!, tripsMap));

        const momentsThatNeedSaving = saveableMoments.filter(item => item.needSaving === true);
        if (momentsThatNeedSaving.length > 0) {
            await this._momentRepository.storeMultiple(momentsThatNeedSaving.map(m => m.item));
        }

        return saveableMoments.map(sm => sm.item);
    }

    private getSaveableMoment(
        serverMoment: ServerMoment,
        locallyStoredMomentsMap: MapObject<PersistedMoment>,
        tripsMap: MapObject<PersistedTrip>
    ): {needSaving: boolean; item: UnpersistedMoment} {
        const momentTrip = tripsMap[serverMoment.serverTripId];
        const localTripId = momentTrip ? momentTrip.uuid : null;
        const localMatchingMoment = locallyStoredMomentsMap[serverMoment.serverId];

        if (localMatchingMoment) {
            const unpersistedMoment = toUnpersistedMoment(serverMoment, localTripId);
            const mergedMoment: PersistedMoment = {
                ...unpersistedMoment,
                uuid: localMatchingMoment.uuid,
                requiresSyncWithServer: false
            };
            if (deepEqual(localMatchingMoment, mergedMoment)) {
                return {needSaving: false, item: mergedMoment};
            } else {
                return {needSaving: true, item: mergedMoment};
            }
        } else {
            return {needSaving: true, item: toUnpersistedMoment(serverMoment, localTripId)};
        }
    }

    private async sendMomentsThatNeedSyncingToServer(persistedMoments: PersistedMoment[]): Promise<PersistedMoment[]> {
        return Promise.all(
            persistedMoments
                .filter(
                    persistedMoment =>
                        persistedMoment.serverId === undefined ||
                        (persistedMoment.requiresSyncWithServer === true && persistedMoment.tripUuid !== null)
                )
                .map(async persistedMoment => {
                    return this._momentInteractor.syncPersistedMomentWithServer(
                        persistedMoment,
                        persistedMoment.tripUuid!
                    );
                })
        );
    }
}
