import {trans} from '../i18n/trans';

export enum ServerType {
    Viewpoint = 1,
    Food = 2,
    Culture = 3,
    Accommodation = 4,
    Nightlife = 5,
    Shopping = 6,
    Transport = 7,
    Excursion = 8,
    Activity = 9,
    Nature = 10,
    Architecture = 11,
    Relaxing = 12,
    Other = 13
}

export enum Type {
    Viewpoint = 'Viewpoint',
    Food = 'Food',
    Culture = 'Culture',
    Accommodation = 'Accommodation',
    Nightlife = 'Nightlife',
    Shopping = 'Shopping',
    Transport = 'Transport',
    Excursion = 'Excursion',
    Activity = 'Activity',
    Nature = 'Nature',
    Architecture = 'Architecture',
    Relaxing = 'Relaxing',
    Other = 'Other'
}

export const TypeOptions = [
    {
        value: Type.Viewpoint,
        label: trans('type.viewpoint')
    },
    {
        value: Type.Accommodation,
        label: trans('type.accommodation')
    },
    {
        value: Type.Nightlife,
        label: trans('type.nightlife')
    },
    {
        value: Type.Shopping,
        label: trans('type.shopping')
    },
    {
        value: Type.Culture,
        label: trans('type.culture')
    },
    {
        value: Type.Transport,
        label: trans('type.transport')
    },
    {
        value: Type.Excursion,
        label: trans('type.excursion')
    },
    {
        value: Type.Food,
        label: trans('type.food')
    },
    {
        value: Type.Activity,
        label: trans('type.activity')
    },
    {
        value: Type.Nature,
        label: trans('type.nature')
    },
    {
        value: Type.Architecture,
        label: trans('type.architecture')
    },
    {
        value: Type.Relaxing,
        label: trans('type.relaxing')
    },
    {
        value: Type.Other,
        label: trans('type.other')
    }
];

export function typeFromServer(type: ServerType): Type {
    switch (type) {
        case ServerType.Viewpoint:
            return Type.Viewpoint;
        case ServerType.Food:
            return Type.Food;
        case ServerType.Culture:
            return Type.Culture;
        case ServerType.Accommodation:
            return Type.Accommodation;
        case ServerType.Nightlife:
            return Type.Nightlife;
        case ServerType.Shopping:
            return Type.Shopping;
        case ServerType.Transport:
            return Type.Transport;
        case ServerType.Excursion:
            return Type.Excursion;
        case ServerType.Activity:
            return Type.Activity;
        case ServerType.Nature:
            return Type.Nature;
        case ServerType.Architecture:
            return Type.Architecture;
        case ServerType.Relaxing:
            return Type.Relaxing;
        case ServerType.Other:
            return Type.Other;
        default:
            return null as never;
    }
}

export function typeToServer(type: Type): ServerType {
    switch (type) {
        case Type.Viewpoint:
            return ServerType.Viewpoint;
        case Type.Food:
            return ServerType.Food;
        case Type.Culture:
            return ServerType.Culture;
        case Type.Accommodation:
            return ServerType.Accommodation;
        case Type.Nightlife:
            return ServerType.Nightlife;
        case Type.Shopping:
            return ServerType.Shopping;
        case Type.Transport:
            return ServerType.Transport;
        case Type.Excursion:
            return ServerType.Excursion;
        case Type.Activity:
            return ServerType.Activity;
        case Type.Nature:
            return ServerType.Nature;
        case Type.Architecture:
            return ServerType.Architecture;
        case Type.Relaxing:
            return ServerType.Relaxing;
        case Type.Other:
            return ServerType.Other;
        default:
            return null as never;
    }
}
