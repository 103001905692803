import {LocalImage, ServerImage} from '../../models/image';

export interface ApiCreateImage {
    id: number;
    file_name: string;
}

export interface ApiImage {
    id: number;
    uuid: string;
    order: number;
    image_id: number;
    moment_id: number;
    title: string;
    description: string;
    date: string;
    lat: number;
    lon: number;
    updated_at: string;
    file_name: string;
}

export interface ApiSaveImageResponse {
    id: number;
}

export class ApiImageTransformer {
    public static toImage(apiImage: ApiImage): ServerImage {
        return {
            uuid: apiImage.uuid,
            order: apiImage.order,
            serverId: apiImage.id,
            serverMomentId: apiImage.moment_id,
            serverImageId: apiImage.image_id,
            lat: apiImage.lat,
            long: apiImage.lon,
            title: apiImage.title,
            description: apiImage.description,
            timestamp: apiImage.date === '0000-00-00 00:00:00' ? 0 : Date.parse(apiImage.date),
            updatedAt: apiImage.updated_at,
            fileName: apiImage.file_name
        };
    }

    public static toImages(apiImages: ApiImage[]): ServerImage[] {
        return apiImages.map(this.toImage);
    }

    public static fromImage(image: LocalImage): Partial<ApiImage> {
        return {
            id: image.serverId,
            uuid: image.uuid,
            image_id: image.serverImageId,
            moment_id: image.serverMomentId,
            title: image.title.length ? image.title : ' ',
            description: image.description.length ? image.description : ' ',
            date: image.timestamp === 0 ? '0000-00-00 00:00:00' : new Date(image.timestamp).toISOString(),
            lat: image.lat,
            lon: image.long,
            updated_at: image.updatedAt,
            order: image.order
        };
    }
}
