import * as React from 'react';

import '../../styles/components/Loader.css';
import {classNames} from '../../support/classnames';

interface OwnProps {
    inline?: boolean;
}

export class Loader extends React.Component<OwnProps, {}> {
    public render() {
        return (
            <div className={classNames('loader', {inline: this.props.inline})}>
                <div className="one" />
                <div className="two" />
                <div />
            </div>
        );
    }
}

// tslint:disable-next-line:max-classes-per-file
export class FullscreenLoader extends React.Component {
    public render() {
        return (
            <div className="fullscreen-loader">
                <Loader />
            </div>
        );
    }
}
