import * as React from 'react';

import '../../styles/scaffold/Article.css';

interface OwnProps {
    title?: string;
}

export class Article extends React.PureComponent<OwnProps, {}> {
    public render() {
        return (
            <article>
                {this.props.title ? <h2>{this.props.title}</h2> : null}
                {this.props.children}
            </article>
        );
    }
}
