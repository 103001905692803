export interface MapObject<T> {
    [key: string]: T;
}

export function arrayToMapObject<T>(
    items: ReadonlyArray<T>,
    keySelector: (item: T) => string | number | undefined
): MapObject<T> {
    return items.reduce(
        (acc, item) => {
            const key = keySelector(item);
            if (key === undefined) {
                return acc;
            }
            acc[String(key)] = item;
            return acc;
        },
        {} as MapObject<T>
    );
}
