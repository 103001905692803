import {observable} from 'mobx';

import {ImageInteractor} from '../business/image_interactor';
import {LocalImage} from '../models/image';
import {CompositeSubscription} from '../support/composit_subscription';
import {Presenter} from '../support/with_presenter';

export class ImagesProviderPresenter implements Presenter {
    @observable
    public loading = true;
    @observable
    public images: LocalImage[] | null = null;
    private _momentUuid: string;
    private _imageInteractor: ImageInteractor;
    private _subscriptions = new CompositeSubscription();

    constructor(momentUuid: string, imageInteractor: ImageInteractor) {
        this._momentUuid = momentUuid;
        this._imageInteractor = imageInteractor;
    }

    public mount(): void {
        this._subscriptions.add(
            this._imageInteractor.imagesMap.subscribe(imagesMap => {
                this.images = imagesMap.get(this._momentUuid) || [];
                this.loading = false;
            })
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }
}
