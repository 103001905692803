import {computed, observable} from 'mobx';
import {of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

import {AccountInteractor} from '../business/account_interactor';
import {MomentInteractor} from '../business/moment_interactor';
import {PageEnum, PageInteractor} from '../business/page_interactor';
import {TripInteractor} from '../business/trip_interactor';
import {ServerAppAuth} from '../models/app_auth';
import {PersistedMoment} from '../models/moment';
import {PersistedTrip} from '../models/trip';
import {CompositeSubscription} from '../support/composit_subscription';
import {delayedNull} from '../support/delayed_null_subscription';
import {Presenter} from '../support/with_presenter';

export class MomentProviderPresenter implements Presenter {
    private _pageInteractor: PageInteractor;
    @computed
    public get loading() {
        return this._loadingMoment || this._loadingAccount || this._loadingTrip;
    }

    @observable
    private _loadingMoment = true;
    @observable
    private _loadingAccount = true;
    @observable
    private _loadingTrip = true;
    @observable
    public moment: PersistedMoment | null = null;
    @observable
    private _trip?: PersistedTrip | null = null;
    @observable
    private _appAuth: ServerAppAuth | null = null;

    @computed
    get canEditMoment() {
        if (this.moment === null) {
            return false;
        }

        //if no serverTrip, it is local so this user owns it
        if (this.moment.serverTripId === undefined) {
            return true;
        }

        //else check if the current userId matches the trip its accountId
        if (this._appAuth === null || !this._trip || !this.moment) {
            return false;
        }

        return this._appAuth.account.id === this._trip.accountId;
    }

    private _momentUuid: string;
    private _momentInteractor: MomentInteractor;
    private _accountInteractor: AccountInteractor;
    private _tripInteractor: TripInteractor;

    private _subscriptions = new CompositeSubscription();

    constructor(
        momentUuid: string,
        momentInteractor: MomentInteractor,
        accountInteractor: AccountInteractor,
        tripInteractor: TripInteractor,
        pageInteractor: PageInteractor
    ) {
        this._momentUuid = momentUuid;
        this._momentInteractor = momentInteractor;
        this._accountInteractor = accountInteractor;
        this._tripInteractor = tripInteractor;
        this._pageInteractor = pageInteractor;
    }

    public mount(): void {
        this._subscriptions.add(
            delayedNull(this._momentInteractor.byMomentUuid(this._momentUuid)).subscribe(moment => {
                if (moment) {
                    this.moment = moment;
                    this._loadingMoment = false;
                } else {
                    this._pageInteractor.navigate(PageEnum.TRIPS_INDEX);
                }
            })
        );

        this._subscriptions.add(
            this._accountInteractor.appAuth().subscribe(appAuth => {
                this._appAuth = appAuth;
                this._loadingAccount = false;
            })
        );
        this._subscriptions.add(
            this._momentInteractor
                .byMomentUuid(this._momentUuid)
                .pipe(
                    mergeMap((moment: PersistedMoment | null) => {
                        if (moment === null || moment.tripUuid === undefined || moment.tripUuid === null) {
                            return of(null);
                        }
                        return this._tripInteractor.tripByUuid(moment.tripUuid);
                    })
                )
                .subscribe((trip: PersistedTrip | null) => {
                    if (trip) {
                        this._trip = trip;
                    }
                    this._loadingTrip = false;
                })
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }
}
