import {LocalforageImageRepository} from './image/localforage_image_repository';
import {ImageRepository} from './image_repository';
import {LocalForageMomentRepository} from './moment/localforage_moment_repository';
import {MomentRepository} from './moment_repository';
import {LocalForageTripRepository} from './trip/localforage_trip_repository';
import {TripRepository} from './trip_repository';

export class PersistenceComponent {
    private _momentRepository?: MomentRepository;
    private _imageRepository?: ImageRepository;
    private _tripRepository?: TripRepository;

    get momentRepository(): MomentRepository {
        if (this._momentRepository === undefined) {
            this._momentRepository = new LocalForageMomentRepository();
        }
        return this._momentRepository;
    }

    get imageRepository(): ImageRepository {
        if (this._imageRepository === undefined) {
            this._imageRepository = new LocalforageImageRepository();
        }
        return this._imageRepository;
    }

    get tripRepository(): TripRepository {
        if (this._tripRepository === undefined) {
            this._tripRepository = new LocalForageTripRepository();
        }
        return this._tripRepository;
    }
}
