import {Plugin, UppyFile} from '@uppy/core';
import {v4 as uuidv4} from 'uuid';

import {ImageExif} from '../business/image/exif';
import {UppyMetaData} from '../business/uppy_interactor';

const loadImage = require('blueimp-load-image');
require('blueimp-canvas-to-blob');

export class ResizeAndCoordsPlugin extends Plugin {
    private momentUuid: string;

    constructor(uppy: any, opts: {id?: string; momentUuid: string}) {
        super(uppy, opts);
        this.id = opts.id || 'ResizeAndCoordsPlugin';
        this.type = 'example';
        this.momentUuid = opts.momentUuid;
        this.prepareUpload = this.prepareUpload.bind(this);
    }

    public async prepareUpload(fileIDs: string[]) {
        return new Promise(async outerResolve => {
            await Promise.all(
                fileIDs.map(async fileID => {
                    return new Promise(innerResolve => {
                        const file = this.uppy.getFile<UppyMetaData>(fileID);

                        loadImage(
                            file.data,
                            (img: HTMLCanvasElement, data: {exif: any[] | undefined; imageHead: ArrayBuffer}) => {
                                img.toBlob(
                                    (blob: Blob | null) => {
                                        if (blob !== null) {
                                            const fileId = file.meta && file.meta.fileId ? file.meta.fileId : uuidv4();
                                            const newFileProperties: Partial<UppyFile<UppyMetaData>> = {
                                                data: blob,
                                                meta: {
                                                    uuid: file.meta.uuid ? file.meta.uuid : uuidv4(),
                                                    coordinateData: ImageExif.getGPSCoordinatesFromExif(data.exif),
                                                    momentUuid: this.momentUuid,
                                                    fileId,
                                                    ...file.meta
                                                }
                                            };
                                            const newFile = Object.assign({}, file, newFileProperties);
                                            this.uppy.setFileState(fileID, newFile);
                                            innerResolve();
                                        }
                                    },
                                    'image/jpeg',
                                    0.85
                                );
                            },
                            {
                                maxWidth: 2500,
                                maxHeight: 2500,
                                orientation: true,
                                meta: true,
                                canvas: true,
                                crossOrigin: true
                            }
                        );
                    });
                })
            );

            window.requestAnimationFrame(() => {
                setTimeout(() => {
                    outerResolve();
                }, 500);
            });
        });
    }

    public install() {
        this.uppy.addPreProcessor(this.prepareUpload);
    }

    public uninstall() {
        this.uppy.removePreProcessor(this.prepareUpload);
    }
}
