import {AuthFlowHandler} from './auth_flow_handler';
import {AccountInteractor} from './business/account_interactor';
import {BusinessComponent} from './business/business_component';
import {NetworkComponent, NetworkComponentImpl} from './network/network_components';
import {PersistenceComponent} from './repositories/PersistenceComponent';

export interface Component {
    persistence: PersistenceComponent;
    business: BusinessComponent;
    network: NetworkComponent;
    accountInteractor: AccountInteractor;
    authFlowHandler: AuthFlowHandler;
}

class ComponentImpl implements Component {
    private _persistence?: PersistenceComponent;
    private _business?: BusinessComponent;
    private _network?: NetworkComponent;
    private _accountInteractor?: AccountInteractor;
    private _authFlowHandler?: AuthFlowHandler;

    get persistence(): PersistenceComponent {
        if (this._persistence === undefined) {
            this._persistence = new PersistenceComponent();
        }
        return this._persistence;
    }

    get business(): BusinessComponent {
        if (this._business === undefined) {
            this._business = new BusinessComponent(this.accountInteractor, this.persistence, this.network);
        }
        return this._business;
    }

    get network(): NetworkComponent {
        if (this._network === undefined) {
            this._network = new NetworkComponentImpl(this.accountInteractor);
        }
        return this._network;
    }

    get accountInteractor(): AccountInteractor {
        if (this._accountInteractor === undefined) {
            this._accountInteractor = new AccountInteractor();
        }
        return this._accountInteractor;
    }

    get authFlowHandler(): AuthFlowHandler {
        if (this._authFlowHandler === undefined) {
            this._authFlowHandler = new AuthFlowHandler(this.accountInteractor);
        }
        return this._authFlowHandler;
    }
}

let instance: Component;

export function component(): Component {
    if (instance === undefined) {
        instance = new ComponentImpl();
    }

    return instance;
}
