import {AccountInteractor} from '../../business/account_interactor';
import {ServerMoment} from '../../models/moment';
import {PersistedTrip, ServerTrip} from '../../models/trip';
import {ApiAccount} from '../authorization/api_account_transformer';
import {ApiMomentTransformer, ApiMomentWithAccount} from '../moment/api_moment_transformer';
import {AuthorizationInterceptor} from '../request/interceptors/authorization_interceptor';
import {JsonRequest} from '../request/json_request';
import {RequestExecutor} from '../request/request_executor';

import {ApiTripTransformer} from './api_trip_transformer';

export interface TripApi {
    getMyTrips(): Promise<ServerTrip[]>;
    create(trip: PersistedTrip, reCaptcha: string): Promise<ServerTrip>;
    update(persistedTrip: PersistedTrip): Promise<ServerTrip>;
    getSharedTrip(secretTripUuid: string): Promise<{trip: ServerTrip; moments: ServerMoment[]}>;
}

export interface ApiTrip {
    id?: number;
    primary_image_id: number | null;
    uuid: string;
    secret_uuid?: string;
    destination: string;
    departure_date: string;
    return_date: string;
    account_id: number;
    booking_id: string | null;
    updated_at: string;
    re_captcha?: string;
}

export interface ApiTripWithAccount extends ApiTrip {
    account: ApiAccount;
}

export interface ApiSharedTrip {
    trip: ApiTripWithAccount;
    moments: ApiMomentWithAccount[];
}

export class TripApiImpl implements TripApi {
    private _requestExecutor: RequestExecutor;

    constructor(accountInteractor: AccountInteractor) {
        this._requestExecutor = new RequestExecutor();
        this._requestExecutor.addInterceptor(new AuthorizationInterceptor(accountInteractor));
    }

    public async getMyTrips(): Promise<ServerTrip[]> {
        const request = new JsonRequest<ApiTripWithAccount[]>('GET', 'me/trips');
        const response = await this._requestExecutor.execute<ApiTripWithAccount[]>(request);

        return ApiTripTransformer.toServerTrips(response.result);
    }

    public async create(trip: PersistedTrip, reCaptcha: string): Promise<ServerTrip> {
        const request = new JsonRequest<ApiTripWithAccount>('POST', `trips`).setJson(
            ApiTripTransformer.fromTrip(trip, reCaptcha)
        );

        const response = await this._requestExecutor.execute<ApiTripWithAccount>(request);
        return ApiTripTransformer.toServerTrip(response.result);
    }

    public async update(trip: PersistedTrip): Promise<ServerTrip> {
        if (trip.serverId === undefined) {
            throw new Error('This trip is not synced with server before, skipping update');
        }
        const request = new JsonRequest<ApiTripWithAccount>('POST', `trips/${trip.serverId}`).setJson(
            ApiTripTransformer.fromTrip(trip)
        );

        const response = await this._requestExecutor.execute<ApiTripWithAccount>(request);
        return ApiTripTransformer.toServerTrip(response.result);
    }

    public async getSharedTrip(secretTripUuid: string): Promise<{trip: ServerTrip; moments: ServerMoment[]}> {
        const request = new JsonRequest<ApiSharedTrip>('GET', 'shared/trip/' + secretTripUuid);
        const response = await this._requestExecutor.execute<ApiSharedTrip>(request);

        const trip = ApiTripTransformer.toServerTrip(response.result.trip);
        const moments = ApiMomentTransformer.toServerMomentArray(response.result.moments);

        return {trip, moments};
    }
}
