import {observer} from 'mobx-react';
import * as React from 'react';

import {trans} from '../i18n/trans';
import {PersistedMoment} from '../models/moment';
import {PresenterProps, withPresenter} from '../support/with_presenter';

import {Error} from './components/error';
import {Loader} from './components/loader';
import {MomentProviderPresenter} from './moment_provider_presenter';
import {Page} from './scaffold/page';

interface OwnProps {
    momentUuid: string;
    requiresOwnership?: boolean;
    silent?: boolean;
    render: (moment: PersistedMoment, hasOwnership: boolean) => React.ReactNode;
}

@observer
class MomentProvider extends React.Component<OwnProps & PresenterProps<MomentProviderPresenter>> {
    public render() {
        if (this.props.presenter.loading) {
            if (this.props.silent === true) {
                return null;
            }
            return (
                <Page>
                    <Loader />
                </Page>
            );
        }
        if (this.props.presenter.moment === null) {
            if (this.props.silent === true) {
                return null;
            }
            return (
                <Page>
                    <Error message={trans('errors.moment_not_found')} />
                </Page>
            );
        }
        if (this.props.requiresOwnership === true && !this.props.presenter.canEditMoment) {
            if (this.props.silent === true) {
                return null;
            }
            return (
                <Page>
                    <Error message={trans('errors.unauthorized')} />
                </Page>
            );
        }

        return this.props.render(this.props.presenter.moment, this.props.presenter.canEditMoment);
    }
}

export default withPresenter<MomentProviderPresenter, OwnProps>(
    (props, component) =>
        new MomentProviderPresenter(
            props.momentUuid,
            component.business.momentInteractor,
            component.accountInteractor,
            component.business.tripInteractor,
            component.business.pageInteractor
        ),
    MomentProvider
);
