import {observer} from 'mobx-react';
import * as React from 'react';

import {PageEnum} from '../../../business/page_interactor';
import {trans} from '../../../i18n/trans';
import {RegisterPresenter} from '../../../presenters/register_presenter';
import {Button} from '../../components/button';
import {Divider} from '../../components/divider';
import {Checkbox} from '../../components/form/checkbox';
import {ValidatorInput} from '../../components/form/validator';
import {Logo} from '../../components/logo';
import {ReCaptcha} from '../../components/recaptcha';
import {PanelHeading} from '../../components/web/panel_heading';
import {Wrapper} from '../../scaffold/wrapper';

interface OwnProps {
    presenter: RegisterPresenter;
    renderCancelButton: boolean;
}

@observer
export class RegisterForm extends React.Component<OwnProps, {}> {
    public render() {
        return (
            <form onSubmit={this.props.presenter.submitLogin}>
                <Logo />

                <PanelHeading align="center" title={trans('register.title')} />

                <ValidatorInput
                    label={trans('labels.email')}
                    id="form-email"
                    name="email"
                    disabled={this.props.presenter.isEmailDisabled}
                    fieldState={this.props.presenter.form.$.email}
                    type="email"
                    autoComplete="username"
                    placeholder={trans('placeholders.email')}
                />

                <ValidatorInput
                    label={trans('labels.first_name')}
                    id="form-first_name"
                    name="first_name"
                    autoComplete="given-name"
                    fieldState={this.props.presenter.form.$.firstName}
                    placeholder={trans('placeholders.first_name')}
                />

                <ValidatorInput
                    label={trans('labels.last_name')}
                    id="form-last_name"
                    name="last_name"
                    autoComplete="family-name"
                    fieldState={this.props.presenter.form.$.lastName}
                    placeholder={trans('placeholders.last_name')}
                />

                <ValidatorInput
                    label={trans('labels.password')}
                    id="form-password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    fieldState={this.props.presenter.form.$.password}
                    placeholder={trans('placeholders.password')}
                />

                <ValidatorInput
                    label={trans('labels.password_repeat')}
                    id="form-password_repeat"
                    name="password_repeat"
                    type="password"
                    autoComplete="new-password"
                    fieldState={this.props.presenter.form.$.passwordRepeat}
                    placeholder={trans('placeholders.password_repeat')}
                />

                <Wrapper type="input">
                    <Checkbox
                        id="form-accept_terms"
                        name="accept_terms"
                        checked={this.props.presenter.form.$.acceptTerms.value}
                        onChange={e => this.props.presenter.form.$.acceptTerms.onChange(e.target.checked)}
                        onBlur={this.props.presenter.form.$.acceptTerms.enableAutoValidationAndValidate}
                    />
                    <label className="Label" htmlFor="form-accept_terms">
                        {trans('labels.accept_terms_first')}{' '}
                        <a
                            ga-on="click"
                            ga-event-category={PageEnum.REGISTER_SHOW}
                            ga-event-action="Open-terms-of-service"
                            title="Open terms of service"
                            aria-label="Open terms of service"
                            href="https://www.mytravelmoments.com/voorwaarden.html"
                            target="_blank"
                            rel="noopener noreferrer">
                            {trans('labels.accept_terms_service')}
                        </a>{' '}
                        {trans('labels.accept_terms_middle')}{' '}
                        <a
                            ga-on="click"
                            ga-event-category={PageEnum.REGISTER_SHOW}
                            ga-event-action="Open-privacy-policy"
                            title="Open privacy policy"
                            aria-label="Open privacy policy"
                            href="https://www.mytravelmoments.com/privacy.html"
                            target="_blank"
                            rel="noopener noreferrer">
                            {trans('labels.accept_terms_privacy')}
                        </a>{' '}
                        {trans('labels.accept_terms_end')}
                    </label>
                    {this.props.presenter.form.$.acceptTerms.hasError === true && (
                        <div className="validation warning">{this.props.presenter.form.$.acceptTerms.error}</div>
                    )}
                </Wrapper>

                <Wrapper>
                    <ReCaptcha
                        onChange={token => {
                            if (token) {
                                this.props.presenter.form.$.reCaptcha.onChange(token);
                            }
                        }}
                    />
                    {this.props.presenter.form.$.reCaptcha.hasError === true && (
                        <div className="validation warning">{this.props.presenter.form.$.reCaptcha.error}</div>
                    )}
                </Wrapper>

                <Divider />

                <Wrapper type="button">
                    <Button
                        floatDirection="right"
                        appearance="primary"
                        width={this.props.renderCancelButton ? undefined : 'full'}
                        type="submit"
                        action="Register"
                        disabled={this.props.presenter.form.hasError}>
                        {trans('buttons.register')}
                    </Button>

                    {this.props.renderCancelButton && (
                        <Button
                            floatDirection="left"
                            appearance="secondary"
                            action="Cancel"
                            onClick={() => this.props.presenter.onCancelClick()}>
                            {trans('buttons.cancel')}
                        </Button>
                    )}
                </Wrapper>
            </form>
        );
    }
}
