import {observer} from 'mobx-react';
import * as React from 'react';

import {trans} from '../../../i18n/trans';
import {LoginPresenter} from '../../../presenters/login_presenter';
import {Button} from '../../components/button';
import {ValidatorInput} from '../../components/form/validator';
import {Logo} from '../../components/logo';
import {PanelHeading} from '../../components/web/panel_heading';

interface OwnProps {
    presenter: LoginPresenter;
}

@observer
export class LoginForm extends React.Component<OwnProps, {}> {
    public render() {
        return (
            <form onSubmit={this.props.presenter.submitLogin} method="post" action="#">
                <Logo />
                <PanelHeading align="center" title={trans('login.title')} />

                <ValidatorInput
                    label={trans('labels.email')}
                    id="form-email"
                    name="email"
                    fieldState={this.props.presenter.form.$.email}
                    type="email"
                    autoComplete="username"
                    placeholder={trans('placeholders.email')}
                />

                <ValidatorInput
                    label={trans('labels.password')}
                    id="form-password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    fieldState={this.props.presenter.form.$.password}
                    placeholder={trans('placeholders.password')}
                />

                <Button
                    floatDirection="right"
                    appearance="primary"
                    type="submit"
                    width="full"
                    title={trans('buttons.login')}
                    action="Login"
                    disabled={this.props.presenter.form.hasError}>
                    {trans('buttons.login')}
                </Button>
            </form>
        );
    }
}
