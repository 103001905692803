import {enTranslations} from './translations/en';
import {nlTranslations} from './translations/nl';

export interface TranslationObject {
    [key: string]: string | TranslationObject | TranslationFunction;
}
type TranslationFunction = (params: {[key: string]: any}) => string;
type TranslationResult = TranslationObject | null | string | TranslationFunction;

interface SupportedTranslations {
    en: TranslationObject;
    nl: TranslationObject;
}

export const supportedTranslations: SupportedTranslations = {
    en: enTranslations,
    nl: nlTranslations
};

export function browserLanguage(): keyof SupportedTranslations {
    if (navigator.language.includes('en')) {
        return 'en';
    }
    if (navigator.language.includes('nl')) {
        return 'nl';
    }

    return 'en';
}

/**
 * @param path dot notation path e.g. "moment.create.succeeded"
 * @param language alpha2 code of any supported language, defaults to 'en'
 */
export function trans(
    path: string,
    params: {[key: string]: any} = {},
    language: keyof SupportedTranslations = browserLanguage()
): string {
    const translationObject = supportedTranslations[language];

    const result = path
        .split('.')
        .reduce((acc: TranslationResult | null, key) => (acc ? acc[key] : null), translationObject);

    if (result === null) {
        console.warn(`No translation result for path ${path}.`);
        if (language !== 'en') {
            return trans(path, params, 'en');
        }
        return '';
    }

    if (typeof result === 'object') {
        console.warn(`Translations result of path ${path} results in an object, try an deeper path.`);
        if (language !== 'en') {
            return trans(path, params, 'en');
        }
        return '';
    }

    if (typeof result === 'function') {
        return result(params);
    }

    return result;
}

export function getTransObject(
    path: string,
    language: keyof SupportedTranslations = browserLanguage()
): TranslationObject | null {
    const translationObject = supportedTranslations[language];

    const result = path
        .split('.')
        .reduce<TranslationResult>((acc: TranslationResult, key) => (acc ? acc[key] : null), translationObject);

    if (result === null) {
        console.warn(`No translation result for path ${path}.`);
        if (language !== 'en') {
            return getTransObject(path, 'en');
        }
        return null;
    }

    if (typeof result !== 'object') {
        console.warn(
            `Translations result of path ${path} results in something else then an object, is the given path too deep?`
        );
        if (language !== 'en') {
            return getTransObject(path, 'en');
        }
        return null;
    }

    return result;
}
