import * as React from 'react';

import {Component, component} from '../component';

// Props you want the resulting component to take (besides the props of the wrapped component)
interface ExternalProps {}

export interface Presenter {
    mount(): any;
    unmount(): any;
}

export interface PresenterProps<P extends Presenter> {
    presenter: P;
}

interface OptionalPresenter<P extends Presenter> {
    presenter?: P;
    [key: string]: any;
    [key: number]: any;
}

export function withPresenter<TPresenter extends Presenter, TOriginalProps extends OptionalPresenter<TPresenter>>(
    presenter: ((props: TOriginalProps, component: Component) => TPresenter),
    UndecoratedComponent:
        | React.ComponentType<TOriginalProps & PresenterProps<TPresenter>>
        | React.ComponentType<PresenterProps<TPresenter>>,
    options?: {passProps?: boolean}
) {
    return class WithPresenterComponent extends React.Component<TOriginalProps, {}> {
        // Define how your HOC is shown in ReactDevTools
        public static displayName = `WithPresenterComponent(${UndecoratedComponent.displayName ||
            UndecoratedComponent.name})`;
        private _presenter?: TPresenter;

        public componentDidMount() {
            this.getPresenter().mount();
        }

        public componentWillUnmount() {
            this.getPresenter().unmount();
        }

        private getPresenter(): TPresenter {
            if (this.props.presenter !== undefined) {
                return this.props.presenter as TPresenter;
            }
            if (this._presenter === undefined) {
                this._presenter = presenter(this.props, component());
            }
            return this._presenter;
        }

        public render() {
            const shouldPassProps = options ? options.passProps === false : true;

            if (shouldPassProps) {
                const decoratedProps: TOriginalProps & PresenterProps<TPresenter> = Object.assign({}, this.props, {
                    presenter: this.getPresenter()
                });
                return React.createElement<TOriginalProps & PresenterProps<TPresenter>>(
                    UndecoratedComponent as React.ComponentType<TOriginalProps & PresenterProps<TPresenter>>,
                    decoratedProps
                );
            } else {
                const decoratedProps: PresenterProps<TPresenter> = Object.assign({}, this.props, {
                    presenter: this.getPresenter()
                });
                return React.createElement<PresenterProps<TPresenter>>(
                    UndecoratedComponent as React.ComponentType<PresenterProps<TPresenter>>,
                    decoratedProps
                );
            }
        }
    };
}
