import {observer} from 'mobx-react';
import * as React from 'react';

import {websiteUrl} from '../../../app_config';
import {PageEnum} from '../../../business/page_interactor';
import {Component} from '../../../component';
import {trans} from '../../../i18n/trans';
import {NavPresenter} from '../../../presenters/nav_presenter';
import '../../../styles/components/web/Nav.css';
import {classNames} from '../../../support/classnames';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {Link} from '../link';
import {Logo} from '../logo';

interface OwnProps {
    title?: string;
}

@observer
class Nav extends React.Component<OwnProps & PresenterProps<NavPresenter>, {}> {
    private isCurrentPage(page: PageEnum) {
        const navigationState = this.props.presenter.currentNavigationState;
        return navigationState !== null && navigationState.page === page;
    }

    public render() {
        return (
            <nav
                className={classNames('Nav', {
                    'menu-open': this.props.presenter.menuOpen
                })}>
                <div>
                    <div onClick={this.props.presenter.menuTriggerClicked} className="hamburger">
                        <span />
                    </div>

                    <div className="logo-wrapper">
                        <a href={websiteUrl} rel="logo" aria-label="logo">
                            <Logo />
                        </a>
                    </div>
                    <ul className="menu">
                        <li
                            onClick={this.props.presenter.closeMenu}
                            className={classNames({
                                current: this.isCurrentPage(PageEnum.MOMENTS_INDEX)
                            })}>
                            <Link page={PageEnum.MOMENTS_INDEX} action="menu-moments-index">
                                {trans('nav.moments_index')}
                            </Link>
                        </li>

                        {this.props.presenter.isAuthenticated && (
                            <li
                                onClick={this.props.presenter.closeMenu}
                                className={classNames({
                                    current: this.isCurrentPage(PageEnum.TRIPS_INDEX)
                                })}>
                                <Link page={PageEnum.TRIPS_INDEX} action="menu-trips-index">
                                    {trans('nav.trips')}
                                </Link>
                            </li>
                        )}

                        {this.props.presenter.isAuthenticated && (
                            <li
                                onClick={this.props.presenter.closeMenu}
                                className={classNames({
                                    current: this.isCurrentPage(PageEnum.PHOTOALBUM_EXPLANATION)
                                })}>
                                <Link page={PageEnum.PHOTOALBUM_EXPLANATION} action="menu-photoalbum-explanation">
                                    {trans('nav.photo_album')}
                                </Link>
                            </li>
                        )}

                        {this.props.presenter.isAuthenticated && (
                            <li
                                onClick={this.props.presenter.closeMenu}
                                className={classNames({
                                    current: this.isCurrentPage(PageEnum.MY_ACCOUNT)
                                })}>
                                <Link page={PageEnum.MY_ACCOUNT} action="menu-my-account">
                                    {trans('nav.account')}
                                </Link>
                            </li>
                        )}

                        <li
                            onClick={this.props.presenter.closeMenu}
                            className={classNames({
                                current: this.isCurrentPage(PageEnum.LOGIN_SHOW)
                            })}>
                            {this.props.presenter.isAuthenticated ? (
                                <a
                                    href={websiteUrl}
                                    onClick={this.props.presenter.logoutClicked}
                                    rel="logout"
                                    aria-label="logout">
                                    {trans('nav.logout')}
                                </a>
                            ) : (
                                <Link className="border" page={PageEnum.LOGIN_SHOW} action="menu-login">
                                    {trans('nav.login')}
                                </Link>
                            )}
                        </li>

                        {!this.props.presenter.isAuthenticated && (
                            <li
                                onClick={this.props.presenter.closeMenu}
                                className={classNames({
                                    current: this.isCurrentPage(PageEnum.REGISTER_SHOW)
                                })}>
                                <span>or</span>
                                <Link className="highlighted" page={PageEnum.REGISTER_SHOW} action="menu-register">
                                    {trans('nav.register')}
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>

                {this.props.children}
            </nav>
        );
    }
}

export default withPresenter<NavPresenter, OwnProps>(
    (props: OwnProps, component: Component) =>
        new NavPresenter(
            component.accountInteractor,
            component.business.pageInteractor,
            component.business.colorsInteractor
        ),
    Nav
);
