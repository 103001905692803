import {observer} from 'mobx-react';
import * as React from 'react';

import {trans} from '../i18n/trans';
import {LocalImage} from '../models/image';
import {PresenterProps, withPresenter} from '../support/with_presenter';

import {Error} from './components/error';
import {Loader} from './components/loader';
import {ImageProviderPresenter} from './image_provider_presenter';
import {Page} from './scaffold/page';

interface OwnProps {
    imageId: string;
    render: (image: LocalImage) => React.ReactNode;
}

@observer
class ImageProvider extends React.Component<OwnProps & PresenterProps<ImageProviderPresenter>> {
    public render() {
        if (this.props.presenter.loading) {
            return (
                <Page>
                    <Loader />
                </Page>
            );
        }
        if (this.props.presenter.image === null) {
            return (
                <Page>
                    <Error message={trans('errors.image_not_found')} />
                </Page>
            );
        }
        return this.props.render(this.props.presenter.image);
    }
}

export default withPresenter<ImageProviderPresenter, OwnProps>(
    (props, component) =>
        new ImageProviderPresenter(
            props.imageId,
            component.business.imageInteractor,
            component.business.pageInteractor
        ),
    ImageProvider
);
