import {PersistedTrip, ServerTrip} from '../../models/trip';
import {TripApi} from '../../network/trips/trip_api';
import {TripRepository} from '../../repositories/trip_repository';

export class TripSynchroniser {
    private _tripRepository: TripRepository;
    private _tripApi: TripApi;

    constructor(tripRepository: TripRepository, tripApi: TripApi) {
        this._tripRepository = tripRepository;
        this._tripApi = tripApi;
    }

    public async synchronise(): Promise<PersistedTrip[]> {
        const serverTrips = await this._tripApi.getMyTrips();

        const locallyStoredTrips = await this._tripRepository.all();

        const deletedLocalTrips = this.findDeletedLocalTrips(serverTrips, locallyStoredTrips);
        if (deletedLocalTrips.length > 0) {
            await this._tripRepository.destroyMultiple(deletedLocalTrips.map(dlt => dlt.uuid));
        }

        const newServerTrips = this.findNewServerTrips(serverTrips, locallyStoredTrips);
        await this._tripRepository.storeMultiple(newServerTrips);

        return await this._tripRepository.all();
    }

    private findNewServerTrips(serverTrips: ServerTrip[], locallyStoredTrips: PersistedTrip[]): ServerTrip[] {
        return serverTrips.filter(
            serverTrip =>
                !locallyStoredTrips.some(locallyStoredTrip => locallyStoredTrip.serverId === serverTrip.serverId)
        );
    }

    private findDeletedLocalTrips(serverTrips: ServerTrip[], locallyStoredTrips: PersistedTrip[]): PersistedTrip[] {
        return locallyStoredTrips.filter(
            lt => lt.serverId !== undefined && serverTrips.every(st => st.serverId !== lt.serverId)
        );
    }
}
