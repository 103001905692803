import {Observable} from 'rxjs';

import {PersistedTrip, UnpersistedTrip} from '../models/trip';
import {TripApi} from '../network/trips/trip_api';
import {TripRepository} from '../repositories/trip_repository';

import {TripSynchroniser} from './trip/trip_synchroniser';

export class TripInteractor {
    private _tripApi: TripApi;
    private _tripSynchronizer: TripSynchroniser;
    private _tripRepository: TripRepository;

    constructor(tripRepository: TripRepository, tripApi: TripApi, tripSynchronizer: TripSynchroniser) {
        this._tripRepository = tripRepository;
        this._tripApi = tripApi;
        this._tripSynchronizer = tripSynchronizer;
    }

    public async synchronise() {
        return this._tripSynchronizer.synchronise();
    }

    /**
     * Stores a fresh trip
     * @param unpersistedTrip
     * @returns {Promise<PersistedTrip>}
     */
    public async createTrip(unpersistedTrip: UnpersistedTrip, reCaptcha: string): Promise<PersistedTrip> {
        const persistedTrip = await this._tripRepository.store(unpersistedTrip);

        try {
            const serverTrip = await this._tripApi.create(persistedTrip, reCaptcha);
            return await this._tripRepository.update({
                ...persistedTrip,
                serverId: serverTrip.serverId
            });
        } catch (e) {
            await this._tripRepository.destroy(persistedTrip.uuid);
            throw e;
        }
    }

    /**
     * Update an edited tripo
     * @returns {Promise<PersistedTrip>}
     * @param toBeUpdatedTrip
     */
    public async updateTrip(toBeUpdatedTrip: PersistedTrip): Promise<PersistedTrip> {
        try {
            const {secretUuid, ...serverTrip} = await this._tripApi.update(toBeUpdatedTrip);
            return await this._tripRepository.update({
                ...toBeUpdatedTrip,
                ...serverTrip
            });
        } catch (e) {
            throw e;
        }
    }

    public trips(): Observable<PersistedTrip[]> {
        return this._tripRepository.stream;
    }

    public async all(): Promise<PersistedTrip[]> {
        return this._tripRepository.all();
    }

    public tripByUuid(tripUuid: string): Observable<PersistedTrip | null> {
        return this._tripRepository.byKey(tripUuid);
    }
}
