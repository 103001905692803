import {Observable} from 'rxjs';
import {shareReplay, switchMap} from 'rxjs/operators';

import {MapLocation} from '../models/map_location';
import {PersistedMoment, UnpersistedMoment} from '../models/moment';
import {UnpersistedMomentComment} from '../models/moment_comment';
import {MomentApi} from '../network/moment/moment_api';
import {MomentRepository} from '../repositories/moment_repository';
import {toUnpersistedMoment} from '../transformers/moment';

export class MomentInteractor {
    private _momentRepository: MomentRepository;
    private _momentApi: MomentApi;

    private _momentsMap: Observable<Map<string, PersistedMoment[]>> | null = null;

    constructor(momentRepository: MomentRepository, momentApi: MomentApi) {
        this._momentRepository = momentRepository;
        this._momentApi = momentApi;
    }

    public moments(): Observable<PersistedMoment[]> {
        return this._momentRepository.stream;
    }

    public get momentsMap(): Observable<Map<string, PersistedMoment[]>> {
        if (this._momentsMap === null) {
            this._momentsMap = this._momentRepository.stream.pipe(
                switchMap(async moments => this.toMomentsMap(moments)),
                shareReplay(1)
            );
        }
        return this._momentsMap;
    }

    private async toMomentsMap(persistedMoments: PersistedMoment[]): Promise<Map<string, PersistedMoment[]>> {
        const momentMap = new Map<string, PersistedMoment[]>();
        persistedMoments.forEach(moment => {
            if (moment.tripUuid !== null) {
                if (momentMap.has(moment.tripUuid)) {
                    const moments = momentMap.get(moment.tripUuid);
                    if (moments) {
                        moments.push(moment);
                    }
                } else {
                    momentMap.set(moment.tripUuid, [moment]);
                }
            }
        });
        return momentMap;
    }

    public byMomentUuid(momentUuid: string): Observable<PersistedMoment | null> {
        return this._momentRepository.byKey(momentUuid);
    }

    /**
     * Updates an existing moment
     * @param {PersistedMoment} moment
     * @param location
     * @returns {Promise<void>}
     */
    public async updateMoment(moment: PersistedMoment, location: MapLocation): Promise<PersistedMoment> {
        return new Promise<PersistedMoment>(async resolve => {
            const newOrChangedMoment: PersistedMoment = {
                ...moment,
                lat: location.lat,
                long: location.long,
                requiresSyncWithServer: true
            };

            // Update moment locally
            const persistedNewMoment = await this._momentRepository.update(newOrChangedMoment);

            try {
                // Update or store the moment to remote
                const serverMoment = persistedNewMoment.serverId
                    ? await this._momentApi.update(persistedNewMoment)
                    : await this._momentApi.create(persistedNewMoment);

                // Merge the remote Moment with our local Moment
                const syncedUnpersistedMoment = toUnpersistedMoment(serverMoment, moment.tripUuid);
                const locallySavedSyncedMoment = await this._momentRepository.update({
                    ...syncedUnpersistedMoment,
                    uuid: moment.uuid,
                    requiresSyncWithServer: false
                });

                resolve(locallySavedSyncedMoment);
            } catch (e) {
                resolve(persistedNewMoment);
            }
        });
    }

    /**
     * Stores a fresh moment
     * @param {UnpersistedMoment} unpersistedMoment
     * @param tripUuid
     * @param serverTripId
     * @param location
     * @returns {Promise<void>}
     */
    public async storeMoment(
        unpersistedMoment: UnpersistedMoment,
        tripUuid: string,
        serverTripId: number,
        location: MapLocation
    ): Promise<PersistedMoment> {
        const moment = {
            ...unpersistedMoment,
            tripUuid,
            serverTripId,
            lat: location.lat,
            long: location.long
        };

        const persistedMoment = await this._momentRepository.store(moment);

        try {
            return await this.syncPersistedMomentWithServer(persistedMoment, tripUuid);
        } catch (e) {
            console.warn(e);
        }

        return persistedMoment;
    }

    public async syncPersistedMomentWithServer(persistedMoment: PersistedMoment, tripId: string) {
        const serverMoment = persistedMoment.serverId
            ? await this._momentApi.update(persistedMoment)
            : await this._momentApi.create(persistedMoment);

        const unpersistedServerMoment = toUnpersistedMoment(serverMoment, tripId);
        if (serverMoment.serverId) {
            return await this._momentRepository.update({
                ...persistedMoment,
                ...unpersistedServerMoment,
                serverId: serverMoment.serverId,
                requiresSyncWithServer: false
            });
        }
        throw new Error('Could not sync persisted moment with server');
    }

    public async deleteMoment(moment: PersistedMoment): Promise<void> {
        if (moment.serverId && moment.serverTripId) {
            await this._momentApi.delete(moment.serverTripId, moment.serverId);
        }
        return await this._momentRepository.destroy(moment.uuid);
    }

    public async likeMoment(moment: PersistedMoment): Promise<PersistedMoment> {
        if (!moment.serverTripId || !moment.serverId) {
            throw new Error("Moment isn't saved to TravelMoments, you can't like it yet");
        }
        const updatedMoment = await this._momentApi.likeMoment(moment.serverTripId, moment.serverId);
        const unpersistedUpdatedMoment = toUnpersistedMoment(updatedMoment, moment.tripUuid);
        return await this._momentRepository.update({
            ...moment,
            ...unpersistedUpdatedMoment,
            serverId: updatedMoment.serverId
        });
    }

    public async deleteMomentLike(moment: PersistedMoment): Promise<PersistedMoment> {
        if (!moment.serverTripId || !moment.serverId) {
            throw new Error("Moment isn't saved to TravelMoments, you can't un-like it yet");
        }
        const updatedMoment = await this._momentApi.deleteMomentLike(moment.serverTripId, moment.serverId);
        const unpersistedUpdatedMoment = toUnpersistedMoment(updatedMoment, moment.tripUuid);
        return await this._momentRepository.update({
            ...moment,
            ...unpersistedUpdatedMoment,
            serverId: updatedMoment.serverId
        });
    }

    public async commentMoment(moment: PersistedMoment, comment: UnpersistedMomentComment): Promise<PersistedMoment> {
        if (!moment.serverTripId || !moment.serverId) {
            throw new Error("Moment isn't saved to TravelMoments, you can't comment it yet");
        }

        const updatedMoment = await this._momentApi.commentMoment(moment.serverTripId, moment.serverId, comment);
        const unpersistedUpdatedMoment = toUnpersistedMoment(updatedMoment, moment.tripUuid);
        return await this._momentRepository.update({
            ...moment,
            ...unpersistedUpdatedMoment,
            serverId: updatedMoment.serverId
        });
    }

    public async updateMomentComment(
        moment: PersistedMoment,
        commentId: number,
        content: string
    ): Promise<PersistedMoment> {
        if (!moment.serverTripId || !moment.serverId) {
            throw new Error("Moment isn't saved to TravelMoments, you can't comment it yet");
        }

        const updatedMoment = await this._momentApi.updateMomentComment(
            moment.serverTripId,
            moment.serverId,
            commentId,
            content
        );
        const unpersistedUpdatedMoment = toUnpersistedMoment(updatedMoment, moment.tripUuid);
        return await this._momentRepository.update({
            ...moment,
            ...unpersistedUpdatedMoment,
            serverId: updatedMoment.serverId
        });
    }
}
