import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface OwnProps {
    onChange: (token: string | null) => void;
}

export class ReCaptcha extends React.PureComponent<OwnProps> {
    public render() {
        return (
            <div className="RecaptchaContainer">
                <ReCAPTCHA sitekey="6Lc7mGUUAAAAAKIRFK2dHLjrmH9re9BhRBXoOehR" onChange={this.props.onChange} />
            </div>
        );
    }
}
