import {AddFileOptions, FailedUppyFile, UploadedUppyFile, UploadResult, Uppy, UppyFile} from '@uppy/core';
import Tus from '@uppy/tus';
import {observable} from 'mobx';
import {v4 as uuidv4} from 'uuid';

import {baseURL} from '../app_config';
import {getTransObject, trans} from '../i18n/trans';
import {MapLocation} from '../models/map_location';
import {ResizeAndCoordsPlugin} from '../support/uppy_coords_plugin';

import {ImageExif} from './image/exif';
import {ImageCacheInteractor} from './image_cache_interactor';

type AddFileObject = AddFileOptions & {meta: Partial<UppyMetaData> & {order: number}};

interface UploadReturn {
    successful: ReadonlyArray<UploadedUppyFile<UppyMetaData>>;
    failed: ReadonlyArray<FailedUppyFile<UppyMetaData>>;
}

export interface UppyMetaData {
    uuid: string;
    order: number;
    name: string;
    type: string;
    coordinateData: MapLocation | null;
    lastModified?: number;
    momentUuid: string;
    fileId: string;
}

export class UppyInteractor {
    private _uppy: Uppy;
    private _imageCacheInteractor: ImageCacheInteractor;
    private _maxNumberOfFiles = 6;

    public get uppy(): Uppy {
        return this._uppy;
    }

    @observable
    public hasImages: boolean = false;
    @observable
    public queuedImages: Array<UppyFile<UppyMetaData>> = [];

    constructor(imageCacheInteractor: ImageCacheInteractor, momentUuid: string, maxNumberOfFiles = 6) {
        this._imageCacheInteractor = imageCacheInteractor;
        this._maxNumberOfFiles = maxNumberOfFiles;

        this._uppy = new Uppy({
            id: momentUuid,
            autoProceed: false,
            debug: process.env.NODE_ENV === 'development',
            restrictions: {
                maxFileSize: 25000000, //25mb
                maxNumberOfFiles,
                minNumberOfFiles: 0,
                allowedFileTypes: ['image/*']
            },
            locale: {
                strings: getTransObject('uppy.core') as any
            }
        });

        this._uppy.on('file-added', () => {
            const uppyState = this._uppy.getState<UppyMetaData>();
            const keys = Object.keys(uppyState.files);
            this.hasImages = keys.length > 0;
            this.queuedImages = keys.map(k => uppyState.files[k]);
        });

        this._uppy.on('file-removed', () => {
            const uppyState = this._uppy.getState<UppyMetaData>();
            const keys = Object.keys(uppyState.files);
            this.hasImages = keys.length > 0;
            this.queuedImages = keys.map(k => uppyState.files[k]);
        });

        this._uppy.use(ResizeAndCoordsPlugin, {momentUuid});
    }

    public addImage = (file: AddFileObject) => {
        if (file.meta.uuid) {
            this._uppy.addFile(file);
        } else {
            this._uppy.addFile({
                ...file,
                meta: {
                    ...file.meta,
                    uuid: uuidv4()
                }
            } as any);
        }
    };

    public addImages = (files: AddFileObject[]) => {
        files.slice(0, this._maxNumberOfFiles).map(this.addImage);
    };

    public updateImageMeta = (fileID: string, data: Partial<UppyMetaData>) => {
        this._uppy.setFileMeta(fileID, data);
    };

    public reset = () => {
        this._uppy.reset();
    };

    public upload = async (
        authToken: string,
        serverTripId: number,
        tripUuid: string,
        momentUuid: string,
        retryCount = 5
    ): Promise<UploadReturn | null> => {
        const existingTusPlugin = this._uppy.getPlugin('Tus');
        if (existingTusPlugin) {
            this._uppy.removePlugin(existingTusPlugin);
        }

        //e.g. [0, 500, 2000, 4500, 8000]
        const retryDelays = Array.from({length: retryCount}, (_, index) => ((index * index) / 2) * 1000);
        const tusOptions = {
            endpoint: baseURL + `trip/${serverTripId}/files/upload`,
            resume: false,
            removeFingerprintOnSuccess: true,
            useFastRemoteRetry: false,
            retryDelays: retryDelays,
            chunkSize: 100 * 1000, // = 0.1mb
            limit: 2,
            headers: {
                Authorization: 'Bearer ' + authToken
            }
        };
        this._uppy.use(Tus, tusOptions);

        return new Promise<UploadReturn | null>(async (resolve, reject) => {
            const beforeUnloadListener = (e: BeforeUnloadEvent) => {
                e.returnValue = trans('uppy.custom.unloadWhileUploading');
            };
            window.addEventListener('beforeunload', beforeUnloadListener);

            try {
                //first remove all images for this moment in the cache, if some images fail uploading they get added again
                await this._imageCacheInteractor.removeUnsyncedBlobsForMomentUuid(momentUuid);

                let didCancel = false as boolean;
                this._uppy.on('cancel-all', () => {
                    //if an user clicked the cancel button, the upload promise will never resolve
                    //we simply set uploaded to false and set an extra boolean flag if the promise ever does resolve
                    //also clear the unload even listener
                    didCancel = true;
                    window.removeEventListener('beforeunload', beforeUnloadListener);
                    return resolve(null);
                });
                await this._uppy.upload();

                if (didCancel === true) {
                    console.info('User cancelled upload.');
                    return;
                }

                const uploadResult = this.getFailedAndSucceededFilesFromUppy();

                // persist each failed file to our service-worker
                uploadResult.failed.forEach(async failedFile => {
                    const fileCopy: UppyFile<UppyMetaData> = {
                        ...failedFile,
                        meta: {
                            ...failedFile.meta,
                            lastModified: failedFile.data instanceof File ? failedFile.data.lastModified : undefined,
                            momentUuid: momentUuid
                        }
                    };
                    await this._imageCacheInteractor.storeUnsyncedBlob(fileCopy);
                });

                //each successful file gets (potentially) removed from the service-worker
                uploadResult.successful.forEach(async uploadedFile => {
                    await this._imageCacheInteractor.removeUnsyncedBlob(uploadedFile.meta.fileId);
                });

                if (uploadResult.failed.length > 0) {
                    console.info('Some images failed uploading', uploadResult);
                }

                window.removeEventListener('beforeunload', beforeUnloadListener);
                return resolve({
                    successful: uploadResult.successful,
                    failed: uploadResult.failed
                });
            } finally {
                window.removeEventListener('beforeunload', beforeUnloadListener);
            }
        });
    };

    private getFailedAndSucceededFilesFromUppy(): UploadResult<UppyMetaData> {
        const filesMap = this._uppy.getState<UppyMetaData>().files;
        return Object.keys(filesMap).reduce(
            (acc: UploadResult<UppyMetaData>, fileId: string) => {
                const file = filesMap[fileId];
                if ('error' in file && file.error !== null && typeof file.error === 'string') {
                    acc.failed.push(file);
                } else if ('uploadURL' in file) {
                    acc.successful.push(file);
                } else {
                    console.warn('Some file got in an unkown state:', file);
                    throw new Error('Some file got in an unkown state: ' + file.name);
                }

                return acc;
            },
            {failed: [], successful: []}
        );
    }

    public async determineImagesLocation(): Promise<MapLocation | null> {
        const state = this._uppy.getState();
        const files = Object.keys(state.files).map(key => state.files[key]);

        for (const file of files) {
            const coordinateData = await ImageExif.getImageCoordinates(file.data);
            if (coordinateData !== null) {
                return coordinateData;
            }
        }

        return null;
    }
}
