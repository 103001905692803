import {first} from 'rxjs/operators';

import {AccountInteractor} from '../../../business/account_interactor';
import {Request} from '../../request/request';
import {RequestInterceptor} from '../interceptor';

export class AuthorizationInterceptor implements RequestInterceptor {
    private _accountInteractor: AccountInteractor;

    constructor(accountInteractor: AccountInteractor) {
        this._accountInteractor = accountInteractor;
    }

    public async next<T>(request: Request<T>): Promise<Request<T>> {
        const appAuth = await this._accountInteractor
            .appAuth()
            .pipe(first())
            .toPromise();

        if (appAuth && appAuth.token && !request.hasHeader('Authorization')) {
            request.addHeader('Authorization', 'Bearer ' + appAuth.token);
        }

        return request;
    }
}
