import {TranslationObject} from '../trans';

export const nlTranslations: TranslationObject = {
    onboarding: {
        continue: 'Leg uw eerste Travel Moment vast',
        page1: {
            title: 'Leg uw reis vast',
            subtitle: 'Leg alle Travel Moments van uw reis vast met My Travel Moments.'
        },
        page2: {
            title: 'Deel uw reis',
            subtitle: 'Deel eenvoudig uw reis met vrienden en familie door een geheime link op te sturen.'
        },
        page3: {
            title: 'Bestel een fotoalbum',
            subtitle: 'Bestel gemakkelijk een fotoalbum met de fotos van uw Travel Moments.'
        }
    },
    photoalbum: {
        explanation: {
            title: 'Bekijk en bestel een fotoalbum van uw reis',
            subtitle:
                'Hoe werkt het? We hebben een fotoalbum gemaakt van uw reis. Deze kunt u hieronder selecteren, bekijken en bestellen.',
            no_shipping_costs: 'Geen verzendkosten'
        },
        preview: {
            title: 'Voorbeeldweergave van uw fotoalbum',
            details: 'Prijsinformatie',
            dialog: {
                title: 'Prijsinformatie',
                error: 'Er is iets misgegaan, probeer het later opnieuw.',
                subtitle: 'Het fotoalbum wordt geleverd met de volgende specificaties:',
                labels: {
                    photoalbum: 'Fotoalbum',
                    format: 'Formaat',
                    pages_count: "Aantal pagina's",
                    price: 'Prijs',
                    shipping: 'Verzendkosten',
                    coupon: 'Kortingscoupon',
                    total: 'Totaal:',
                    expected_delivery: 'Verwachte levertijd: 4-6 werkdagen'
                },
                values: {
                    photoalbum: 'Hardcover met 200 grams glossy papier',
                    format: '29,7cm x 21,0cm (A4 liggend)',
                    shipping_costs: '€4.95',
                    no_shipping_costs: '(nu geen verzendkosten)',
                    coupon_explanation: 'De eventuele korting wordt verrekend in de volgende stap.'
                }
            }
        }
    },
    errors: {
        trip_not_found: 'Reis is niet gevonden',
        moment_not_found: 'Travel Moment niet gevonden',
        image_not_found: 'Foto is niet gevonden',
        unauthorized: 'U bent niet geautoriseerd om deze pagina te bekijken'
    },
    nav: {
        title: 'My Travel Moments',
        moments_index: 'Ontdek',
        trips: 'Reizen',
        photo_album: 'Fotoalbum',
        account: 'Mijn account',
        register: 'Nieuw account',
        login: 'Login',
        logout: 'Logout'
    },
    sync: {
        server: 'Synchroniseren met de server'
    },
    navigator_share: {
        title: 'Volgverzoek',
        text: 'U bent uitgenodigd om de Travel Moments van een vriend of vriendin te bekijken.'
    },
    placeholders: {
        share_input: 'Volg link',
        title: 'Titel',
        description: 'Omschrijving',
        type: 'Selecteer het soort dat deze Travel Moment het beste omschrijft',
        date: 'Datum',
        visibility: 'Zichtbaarheid',
        destination: 'Bestemming',
        departure_date: 'Vertrekdatum',
        return_date: 'Terugkomstdatum',
        location: 'Locatie',
        email: 'email@voorbeeld.nl',
        password: 'Wachtwoord',
        password_repeat: 'Herhaal wachtwoord',
        first_name: 'Voornaam',
        last_name: 'Achternaam',
        images: 'Afbeeldingen',
        search_location: 'Locatie zoeken (bijv. plaatsnaam, strandnaam, hotel…)',
        coupon: 'Vul hier een couponcode in',
        comment_content: 'Uw bericht.'
    },
    labels: {
        photos: "Foto's",
        about_moment: 'Omschrijving',
        category: 'Soort',
        drag_images: "Sleep hier foto's naartoe, of klik om meerdere foto's te uploaden.",
        title: 'Titel',
        description: 'Omschrijving',
        type: 'Soort',
        date: 'Datum',
        visibility: 'Zichtbaarheid',
        destination: 'Bestemming',
        departure_date: 'Vertrekdatum',
        return_date: 'Terugkomstdatum',
        location: 'Locatie',
        email: 'Email',
        password: 'Wachtwoord',
        password_repeat: 'Herhaal wachtwoord',
        first_name: 'Voornaam',
        last_name: 'Achternaam',
        images: 'Afbeeldingen',
        accept_terms_first: 'Door mij aan te melden, ga ik akkoord met de',
        accept_terms_service: 'servicevoorwaarden',
        accept_terms_middle: 'en',
        accept_terms_privacy: 'privacyverklaring',
        accept_terms_end: 'van My Travel Moments.',
        coupon: 'Als u een coupon code heeft kunt u deze hier invullen.',
        first_comment: 'Er zijn nog geen opmerkingen, Wees de eerste om een opmerking toe te voegen.',
        comment: 'Vul het formulier in om uw opmerking toe te voegen.',
        reply_comment: (args: {[key: string]: any}) => `Reageer hieronder op de opmerking van ${args.name}.`,
        edit_comment: 'Pas uw opmerking aan.'
    },
    default: {
        empty_description: 'Geen beschrijving'
    },
    buttons: {
        back: 'Terug',
        block: 'Blokkeren',
        cancel: 'Annuleer',
        close: 'Sluiten',
        change_password: 'Verander wachtwoord',
        create: 'Aanmaken',
        create_moment: 'Nieuw Moment',
        create_moment_disabled_titel: 'Uw reis heeft de limiet bereikd van 100 Travel Moments.',
        create_first_moment: 'Maak uw eerste Travel Moment',
        create_trip: 'Nieuwe reis',
        create_trip_disabled_title: 'Uw account heeft reeds de gelimiteerde hoeveelheid van 25 reizen bereikt.',
        share_trip: 'Deel uw reis',
        delete: 'Verwijderen',
        discover: 'Ontdek Travel Moments',
        edit: 'Bewerken',
        edit_moment: 'Bewerk Travel Moment',
        edit_trip: 'Bewerk reis',
        follow_user: (args: {[key: string]: any}) => `Volg ${args.name}`,
        login: 'Log in',
        ok: 'Ok',
        register: 'Maak een account',
        retry: 'Opnieuw',
        save: 'Opslaan',
        send_email: 'Verstuur email',
        show_map: 'Bekijk grote kaart',
        unfollow: 'Ontvolg',
        next: 'Volgende',
        previous: 'Vorige',
        forgot_password: 'Ik ben mijn wachtwoord vergeten',
        create_account: 'Een My Travel Moments account aanmaken',
        login_account: 'Inloggen',
        order_photoalbum: 'Bestel fotoalbum',
        reply: 'Reageren',
        downloadApp: 'Download My Travel Moments App'
    },

    //Pages
    accept_follow_request: {
        title: 'Accepteer volgverzoek',
        dialog_success: 'Volgverzoek bevestigd, andere gebruiker kan nu uw Travel Moments zien',
        dialog_failed: 'Het accepteren van het volgverzoek is mislukt, probeer het opnieuw.',
        dialog_error: 'Er is iets fout gegaan bij het accepteren van het volgverzoek.'
    },

    activate_account: {
        title: 'Activeer account',
        no_account_found: 'Er is geen inactief account gevonden voor deze link. Is uw account al geactiveerd?',
        dialog_error:
            'Er is iets misgegaan bij het activeren van uw account. Probeer het opnieuw of maak een nieuw account.'
    },

    visibility: {
        public: 'Ja, ik wil andere mensen graag inspireren met mijn Travel Moment',
        followers: 'Ja, maar alleen mijn volgers mogen mijn Travel Moment zien',
        private: 'Nee, mijn Travel Moment is alleen zichtbaar voor mij'
    },
    type: {
        viewpoint: 'Bezoekwaardig',
        food: 'Eten & drinken',
        culture: 'Cultuur & kunst',
        accommodation: 'Accomodatie',
        nightlife: 'Nachtleven',
        shopping: 'Winkelen',
        transport: 'Transport',
        excursion: 'Excursie',
        activity: 'Sport & activiteit',
        nature: 'Natuur',
        architecture: 'Architectuur',
        relaxing: 'Ontspannen',
        other: 'Overig'
    },
    create_moment: {
        title: 'Maak een nieuw Travel Moment',
        info_change_image:
            "Selecteer eerst de foto's die u wilt delen en geef daarna aan wie u allemaal wilt inspireren",
        info_visibility:
            'Travel Moments kunnen drie soorten zichtbaarheden hebben: publiek, zichtbaar voor volgers en privé. Privé Travel Moments kunnen niet worden gedeeld met of worden gezien door anderen ',
        dialog_error: 'Er is iets misgegaan, probeer het later opnieuw.',
        about_info:
            'Vertel iets over dit Travel Moment. Wanneer was het, en wat is het onderwerp? De omschrijving is optioneel maar kan juist inspirerend zijn voor anderen.',
        type_info:
            'Wat omschrijft dit Travel Moment het beste? Om gemakkelijk Travel Moments te kunnen vinden willen we graag weten wat voor soort moment dit is.',
        pick_location: 'Kies een locatie waar deze Travel Moment zich heeft afgespeeld.'
    },
    edit_moment: {
        title: 'Bewerk Travel Moment',
        title_delete: 'Verwijder Travel Moment',
        info_delete:
            'U kunt een Travel Moment verwijderen. Met deze actie wordt alle inhoud van dit moment verwijderd.',
        info_change_image_web:
            'Klik op de afbeelding om de afgebeelde afbeelding te bewerken, of de locatie van een afbeelding te wijzigen. De afbeeldingen kunnen gesorteerd worden door ze te verslepen. Voeg tot een maximum van 6 afbeeldingen toe in het blok hieronder.',
        info_change_image_app:
            'Klik op de afbeelding om de afgebeelde afbeelding te bewerken, of de locatie van een afbeelding te wijzigen. De afbeeldingen kunnen gesorteerd worden door op de knoppen onder de afbeelding te klikken. Voeg tot een maximum van 6 afbeeldingen toe in het blok hieronder.',
        info_visibility:
            'Travel Moments kunnen drie soorten zichtbaarheden hebben: publiek, zichtbaar voor volgers en privé. Privé Travel Moments kunnen niet worden gedeeld met of worden gezien door anderen ',
        dialog_error: 'Er is iets misgegaan, probeer het later opnieuw.',
        delete_confirm: 'Weet u zeker dat u dit Travel Moment wil verwijderen?'
    },
    moment_comment: {
        title: 'Opmerkingen',
        edited: (args: {[key: string]: any}) => `aangepast: ${args.date}`
    },
    create_trip: {
        title: 'Maak een nieuwe reis',
        dialog_error: 'Er is iets misgegaan, probeer het later opnieuw.'
    },
    edit_trip: {
        title: 'Bewerk reis',
        dialog_error: 'Er is iets misgegaan, probeer het later opnieuw.'
    },
    edit_image: {
        title: 'Bewerk afbeeldingsgegevens',
        title_delete: 'Verwijder afbeelding',
        info_delete:
            'U kunt een afbeelding verwijderen. Met deze actie wordt alle inhoud van dit afbeelding verwijderd.',
        dialog_error: 'Er is iets misgegaan, probeer het later opnieuw.',
        delete_confirm: 'Weet u zeker dat u deze foto wil verwijderen?'
    },
    follow_user: {
        title: 'Volg gebruiker',
        dialog_success:
            'E-mail is verzonden naar andere de gebruiker, even geduld tot de andere gebruiker uw verzoek heeft geaccepteerd.',
        dialog_failed: 'Verzenden van het volgverzoek mislukt, probeer het opnieuw.',
        dialog_error: 'Er is iets verkeerd gegaan.'
    },
    home: {
        title: 'Leg Travel Moments vast, ontdek de wereld',
        intro_left:
            'Ontdek nieuwe plaatsen. Deel uw favoriete plaatsen met anderen. Dat is waar My Travel Moments over gaat. Ons platform werkt perfect samen met Travelia, waardoor het delen van herinneringen zo eenvoudig mogelijk is. ',
        intro_right:
            'In onze ogen reist u nooit alleen en is er altijd meer te ontdekken dan u kunt vinden. Daarom bieden wij u de perfecte oplossing om uw reis vast te leggen. '
    },
    index_moments: {
        title: 'Ontdek de wereld',
        title_empty: 'Er zijn nog geen Travel Moments.',
        intro:
            'My Travel Moments is de plek om uw verhaal te vertellen. Gebruik de applicatie My Travel Moments of onze integratie met de Travelia-app om uw verhalen vast te leggen. Op deze pagina vindt u de nieuwste Travel Moments. ',
        info_empty: 'Log in of gebruik de Travelia-app om momenten te creëren.'
    },
    login: {
        title: 'Log in om meer te ontdekken',
        dialog_error: 'Er is iets misgegaan tijdens het inloggen. Probeer het opnieuw.'
    },
    auth_dialog: {
        start: {
            title: 'Log in of maak een account om meer te ontdekken.',
            subtitle: 'Om deze stap uit te voeren moet u ingelogd zijn. Log in of maak een account aan.'
        }
    },
    my_account: {
        title_followers: 'Volgers',
        title_followings: 'Mensen die u volgt',
        title_follow_options: 'Volg opties',
        info_followers: 'Hieronder vindt u de mensen die u volgen.',
        info_followings: 'Onderstaande mensen volgt u.',
        info_follow_options:
            'U kunt vrienden uitnodigen om uw account te volgen. Alle Moments die alleen publiek of volgers zijn, kunnen door uw volgers worden bekeken. De lijst met volgers en mensen die u volgt, is op deze pagina te beheren.',
        info_follow_url:
            'Als u vrienden wilt uitnodigen om u te volgen, maakt u een volgverzoek link met de onderstaande knop. Een volglink verloopt na zeven dagen.',
        error_followers: 'Laden van volgers mislukt',
        error_followings: 'Mislukt laden van accounts die u volgt',
        dialog_error: 'Er is iets misgegaan met het maken van een nieuwe link, probeer het opnieuw.',
        version: 'Versie:'
    },
    my_trips: {
        title: 'Mijn reizen',
        title_followings: 'Reizen van mensen die u volgt',
        intro_my_trips:
            'Hier vindt u al uw reizen. Zowel reizen die zijn gemaakt met de Travelia-app, als reizen die u online hebt gemaakt, worden weergegeven. ',
        intro_following_trips: 'Hier vindt u alle reizen van de mensen die u een volgverzoek hebben gestuurd.'
    },
    recover_password: {
        title: 'Wachtwoord opnieuw instellen',
        dialog_success: 'Controleer uw inbox om uw wachtwoord opnieuw in te stellen.'
    },
    register: {
        title: 'Maak een account aan',
        check_inbox: 'Ga naar uw e-mail inbox om uw account te activeren.'
    },
    reset_password: {
        title: 'Herstel uw wachtwoord'
    },
    select_image: {
        title: 'Verander primaire afbeelding',
        intro: 'Selecteer de primaire afbeelding hieronder.',
        dialog_error: 'Er is iets misgegaan, probeer het later opnieuw.'
    },
    show_moment: {
        title: 'My Travel Moment ',
        title_unsynchronized_images: 'Niet-gesynchroniseerde afbeeldingen',
        stats_comments: 'Opmerkingen',
        stats_photos: "Foto's",
        stats_offline: 'Offline',
        stats_not_synced: 'Niet gesynct',
        stats_images: "Foto's",
        stats_likes: 'Likes',
        image_locations: 'Locaties van afbeeldingen'
    },
    show_trip: {
        title: 'Mijn Travel Moments',
        title_empty: 'Er zijn nog geen Travel Moments.',
        info_empty: 'Log in of gebruik de Travelia-app om Travel Moments te creëren.',
        title_create_moments: 'Wilt u Travel Moments vastleggen?',
        info_create_moments:
            'Ontdek nieuwe plaatsen. Deel uw favoriete plaatsen met anderen. Dat is waar My Travel Moments over gaat. Maak reismomenten in de app en deel ze met onze web-app op mytravelmoments.com. ',
        day: 'Dag',
        stats_days: 'Dagen',
        stats_moments: 'Moments',
        stats_images: "Foto's",
        share_title: 'Deel reis',
        share_message:
            'U kunt de link hieronder gebruiken om deze reis te delen met andere gebruikers. Zelfs als ze nog geen account hebben kunnen ze uw publieke en volgers moments zien. Let op dat iedereen die deze link heeft uw publieke en volgers moments voor deze reis kan zien.'
    },
    uppy: {
        custom: {
            unloadWhileUploading: 'Er worden nog bestanden verzonden, weet u zeker dat u weg wilt navigeren?',
            uploadingFailed:
                "Het uploaden van de foto's is niet gelukt, we proberen het later op de achtergrond opnieuw. Andere gebruikers kunnen uw foto's nog niet zien.",
            partialFailed:
                "Het uploaded van sommige foto's is niet gelukt, we proberen deze foto's later op de achtergrond opnieuw. Andere gebruikers kunnen deze foto's nog niet zien."
        },
        core: {
            youCanOnlyUploadX: {
                0: 'U kan maar %{smart_count} bestand uploaden',
                1: 'U kan maar %{smart_count} bestanden uploaden'
            },
            youHaveToAtLeastSelectX: {
                0: 'U moet op zijn minst %{smart_count} bestand selecteren',
                1: 'U moet op zijn minst %{smart_count} bestanden selecteren'
            },
            exceedsSize: 'Dit bestand gaat over de maximale toegestane grootte van',
            youCanOnlyUploadFileTypes: 'U kunt enkel de volgende bestanden uploaden:',
            failedToUpload: 'Uploaden mislukt voor %{file}',
            noInternetConnection: 'Geen internet verbinding',
            connectedToInternet: 'Vervonden',
            noFilesFound: 'Er zijn hier geen bestanden of mappen'
        },
        dashboard: {
            selectToUpload: 'Selecteer bestanden om te uploaden',
            closeModal: 'Sluit popup',
            upload: 'Upload',
            importFrom: 'Importeer van %{name}',
            dashboardTitle: 'Upload Dashboard',
            copyLinkToClipboardSuccess: 'Link gekopieerd naar clipboard',
            copyLink: 'Kopieer link',
            fileSource: 'Bestand locatie: %{name}',
            done: 'Klaar',
            name: 'Naam',
            removeFile: 'Verwijder bestand',
            editFile: 'Wijzig bestand',
            editing: '%{file} wijzigen',
            finishEditingFile: 'Klaar wijzigen bestand',
            saveChanges: 'Save wijzigingen',
            cancel: 'Annuleer',
            localDisk: 'Lokale Opslag',
            myDevice: 'Mijn apparaat',
            dropPasteImport: '',
            dropPaste: "Klik %{browse} om uw foto's te uploaden",
            browse: 'hier',
            fileProgress: 'Upload voortgang: Upload snelheid en tijd',
            numberOfSelectedFiles: 'Nummer of geselecteerde bestanden',
            uploadAllNewFiles: 'Upload alle nieuwe bestanden',
            emptyFolderAdded: 'Geen bestanden toegevoegd vanuit lege map',
            uploadComplete: 'Upload klaar',
            resumeUpload: 'Upload voortzetten',
            pauseUpload: 'Upload pauzeren',
            retryUpload: 'Upload herproberen',
            uploadXFiles: {
                0: 'Upload %{smart_count} bestand',
                1: 'Upload %{smart_count} bestanden'
            },
            uploadXNewFiles: {
                0: 'Upload +%{smart_count} bestand',
                1: 'Upload +%{smart_count} bestanden'
            },
            folderAdded: {
                0: '%{smart_count} bestand toegevoegd van %{folder}',
                1: '%{smart_count} bestanden toegevoegd van %{folder}'
            }
        }
    },
    quota: {
        small: 'Er is niet genoeg ruimte over op dit apparaat om My Travel Moments te laten functioneren.'
    },
    validation: {
        length: (args: {[key: string]: any}) => `${args.name} moet minimaal ${args.length} karakters lang zijn.`,
        array_length: (args: {[key: string]: any}) => `${args.name} moet minimaal ${args.length} elementen lang zijn.`,
        max_length: (args: {[key: string]: any}) => `${args.name} mag maximaal ${args.maxLength} karakters lang zijn.`,
        array_max_length: (args: {[key: string]: any}) =>
            `${args.name} mag maximaal ${args.maxLength} elementen lang zijn.`,
        required: (args: {[key: string]: any}) => `${args.name} is verplicht.`,
        checked: (args: {[key: string]: any}) =>
            args.name ? `${args.name} moet geaccepteerd zijn.` : `Moet geaccepteerd zijn.`,
        email: `Moet een geldig email zijn.`,
        uppercase_characters: (args: {[key: string]: any}) =>
            `${args.name} moet op zijn minst ${args.amount} ${
                args.amount > 1 ? 'hoofdletters' : 'hoofdletter'
            } bezitten.`,
        numeric_characters: (args: {[key: string]: any}) =>
            `${args.name} moet op zijn minst ${args.amount} ${args.amount > 1 ? 'getallen' : 'getal'} bezitten.`,
        match: (args: {[key: string]: any}) => `${args.name} moet overeenkomen met ${args.otherName}.`
    }
};
