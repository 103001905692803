import {observable} from 'mobx';

import {PageEnum, PageInteractor} from '../business/page_interactor';
import {CompositeSubscription} from '../support/composit_subscription';
import {createPath} from '../support/path';
import {Presenter} from '../support/with_presenter';

export class NavigationPresenter implements Presenter {
    private _pageInteractor: PageInteractor;
    private _subscriptions = new CompositeSubscription();

    @observable
    public currentPage: PageEnum | null = null;

    constructor(pageInteractor: PageInteractor) {
        this._pageInteractor = pageInteractor;
    }

    public path(page: PageEnum, parameters: string[] = [], queryParameters: {[name: string]: string} = {}) {
        return createPath(page, parameters, queryParameters);
    }

    public mount() {
        this._subscriptions.add(
            this._pageInteractor.currentPage().subscribe(p => {
                this.currentPage = p.page;
            })
        );
    }

    public unmount() {
        this._subscriptions.clear();
    }

    public navigate = (
        e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
        page: PageEnum,
        parameters: string[] = [],
        queryParameters: {[name: string]: string} = {}
    ) => {
        if (e.ctrlKey === false && e.metaKey === false) {
            e.preventDefault();

            const firstChild = e.currentTarget.firstChild;
            if (firstChild && firstChild instanceof HTMLElement && firstChild.id !== '') {
                window.history.replaceState(
                    window.history.state,
                    window.document.title,
                    window.location.href.split('#')[0] + '#' + firstChild.id
                );
            }

            this._pageInteractor.navigate(page, parameters, queryParameters);
        }
    };
}
