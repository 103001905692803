export async function tryGetElementById(id: string, timeout: number = 2500): Promise<HTMLElement | null> {
    return new Promise<HTMLElement | null>(resolve => {
        let breaked = false;
        const breakTimeout = window.setTimeout(() => {
            breaked = true;
            resolve(null);
        }, timeout);

        const getElement = () => {
            const el = document.getElementById(id);
            if (el) {
                window.clearTimeout(breakTimeout);
                resolve(el);
            } else if (breaked === false) {
                window.requestAnimationFrame(getElement);
            }
        };
        getElement();
    });
}
