import {FieldState, FormState} from 'formstate';
import {computed, observable} from 'mobx';

import {AccountInteractor} from '../business/account_interactor';
import {PageInteractor} from '../business/page_interactor';
import {trans} from '../i18n/trans';
import {CompositeSubscription} from '../support/composit_subscription';
import {Presenter} from '../support/with_presenter';
import {emailValidator, passwordValidator, requiredValidator, trueValidator} from '../ui/components/form/validator';

export class RegisterPresenter implements Presenter {
    @observable
    public isDirty = false;
    @observable
    public showLoader = false;
    @observable
    public dialogFailedMessage: string | null = null;
    @observable
    public dialogSucceededMessage: string | null = null;
    private _subscriptions = new CompositeSubscription();

    @observable
    public isEmailDisabled = false;

    private email = new FieldState('').disableAutoValidation().validators(emailValidator(trans('labels.email')));
    private password = new FieldState('').validators(passwordValidator(trans('labels.password')));
    private passwordRepeat = new FieldState('').validators(passwordValidator(trans('labels.password_repeat')));
    private firstName = new FieldState('')
        .disableAutoValidation()
        .validators(requiredValidator(trans('labels.first_name')));
    private lastName = new FieldState('')
        .disableAutoValidation()
        .validators(requiredValidator(trans('labels.last_name')));
    private acceptTerms = new FieldState(false).disableAutoValidation().validators(trueValidator());
    private reCaptcha = new FieldState('').validators(requiredValidator('ReCAPTCHA'));

    public form = new FormState({
        email: this.email,
        password: this.password,
        passwordRepeat: this.passwordRepeat,
        firstName: this.firstName,
        lastName: this.lastName,
        acceptTerms: this.acceptTerms,
        reCaptcha: this.reCaptcha
    })
        .compose()
        .validators(fields => {
            //compare the password and passwordRepeat in the compose,
            //this way the validation gets triggered when either of the 2 update.
            //then we set teh error on the passwordRepeat field,
            //this so that the tooltip gets shown on the passwordRepeat field
            //instead of the global form.error property
            if (fields.password.$ !== fields.passwordRepeat.$) {
                fields.passwordRepeat.setError(
                    trans('validation.match', {
                        name: trans('labels.password_repeat'),
                        otherName: trans('labels.password')
                    })
                );
            }
            return null;
        });

    @computed
    public get formHasBeenValidated() {
        return Object.keys(this.form.$).every(key => this.form.$[key].hasBeenValidated);
    }

    private _accountInteractor: AccountInteractor;
    private _pageInteractor: PageInteractor;

    constructor(accountInteractor: AccountInteractor, pageInteractor: PageInteractor) {
        this._accountInteractor = accountInteractor;
        this._pageInteractor = pageInteractor;
    }

    public mount() {
        this._subscriptions.add(
            this._pageInteractor.currentPage().subscribe(cp => {
                if ('email' in cp.queryParameters) {
                    this.email.onChange(cp.queryParameters.email);
                    this.isEmailDisabled = true;
                }
            })
        );
    }

    public unmount() {
        this._subscriptions.clear();
    }
    public clearErrorMessage = () => {
        this.dialogFailedMessage = null;
        this.dialogSucceededMessage = null;
    };

    public submitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const res = await this.form.validate();
        if (res.hasError) {
            console.warn(this.form.error);
            return;
        }

        this.showLoader = true;
        try {
            this.clearErrorMessage();
            await this._accountInteractor.registerUser(
                this.email.$,
                this.firstName.$,
                this.lastName.$,
                this.password.$,
                this.passwordRepeat.$,
                this.reCaptcha.$
            );
            this.dialogSucceededMessage = trans('register.check_inbox');
        } catch (error) {
            this.dialogFailedMessage = error.result.message;
        } finally {
            this.showLoader = false;
            this.password.reset();
            this.passwordRepeat.reset();
            this.isDirty = false;
        }
    };

    public onCancelClick = () => {
        this._pageInteractor.back();
    };
}
