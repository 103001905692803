import {observer} from 'mobx-react';
import * as React from 'react';

import {trans} from '../i18n/trans';
import {LocalImage} from '../models/image';
import {PresenterProps, withPresenter} from '../support/with_presenter';

import {Error} from './components/error';
import {Loader} from './components/loader';
import {ImagesProviderPresenter} from './images_provider_presenter';
import {Page} from './scaffold/page';

interface OwnProps {
    momentUuid: string;
    render: (images: LocalImage[]) => React.ReactNode;
}

@observer
class ImagesProvider extends React.Component<OwnProps & PresenterProps<ImagesProviderPresenter>> {
    public render() {
        if (this.props.presenter.loading) {
            return (
                <Page>
                    <Loader />
                </Page>
            );
        }
        if (this.props.presenter.images === null) {
            return (
                <Page>
                    <Error message={trans('errors.image_not_found')} />
                </Page>
            );
        }
        return this.props.render(this.props.presenter.images);
    }
}

export default withPresenter<ImagesProviderPresenter, OwnProps>(
    (props, component) => new ImagesProviderPresenter(props.momentUuid, component.business.imageInteractor),
    ImagesProvider
);
