import {observable} from 'mobx';

import {ImageInteractor} from '../business/image_interactor';
import {PageEnum, PageInteractor} from '../business/page_interactor';
import {LocalImage} from '../models/image';
import {CompositeSubscription} from '../support/composit_subscription';
import {delayedNull} from '../support/delayed_null_subscription';
import {Presenter} from '../support/with_presenter';

export class ImageProviderPresenter implements Presenter {
    @observable
    public loading = true;
    @observable
    public image: LocalImage | null = null;
    private _imageUuid: string;
    private _imageInteractor: ImageInteractor;

    private _subscriptions = new CompositeSubscription();
    private _pageInteractor: PageInteractor;
    constructor(imageUuid: string, imageInteractor: ImageInteractor, pageInteractor: PageInteractor) {
        this._imageUuid = imageUuid;
        this._imageInteractor = imageInteractor;
        this._pageInteractor = pageInteractor;
    }

    public mount(): void {
        this._subscriptions.add(
            delayedNull(this._imageInteractor.byImageUuid(this._imageUuid)).subscribe(image => {
                if (image) {
                    this.image = image;
                    this.loading = false;
                } else {
                    this._pageInteractor.navigate(PageEnum.TRIPS_INDEX);
                }
            })
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }
}
