import {UppyFile} from '@uppy/core';
import {deepEqual} from 'fast-equals';
import {first} from 'rxjs/operators';

import {LocalImage, ServerImage} from '../../models/image';
import {PersistedMoment, ServerMoment} from '../../models/moment';
import {ImageRepository} from '../../repositories/image_repository';
import {arrayToMapObject, MapObject} from '../../support/array_to_generic_map';
import {toUnpersistedImage} from '../../transformers/image';
import {AccountInteractor} from '../account_interactor';
import {ImageCacheInteractor} from '../image_cache_interactor';
import {ImageInteractor} from '../image_interactor';

import {UppyInteractor, UppyMetaData} from './../uppy_interactor';

export class ImageSynchroniser {
    private _accountInteractor: AccountInteractor;
    private _imageRepository: ImageRepository;
    private _imageInteractor: ImageInteractor;
    private _imageCacheInteractor: ImageCacheInteractor;

    constructor(
        accountInteractor: AccountInteractor,
        imageRepository: ImageRepository,
        imageInteractor: ImageInteractor,
        imageCacheInteractor: ImageCacheInteractor
    ) {
        this._accountInteractor = accountInteractor;
        this._imageRepository = imageRepository;
        this._imageInteractor = imageInteractor;
        this._imageCacheInteractor = imageCacheInteractor;
    }

    public async synchronise(
        persistedMoments: PersistedMoment[],
        serverMoments: ServerMoment[]
    ): Promise<LocalImage[]> {
        const serverImages = serverMoments.reduce((acc: ServerImage[], moment) => [...acc, ...moment.images], []);
        const locallyStoredImages = await this._imageRepository.all();

        const serverImagesMap = arrayToMapObject(serverImages, i => i.serverId);
        const locallyStoredImagesMap = arrayToMapObject(locallyStoredImages, img => img.serverId);
        const persistedMomentsMap = arrayToMapObject(persistedMoments, m => m.serverId);

        const removedImages = this.findRemovedImages(locallyStoredImages, serverImagesMap);
        if (removedImages.length > 0) {
            await this._imageRepository.destroyMultiple(removedImages.map(m => m.uuid));
        }

        await this.updateOrStoreServerImages(serverImages, locallyStoredImagesMap, persistedMomentsMap);
        await this.syncUnsyncedImages(persistedMoments);

        return await this._imageRepository.all();
    }

    private findRemovedImages(locallyStoredImages: LocalImage[], serverImages: MapObject<ServerImage>): LocalImage[] {
        return locallyStoredImages.filter(
            lsi => lsi.serverId !== undefined && serverImages[lsi.serverId] === undefined
        );
    }

    public async updateOrStoreServerImages(
        serverImages: ServerImage[],
        locallyStoredImagesMap: MapObject<LocalImage> | null,
        persistedMomentsMap: MapObject<PersistedMoment>
    ) {
        let localStoredImages = locallyStoredImagesMap;
        if (localStoredImages === null) {
            const images = await this._imageRepository.all();
            localStoredImages = arrayToMapObject(images, img => img.serverId);
        }

        const saveableImages = serverImages
            .map(si => this.getSaveableImage(si, localStoredImages!, persistedMomentsMap))
            .filter(i => i !== null) as Array<{needSaving: boolean; item: LocalImage}>;

        const imagesThatNeedSaving = saveableImages.filter(item => item.needSaving === true);
        if (imagesThatNeedSaving.length > 0) {
            await this._imageRepository.storeMultiple(imagesThatNeedSaving.map(i => i.item));
        }

        return saveableImages.map(si => si.item);
    }

    private getSaveableImage(
        serverImage: ServerImage,
        locallyStoredImagesMap: MapObject<LocalImage>,
        persistedMomentsMap: MapObject<PersistedMoment>
    ): {needSaving: boolean; item: LocalImage} | null {
        const moment = persistedMomentsMap[serverImage.serverMomentId];
        if (moment === undefined) {
            console.warn('Moment for image not found');
            return null;
        }

        const locallyMatchingImage = locallyStoredImagesMap[serverImage.serverId];

        if (locallyMatchingImage) {
            const unpersistedImage = toUnpersistedImage(serverImage, moment.uuid);
            const mergedImage: LocalImage = {
                ...unpersistedImage,
                momentUuid: moment.uuid,
                uuid: locallyMatchingImage.uuid
            };
            if (deepEqual(locallyMatchingImage, mergedImage)) {
                return {needSaving: false, item: mergedImage};
            } else {
                return {needSaving: true, item: mergedImage};
            }
        } else {
            return {needSaving: true, item: toUnpersistedImage(serverImage, moment.uuid)};
        }
    }

    private async syncUnsyncedImages(persistedMoments: PersistedMoment[]) {
        const appAuth = await this._accountInteractor
            .appAuth()
            .pipe(first())
            .toPromise();

        if (appAuth === null) {
            return;
        }

        const unsyncedImages = await this._imageCacheInteractor.getUnsyncedBlobs();
        const unsyncedImagesMap = new Map<string, Array<UppyFile<UppyMetaData>>>();

        for (const file of unsyncedImages) {
            const mapArray = unsyncedImagesMap.get(file.meta.momentUuid);
            if (mapArray) {
                mapArray.push(file);
            } else {
                unsyncedImagesMap.set(file.meta.momentUuid, [file]);
            }
        }

        const entries = Array.from(unsyncedImagesMap.entries());
        for (const entry of entries) {
            const matchingMoment = persistedMoments.find(m => m.uuid === entry[0]);

            if (
                matchingMoment === undefined ||
                matchingMoment.serverTripId === undefined ||
                matchingMoment.serverTripId === null ||
                matchingMoment.tripUuid === undefined ||
                matchingMoment.tripUuid === null
            ) {
                await this._imageCacheInteractor.removeUnsyncedBlobsForMomentUuid(entry[0]);
            } else {
                const uppyInteractor = new UppyInteractor(this._imageCacheInteractor, matchingMoment.uuid);
                entry[1].forEach((file, index) => {
                    if (file.type !== undefined) {
                        uppyInteractor.addImage({
                            ...file,
                            meta: {
                                ...file.meta,
                                order: file.meta.order || index
                            }
                        } as any);
                    }
                });

                const uploadedFiles = await uppyInteractor.upload(
                    appAuth.token,
                    matchingMoment.serverTripId,
                    matchingMoment.tripUuid,
                    matchingMoment.uuid
                );
                if (uploadedFiles !== null) {
                    await this._imageInteractor.storeImages(uploadedFiles.successful, matchingMoment);
                }
            }
        }
    }
}
