import * as React from 'react';

import {trans} from '../../../../../i18n/trans';
import {AuthenticateDialogStep} from '../../../../../presenters/authenticate_dialog_presenter';
import {Button} from '../../../../components/button';
import {Divider} from '../../../../components/divider';
import {Logo} from '../../../../components/logo';
import {Panel} from '../../../../components/web/panel';
import {PanelHeading} from '../../../../components/web/panel_heading';
import {Wrapper} from '../../../../scaffold/wrapper';

export interface OwnProps {
    onStepChange: (step: AuthenticateDialogStep) => void;
    closeDialog: () => void;
}

export class StartStep extends React.Component<OwnProps, {}> {
    public render() {
        return (
            <Panel border={false} padding={false} center={true}>
                <Button
                    className="close"
                    floatDirection="right"
                    appearance="icon"
                    icon="ion-md-close"
                    title={trans('buttons.close')}
                    action="Close-auth-dialog"
                    onClick={this.props.closeDialog}
                />
                <Logo />
                <PanelHeading align="center" title={trans('auth_dialog.start.title')} />
                <Wrapper type="text">{trans('auth_dialog.start.subtitle')}</Wrapper>

                <Divider />

                <Wrapper type="button">
                    <Button
                        floatDirection="right"
                        appearance="primary"
                        type="submit"
                        width="full"
                        action="Auth-dialog-register"
                        onClick={() => this.props.onStepChange(AuthenticateDialogStep.Register)}>
                        {trans('buttons.create_account')}
                    </Button>
                </Wrapper>

                <Wrapper type="button">
                    <Button
                        floatDirection="right"
                        appearance="secondary"
                        type="submit"
                        width="full"
                        action="Auth-dialog-register"
                        onClick={() => this.props.onStepChange(AuthenticateDialogStep.Login)}>
                        {trans('buttons.login_account')}
                    </Button>
                </Wrapper>
            </Panel>
        );
    }
}
