import {computed, observable} from 'mobx';

import {AccountInteractor} from '../business/account_interactor';
import {ImageInteractor} from '../business/image_interactor';
import {MomentInteractor} from '../business/moment_interactor';
import {PageEnum, PageInteractor} from '../business/page_interactor';
import {TripInteractor} from '../business/trip_interactor';
import {ServerAppAuth} from '../models/app_auth';
import {LocalImage} from '../models/image';
import {PersistedMoment} from '../models/moment';
import {PersistedTrip} from '../models/trip';
import {CompositeSubscription} from '../support/composit_subscription';
import {delayedNull} from '../support/delayed_null_subscription';
import {Presenter} from '../support/with_presenter';

export class TripWithMomentsProviderPresenter implements Presenter {
    @observable
    public trip: PersistedTrip | null = null;
    @observable
    public momentsMap: Map<string, PersistedMoment[]> = new Map();
    @observable
    public imagesMap: Map<string, LocalImage[]> = new Map();
    @observable
    private _appAuth: ServerAppAuth | null = null;
    @observable
    private _loadingMoments = true;
    @observable
    private _loadingTrip = true;
    @observable
    private _loadingAccount = true;
    @observable
    private _loadingImages = true;
    private _pageInteractor: PageInteractor;

    @computed
    public get loading() {
        return this._loadingMoments || this._loadingTrip || this._loadingAccount || this._loadingImages;
    }

    get canEditTrip() {
        if (this.trip === null) {
            return false;
        }

        //if no serverTrip, it is local so this user owns it
        if (this.trip.serverId === undefined) {
            return true;
        }

        //else check if the current accountId matches the trip its accountId
        if (this._appAuth === null || !this.trip) {
            return false;
        }

        return this._appAuth.account.id === this.trip.accountId;
    }
    private _tripUuid: string;

    private _tripInteractor: TripInteractor;
    private _momentInteractor: MomentInteractor;
    private _accountInteractor: AccountInteractor;
    private _imageInteractor: ImageInteractor;

    private _subscriptions = new CompositeSubscription();

    constructor(
        tripUuid: string,
        tripInteractor: TripInteractor,
        momentInteractor: MomentInteractor,
        accountInteractor: AccountInteractor,
        imageInteractor: ImageInteractor,
        pageInteractor: PageInteractor
    ) {
        this._tripUuid = tripUuid;
        this._tripInteractor = tripInteractor;
        this._momentInteractor = momentInteractor;
        this._accountInteractor = accountInteractor;
        this._imageInteractor = imageInteractor;
        this._pageInteractor = pageInteractor;
    }

    public mount() {
        this._subscriptions.add(
            delayedNull(this._tripInteractor.tripByUuid(this._tripUuid)).subscribe(trip => {
                if (trip) {
                    this.trip = trip;
                    this._loadingTrip = false;
                } else {
                    this._pageInteractor.navigate(PageEnum.TRIPS_INDEX);
                }
            })
        );
        this._subscriptions.add(
            this._momentInteractor.momentsMap.subscribe(momentsMap => {
                this.momentsMap = momentsMap;
                this._loadingMoments = false;
            })
        );
        this._subscriptions.add(
            this._imageInteractor.imagesMap.subscribe(imagesMap => {
                this.imagesMap = imagesMap;
                this._loadingImages = false;
            })
        );
        this._subscriptions.add(
            this._accountInteractor.appAuth().subscribe(appAuth => {
                this._appAuth = appAuth;
                this._loadingAccount = false;
            })
        );
    }

    public unmount() {
        this._subscriptions.clear();
    }
}
