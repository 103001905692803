import {ServerType, typeFromServer, typeToServer} from '../../enums/type';
import {ServerVisibility, visibilityFromServer, visibilityToServer} from '../../enums/visibility';
import {PersistedMoment, ServerMoment} from '../../models/moment';
import {ApiImage, ApiImageTransformer} from '../images/api_image_transformer';
import {
    ApiMomentCommentsTransformer,
    ApiMomentCommentWithAccount
} from '../moment_comment/api_moment_comments_transformer';
import {ApiMomentLike, ApiMomentLikesTransformer} from '../moment_likes/api_moment_likes_transformer';

import {ApiAccount, ApiAccountTransformer} from './../authorization/api_account_transformer';

export interface ApiMoment {
    id?: number;
    uuid: string;
    trip_id: number;
    title: string;
    description: string;
    type: ServerType;
    visibility: ServerVisibility;
    date: string;
    lat: number;
    lon: number;
    likes?: ApiMomentLike[];
    images?: ApiImage[];
    comments?: ApiMomentCommentWithAccount[];
    updated_at: string;
    primary_image_id: number | null;
}

export interface ApiMomentWithAccount extends ApiMoment {
    account: ApiAccount;
}

export class ApiMomentTransformer {
    public static toServerMoment(apiMoment: ApiMomentWithAccount): ServerMoment {
        return {
            uuid: apiMoment.uuid,
            serverId: apiMoment.id as number,
            serverTripId: apiMoment.trip_id,
            lat: apiMoment.lat,
            long: apiMoment.lon,
            title: apiMoment.title,
            description: apiMoment.description,
            timestamp: Date.parse(apiMoment.date),
            visibility: visibilityFromServer(apiMoment.visibility),
            type: typeFromServer(apiMoment.type),
            updatedAt: apiMoment.updated_at,
            primaryImageId: apiMoment.primary_image_id,
            images: apiMoment.images ? ApiImageTransformer.toImages(apiMoment.images) : [],
            likes: apiMoment.likes ? ApiMomentLikesTransformer.toServerMomentLikes(apiMoment.likes) : [],
            comments: apiMoment.comments ? ApiMomentCommentsTransformer.toServerMomentComments(apiMoment.comments) : [],
            account: ApiAccountTransformer.toServerPublicAccount(apiMoment.account)
        };
    }

    public static toServerMomentArray(apiMoments: ApiMomentWithAccount[]): ServerMoment[] {
        return apiMoments.map(apiMoment => {
            return ApiMomentTransformer.toServerMoment(apiMoment);
        });
    }

    public static fromMoment(moment: PersistedMoment): ApiMoment {
        return {
            id: moment.serverId,
            uuid: moment.uuid,
            title: moment.title,
            description: moment.description,
            type: typeToServer(moment.type),
            visibility: visibilityToServer(moment.visibility),
            date: new Date(moment.timestamp).toISOString(),
            lat: moment.lat ? moment.lat : 0,
            lon: moment.long ? moment.long : 0,
            trip_id: moment.serverTripId || 0,
            primary_image_id: moment.primaryImageId,
            updated_at: moment.updatedAt
        };
    }
}
