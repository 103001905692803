import {AccountInteractor} from '../business/account_interactor';

import {ImageApi, ImageApiImpl} from './images/image_api';
import {MomentApi, MomentApiImpl} from './moment/moment_api';
import {PhotoalbumApi, PhotoalbumApiImpl} from './photoalbum/photoalbum_api';
import {TripApi, TripApiImpl} from './trips/trip_api';

export interface NetworkComponent {
    momentApi: MomentApi;
    imageApi: ImageApi;
    tripApi: TripApi;
    photoalbumApi: PhotoalbumApi;
}

export class NetworkComponentImpl implements NetworkComponent {
    private _accountInteractor: AccountInteractor;
    private _momentApi?: MomentApi;
    private _imageApi?: ImageApi;
    private _tripApi?: TripApi;
    private _photoalbumApi?: PhotoalbumApi;

    constructor(accountInteractor: AccountInteractor) {
        this._accountInteractor = accountInteractor;
    }

    get momentApi(): MomentApi {
        if (this._momentApi === undefined) {
            this._momentApi = new MomentApiImpl(this._accountInteractor);
        }
        return this._momentApi;
    }

    get imageApi(): ImageApi {
        if (this._imageApi === undefined) {
            this._imageApi = new ImageApiImpl(this._accountInteractor);
        }
        return this._imageApi;
    }

    get tripApi(): TripApi {
        if (this._tripApi === undefined) {
            this._tripApi = new TripApiImpl(this._accountInteractor);
        }
        return this._tripApi;
    }

    get photoalbumApi(): PhotoalbumApi {
        if (this._photoalbumApi === undefined) {
            this._photoalbumApi = new PhotoalbumApiImpl(this._accountInteractor);
        }
        return this._photoalbumApi;
    }
}
