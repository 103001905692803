import {LocalImage, ServerImage} from '../models/image';

export function toUnpersistedImage(serverImage: ServerImage, persistedMomentUuid: string): LocalImage {
    return {
        uuid: serverImage.uuid,
        order: serverImage.order,
        fileName: serverImage.fileName,
        serverId: serverImage.serverId,
        serverMomentId: serverImage.serverMomentId,
        serverImageId: serverImage.serverImageId,
        momentUuid: persistedMomentUuid,
        lat: serverImage.lat,
        long: serverImage.long,
        title: serverImage.title,
        description: serverImage.description,
        timestamp: serverImage.timestamp,
        updatedAt: serverImage.updatedAt,
        requiresSyncWithServer: false
    };
}
