import {observer} from 'mobx-react';
import * as React from 'react';

import {trans} from '../../../i18n/trans';
import {RecoverPasswordPresenter} from '../../../presenters/recover_password_presenter';
import {Button} from '../../components/button';
import {Input} from '../../components/form/input';
import {Label} from '../../components/form/label';
import {Logo} from '../../components/logo';
import {PanelHeading} from '../../components/web/panel_heading';
import {Wrapper} from '../../scaffold/wrapper';

interface OwnProps {
    presenter: RecoverPasswordPresenter;
    renderCancelButton: boolean;
}

@observer
export class RecoverPasswordForm extends React.Component<OwnProps, {}> {
    public render() {
        return (
            <form onSubmit={this.props.presenter.submitForm}>
                <Logo />
                <PanelHeading align="center" title={trans('recover_password.title')} />

                <Wrapper type="input">
                    <Label for="email" value={trans('labels.email')} />
                    <Input
                        name="email"
                        type="email"
                        autoComplete="username"
                        placeholder={trans('placeholders.email')}
                        value={this.props.presenter.email}
                        onChange={e => this.props.presenter.emailChanged(e.currentTarget.value)}
                    />
                </Wrapper>

                <Wrapper type="button">
                    {this.props.renderCancelButton && (
                        <Button
                            floatDirection="left"
                            appearance="secondary"
                            action="Cancel"
                            onClick={this.props.presenter.onCancelClick}>
                            {trans('buttons.cancel')}
                        </Button>
                    )}
                    <Button
                        floatDirection="right"
                        appearance="primary"
                        width={this.props.renderCancelButton ? undefined : 'full'}
                        type="submit"
                        action="Send-email"
                        disabled={!this.props.presenter.validates}>
                        {trans('buttons.send_email')}
                    </Button>
                </Wrapper>
            </form>
        );
    }
}
