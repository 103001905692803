import * as React from 'react';

import '../../styles/scaffold/Wrapper.css';
import {classNames} from '../../support/classnames';

interface OwnProps {
    type?: 'input' | 'button' | 'image' | 'moment_detail' | 'text' | 'empty' | 'moments' | 'trip_moments' | 'trips';
    contentAlign?: 'left' | 'center' | 'right';
}

export class Wrapper extends React.PureComponent<OwnProps, {}> {
    public render() {
        const className = classNames('Wrapper', this.props.contentAlign, {
            'form-group': this.props.type === 'input',
            'button-wrapper': this.props.type === 'button',
            empty: this.props.type === 'empty',
            text: this.props.type === 'text',
            'image-wrapper': this.props.type === 'image',
            'moments-wrapper': this.props.type === 'moments',
            'trip-moments-wrapper': this.props.type === 'trip_moments',
            'moment-detail-wrapper': this.props.type === 'moment_detail',
            'trips-wrapper': this.props.type === 'trips'
        });

        return <div className={className}>{this.props.children}</div>;
    }
}
