import * as React from 'react';

import '../../../styles/components/form/Label.css';
import {classNames} from '../../../support/classnames';

interface OwnProps {
    for?: string;
    value?: string;
    type?: 'danger';
}

export class Label extends React.PureComponent<OwnProps> {
    public render() {
        return (
            <label className={classNames('Label', this.props.type)} htmlFor={this.props.for}>
                {this.props.value}
                {this.props.children}
            </label>
        );
    }
}
