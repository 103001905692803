import {computed, observable} from 'mobx';

import {FieldState, FormState} from '../../node_modules/formstate/lib';
import {AccountInteractor} from '../business/account_interactor';
import {PageEnum, PageInteractor} from '../business/page_interactor';
import {trans} from '../i18n/trans';
import {ServerAppAuth} from '../models/app_auth';
import {Presenter} from '../support/with_presenter';
import {emailValidator, requiredValidator} from '../ui/components/form/validator';

export class LoginPresenter implements Presenter {
    @observable
    public showLoader = false;
    @observable
    public errorMessage: string | null = null;

    private email = new FieldState('').disableAutoValidation().validators(emailValidator(trans('labels.email')));
    private password = new FieldState('')
        .disableAutoValidation()
        .validators(requiredValidator(trans('labels.password')));

    public form = new FormState({
        email: this.email,
        password: this.password
    });

    @computed
    public get formHasBeenValidated() {
        return Object.keys(this.form.$).every(key => this.form.$[key].hasBeenValidated);
    }

    private _accountInteractor: AccountInteractor;
    private _pageInteractor: PageInteractor;
    private _onCompleteCallback?: (appAuth: ServerAppAuth) => void;

    constructor(
        accountInteractor: AccountInteractor,
        pageInteractor: PageInteractor,
        onCompleteCallback?: (appAuth: ServerAppAuth) => void
    ) {
        this._accountInteractor = accountInteractor;
        this._pageInteractor = pageInteractor;
        this._onCompleteCallback = onCompleteCallback;
    }

    public mount() {
        /* Noop */
    }

    public unmount() {
        /* Noop */
    }

    public clearErrorMessage = () => {
        this.errorMessage = null;
    };

    public submitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const res = await this.form.validate();
        if (res.hasError) {
            console.warn(this.form.error);
            return;
        }

        this.showLoader = true;
        try {
            this.clearErrorMessage();
            const appAuth = await this._accountInteractor.authorizeUser(this.email.$, this.password.$);
            if (this._onCompleteCallback) {
                this._onCompleteCallback(appAuth);
            } else {
                this._pageInteractor.navigate(PageEnum.TRIPS_INDEX);
            }
        } catch (error) {
            if (error.result) {
                this.errorMessage = error.result.message;
            } else {
                this.errorMessage = trans('login.dialog_error');
            }
        } finally {
            this.showLoader = false;
        }
    };

    public onRegisterClick = () => {
        this._pageInteractor.navigate(PageEnum.REGISTER_SHOW);
    };

    public onRecoverPasswordClick = () => {
        this._pageInteractor.navigate(PageEnum.RECOVER_PASSWORD_SHOW);
    };
}
