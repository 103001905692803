import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './app';
import * as serviceWorker from './serviceWorker';
import './styles/App.css';

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
serviceWorker.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
        // iOS never really gets the chance to update. So force it by refreshing.
        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS) {
            const controller = navigator.serviceWorker.controller;
            if (controller) {
                //Send message to serviceWorker.js to skipWaiting()
                controller.postMessage({
                    type: 'SKIP_WAITING'
                });
            }

            window.location.reload(true);
        }
    }
});
