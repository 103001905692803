import {observer} from 'mobx-react';
import * as React from 'react';

import {Component} from '../../../../../component';
import {trans} from '../../../../../i18n/trans';
import {ServerAppAuth} from '../../../../../models/app_auth';
import {AuthenticateDialogStep} from '../../../../../presenters/authenticate_dialog_presenter';
import {LoginPresenter} from '../../../../../presenters/login_presenter';
import {PresenterProps, withPresenter} from '../../../../../support/with_presenter';
import {Button} from '../../../../components/button';
import {Loader} from '../../../../components/loader';
import {Panel} from '../../../../components/web/panel';
import {LoginForm} from '../../../../partials/web/login_form';
import {Wrapper} from '../../../../scaffold/wrapper';

export interface OwnProps {
    onStepChange: (step: AuthenticateDialogStep) => void;
    closeDialog: () => void;
    loggedInCallback: (appAuth: ServerAppAuth) => void;
}

@observer
class LoginStepComp extends React.Component<OwnProps & PresenterProps<LoginPresenter>, {}> {
    public render() {
        if (this.props.presenter.showLoader === true) {
            return <Loader />;
        }

        return (
            <Panel border={false} padding={false} center={true}>
                <Button
                    className="close"
                    floatDirection="right"
                    appearance="icon"
                    icon="ion-md-close"
                    title={trans('buttons.close')}
                    action="Close-auth-dialog"
                    onClick={this.props.closeDialog}
                />
                <Button
                    className="back"
                    floatDirection="left"
                    appearance="icon"
                    icon="ion-ios-arrow-back"
                    title={trans('buttons.back')}
                    action="Back"
                    onClick={() => this.props.onStepChange(AuthenticateDialogStep.Start)}
                />

                {this.props.presenter.errorMessage && (
                    <span className="notification">{this.props.presenter.errorMessage}</span>
                )}

                <LoginForm presenter={this.props.presenter} />

                <Wrapper type="button">
                    <Button
                        floatDirection="right"
                        appearance="link"
                        type="submit"
                        width="full"
                        action="Auth-dialog-forgot-password"
                        onClick={() => this.props.onStepChange(AuthenticateDialogStep.PasswordRecovery)}>
                        {trans('buttons.forgot_password')}
                    </Button>
                </Wrapper>
            </Panel>
        );
    }
}

export const LoginStep = withPresenter<LoginPresenter, OwnProps>(
    (props: OwnProps, component: Component) =>
        new LoginPresenter(component.accountInteractor, component.business.pageInteractor, props.loggedInCallback),
    LoginStepComp
);
