import * as React from 'react';

import '../../styles/scaffold/PageTitle.css';

interface OwnProps {
    title?: string;
    button?: any;
}

export class PageTitle extends React.PureComponent<OwnProps, {}> {
    public render() {
        return (
            <div className="PageTitle">
                {this.props.title ? <h2>{this.props.title}</h2> : null}
                {this.props.button ? this.props.button : null}
            </div>
        );
    }
}
