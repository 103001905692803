import {UploadedUppyFile} from '@uppy/core';
import {Observable} from 'rxjs';
import {shareReplay, switchMap} from 'rxjs/operators';

import {PersistedMoment} from '../models/moment';
import {MomentImageOrder} from '../models/moment_image_order';
import {ImageApi} from '../network/images/image_api';
import {ImageRepository} from '../repositories/image_repository';

import {LocalImage} from './../models/image';
import {S3ImageCreate, S3SignObject, S3SignRequest} from './../network/images/image_api';
import {UppyMetaData} from './uppy_interactor';

export class ImageInteractor {
    private _imageRepository: ImageRepository;
    private _imageApi: ImageApi;

    private _imagesMap: Observable<Map<string, LocalImage[]>> | null = null;

    constructor(imageRepository: ImageRepository, imageApi: ImageApi) {
        this._imageRepository = imageRepository;
        this._imageApi = imageApi;
    }

    public get imagesMap(): Observable<Map<string, LocalImage[]>> {
        if (this._imagesMap === null) {
            this._imagesMap = this._imageRepository.stream.pipe(
                switchMap(async images => ImageInteractor.toImagesMap(images)),
                shareReplay(1)
            );
        }
        return this._imagesMap;
    }

    public byImageUuid(imageUuid: string): Observable<LocalImage | null> {
        return this._imageRepository.byKey(imageUuid);
    }

    public static async toImagesMap(persistedImages: LocalImage[]): Promise<Map<string, LocalImage[]>> {
        const imageMap = new Map<string, LocalImage[]>();
        persistedImages.forEach(image => {
            if (image === null) {
                return;
            }
            if (imageMap.has(image.momentUuid)) {
                const images = imageMap.get(image.momentUuid);
                if (images) {
                    images.push(image);
                }
            } else {
                imageMap.set(image.momentUuid, [image]);
            }
        });

        imageMap.forEach((images, key) => {
            imageMap.set(key, [...images].sort((a, b) => a.order - b.order));
        });

        return imageMap;
    }

    public async updateImage(persistedImage: LocalImage, moment: PersistedMoment): Promise<LocalImage> {
        const locallySavedImage = await this._imageRepository.update({
            ...persistedImage,
            requiresSyncWithServer: true
        });

        if (moment.serverTripId && moment.serverId) {
            try {
                const serverSaved = await this._imageApi.updateImage(
                    locallySavedImage,
                    moment.serverTripId,
                    moment.serverId
                );
                return await this._imageRepository.update({
                    ...serverSaved,
                    requiresSyncWithServer: false
                });
            } catch (e) {
                console.warn(e);
                return locallySavedImage;
            }
        }

        return locallySavedImage;
    }

    public async delete(image: LocalImage, tripId?: number): Promise<void> {
        if (tripId && image.serverMomentId && image.serverId) {
            await this._imageApi.delete(tripId, image.serverMomentId, image.serverId);
        }
        return await this._imageRepository.destroy(image.uuid);
    }

    private extractFilenameFromUploadUrl(uploadUrl: string) {
        return uploadUrl.split('/files/')[1];
    }

    public async storeImages(
        uppyFiles: ReadonlyArray<UploadedUppyFile<UppyMetaData>>,
        moment: PersistedMoment
    ): Promise<LocalImage[]> {
        const unpersistedFiles = uppyFiles.map((uppyFile, index) => {
            const meta = uppyFile.meta || {};
            const coordinateData = meta && meta.coordinateData ? meta.coordinateData : null;
            const remoteFileName = this.extractFilenameFromUploadUrl(uppyFile.uploadURL);

            return {
                uppyFile,
                unpersistedImage: {
                    title: '',
                    description: '',
                    updatedAt: new Date().toISOString(),
                    uuid: meta.uuid,
                    momentUuid: moment.uuid,
                    requiresSyncWithServer: true,
                    timestamp: uppyFile.data instanceof File ? uppyFile.data.lastModified : Date.now(),
                    lat: coordinateData ? coordinateData.lat : 0,
                    long: coordinateData ? coordinateData.long : 0,
                    fileName: remoteFileName,
                    imagePublicUrl: uppyFile.uploadURL,
                    order: meta.order || index
                }
            };
        });

        const locallyStoredImagesPromises = unpersistedFiles.map(async image => {
            const persistedImage = await this._imageRepository.store({
                ...image.unpersistedImage,
                momentUuid: moment.uuid,
                serverMomentId: moment.serverId
            });
            return {
                persistedImage,
                uppyFile: image.uppyFile
            };
        });
        const locallyStoredImages = await Promise.all(locallyStoredImagesPromises);

        try {
            return await this.syncOnRemoteAndSaveLocally(locallyStoredImages, moment);
        } catch (e) {
            console.warn(e);
        }

        return locallyStoredImages.map(i => i.persistedImage);
    }

    public async syncOnRemoteAndSaveLocally(
        images: Array<{
            uppyFile: UploadedUppyFile<UppyMetaData>;
            persistedImage: LocalImage;
        }>,
        moment: PersistedMoment
    ): Promise<LocalImage[]> {
        if (moment.serverTripId !== undefined && moment.serverId !== undefined) {
            const localImages = images.filter(img => img.persistedImage.serverId === undefined);

            if (localImages.length > 0) {
                const s3Images: S3ImageCreate[] = localImages.map(localImg => {
                    const remoteFileName = this.extractFilenameFromUploadUrl(localImg.uppyFile.uploadURL);

                    return {
                        order: localImg.uppyFile.meta.order!,
                        original_file_name: localImg.uppyFile.meta.name!,
                        original_extension: localImg.uppyFile.extension!,
                        original_mime: localImg.uppyFile.type!,
                        file_name: remoteFileName,
                        uuid: localImg.persistedImage.uuid,
                        public_url: localImg.persistedImage.imagePublicUrl!,
                        lon: localImg.persistedImage.long || 0,
                        lat: localImg.persistedImage.lat || 0,
                        title: localImg.persistedImage.title,
                        description: localImg.persistedImage.description,
                        date: new Date(localImg.persistedImage.timestamp)
                    };
                });

                const serverCreatedImages = await this._imageApi.createImages(
                    s3Images,
                    moment.serverTripId,
                    moment.serverId
                );
                await Promise.all(
                    serverCreatedImages.map(async si => {
                        const matchingLocalImage = images.find(img => img.persistedImage.fileName === si.fileName);
                        if (matchingLocalImage !== undefined) {
                            await this._imageRepository.update({
                                ...si,
                                uuid: matchingLocalImage.persistedImage.uuid,
                                momentUuid: moment.uuid,
                                serverMomentId: moment.serverId,
                                requiresSyncWithServer: false
                            });
                        }
                    })
                );
            }
        }

        const allPersistedImages = await this._imageRepository.all();
        const thisMomentPersistedImages = allPersistedImages.filter(img => img.momentUuid === moment.uuid);
        return thisMomentPersistedImages;
    }

    public async getUploadParameters(tripServerId: number, file: S3SignRequest): Promise<S3SignObject> {
        return this._imageApi.requestSignedUrl(tripServerId, file);
    }

    public async updateMomentImagesOrder(
        tripId: number,
        momentId: number,
        imagesOrder: MomentImageOrder[]
    ): Promise<LocalImage[]> {
        const serverImages = await this._imageApi.updateMomentImagesOrder(tripId, momentId, imagesOrder);
        const localImages = await this._imageRepository.getMultiple(serverImages.map(si => si.uuid));

        const mergedLocalImages = serverImages.reduce(
            (acc, cv) => {
                const matchingLocalImage = localImages.find(img => img.uuid === cv.uuid);
                if (matchingLocalImage) {
                    acc.push({
                        ...matchingLocalImage,
                        ...cv
                    });
                }
                return acc;
            },
            [] as LocalImage[]
        );

        return await this._imageRepository.updateMultiple(mergedLocalImages);
    }
}
