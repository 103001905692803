import {FieldState} from 'formstate';
import {observer} from 'mobx-react';
import * as React from 'react';

import {trans} from '../../../i18n/trans';
import {Wrapper} from '../../scaffold/wrapper';

import {Input} from './input';
import {Label} from './label';

interface MyValidatorInputRenderProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface RenderPropValidatorProps<TRenderProps, TFieldStateType> extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    id: string;
    label: string;
    render: (props: MyValidatorInputProps<TRenderProps, TFieldStateType>) => React.ReactElement<JSX.Element>;
    fieldState: FieldState<TFieldStateType>;
}

function MyRenderPropValidator<T>({
    fieldState,
    render,
    label,
    ...passedProps
}: RenderPropValidatorProps<MyValidatorInputRenderProps, T>) {
    const props = {
        id: passedProps.name,
        onBlur: fieldState.enableAutoValidationAndValidate,
        ...passedProps,
        value: fieldState.value as any
    };

    return (
        <Wrapper type="input">
            <Label for={props.id} value={label}>
                {render({...props, fieldState, label})}
                {fieldState.hasError === true && <div className="validation warning">{fieldState.error}</div>}
            </Label>
        </Wrapper>
    );
}
export const RenderPropValidator = observer(MyRenderPropValidator);

interface MyValidatorInputProps<TRenderProps, TFieldStateType> extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    id: string;
    label: string;
    render?: (
        props: MyValidatorInputProps<TRenderProps, TFieldStateType>
    ) => React.ReactElement<React.InputHTMLAttributes<any>>;
    fieldState: FieldState<TFieldStateType>;
}

export const ValidatorInput: React.SFC<MyValidatorInputProps<MyValidatorInputRenderProps, string>> = observer(
    ({fieldState, render, label, ...passedProps}: MyValidatorInputProps<MyValidatorInputRenderProps, string>) => {
        const props = {
            id: passedProps.name,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => fieldState.onChange(e.currentTarget.value),
            onBlur: fieldState.enableAutoValidationAndValidate,
            ...passedProps,
            value: fieldState.value
        };

        return (
            <Wrapper type="input">
                <Label for={props.id} value={label}>
                    {render !== undefined ? render({...props, fieldState, label}) : <Input {...props} />}
                    {fieldState.hasError === true && <div className="validation warning">{fieldState.error}</div>}
                </Label>
            </Wrapper>
        );
    }
);

export function maxLengthValidator(name: string, maxLength: number) {
    return (val: string | number | number[]) => {
        if (typeof val === 'string') {
            return val.trim().length > maxLength ? trans('validation.max_length', {name, maxLength}) : null;
        }

        if (Array.isArray(val)) {
            return val.length > maxLength ? trans('validation.array_max_length', {name, maxLength}) : null;
        }

        return null;
    };
}

export function lengthValidator(name: string, length: number) {
    return (val: string | number | number[]) => {
        if (typeof val === 'string') {
            return val.trim().length > length ? null : trans('validation.length', {name, length});
        }

        if (Array.isArray(val)) {
            return val.length > length ? null : trans('validation.array_length', {name, length});
        }

        return null;
    };
}

export function requiredValidator(name: string) {
    return (val: string | number | number[] | undefined | null) => {
        if (typeof val === 'string') {
            return val.trim().length > 0 ? null : trans('validation.required', {name});
        }

        if (Array.isArray(val)) {
            return val.length > 0 ? trans('validation.required', {name}) : null;
        }

        return val !== undefined && val !== null ? null : trans('validation.required', {name});
    };
}

export function trueValidator(name?: string) {
    return (val: boolean) => {
        if (val !== true) {
            return trans('validation.checked', {name});
        }

        return null;
    };
}

export function emailValidator(name: string) {
    return (val: string | number | number[]) => {
        if (typeof val === 'string') {
            const isEmail = val.match('[A-z0-9._%+-]+@[A-z0-9.-]+.[A-z]{2,}');
            return isEmail ? null : trans('validation.email');
        }

        return null;
    };
}

export function passwordValidator(name: string) {
    return (val: string) => {
        if (val.length < 8) {
            return trans('validation.length', {name, length: 8});
        }
        if (val.replace(/[^A-Z]/g, '').length < 1) {
            return trans('validation.uppercase_characters', {name, amount: 1});
        }
        if (val.replace(/[^0-9]/g, '').length < 1) {
            return trans('validation.numeric_characters', {name, amount: 1});
        }

        return null;
    };
}

export function matchValidator(name: string, otherValue: string, otherName: string) {
    return (val: string | number | number[]) => {
        if (typeof val === 'string') {
            return val === otherValue ? null : trans('validation.match', {name, otherName});
        }

        return null;
    };
}
