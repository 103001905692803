import {AccountInteractor} from '../../business/account_interactor';
import {ServerPublicAccount, ServerPublicAccountWithNames} from '../../models/account';
import {ServerAppAuth} from '../../models/app_auth';
import {AuthorizationInterceptor} from '../request/interceptors/authorization_interceptor';
import {JsonRequest} from '../request/json_request';
import {RequestExecutor} from '../request/request_executor';

import {ApiAccount, ApiAccountTransformer} from './api_account_transformer';
import {ApiAppAuth, ApiAuthTransformer} from './api_auth_transformer';

export interface AccountApi {
    authorizeUser(email: string, password: string): Promise<ServerAppAuth>;
    authorizeToken(token: string): Promise<ServerAppAuth>;
    registerUser(
        email: string,
        firstName: string,
        lastName: string,
        password: string,
        passwordRepeat: string,
        reCaptcha: string
    ): Promise<ServerPublicAccount>;
    activateUser(token: string): Promise<boolean>;
    followUser(userId: number): Promise<boolean>;
    followers(): Promise<ServerPublicAccountWithNames[]>;
    followings(): Promise<ServerPublicAccountWithNames[]>;
    unfollow(accountId: number): Promise<boolean>;
    block(accountId: number): Promise<boolean>;
    acceptFollowRequest(token: string): Promise<boolean>;
    recoverPassword(email: string): Promise<boolean>;
    resetPassword(token: string, password: string, passwordRepeat: string): Promise<boolean>;
}

export class AccountApiImpl implements AccountApi {
    private _requestExecutor: RequestExecutor;

    constructor(accountInteractor: AccountInteractor) {
        this._requestExecutor = new RequestExecutor();
        this._requestExecutor.addInterceptor(new AuthorizationInterceptor(accountInteractor));
    }

    public async authorizeUser(email: string, password: string): Promise<ServerAppAuth> {
        const request = new JsonRequest<ApiAppAuth>('POST', 'authorize').addHeader(
            'Authorization',
            'Basic ' + btoa(`${email}:${password}`)
        );
        const response = await this._requestExecutor.execute(request);

        return ApiAuthTransformer.toServerAppAuth(response.result);
    }

    public async authorizeToken(token: string): Promise<ServerAppAuth> {
        const request = new JsonRequest<ApiAppAuth>('POST', 'authorize').addHeader('Authorization', 'Bearer ' + token);
        const response = await this._requestExecutor.execute(request);

        return ApiAuthTransformer.toServerAppAuth(response.result);
    }

    public async registerUser(
        email: string,
        firstName: string,
        lastName: string,
        password: string,
        passwordRepeat: string,
        reCaptcha: string
    ): Promise<ServerPublicAccount> {
        const apiAccount = ApiAccountTransformer.toApiAccountWithPasswords(
            email,
            firstName,
            lastName,
            password,
            passwordRepeat,
            reCaptcha
        );
        const request = new JsonRequest<ApiAccount>('POST', 'register').setJson(apiAccount);
        const response = await this._requestExecutor.execute(request);

        return ApiAccountTransformer.toServerPublicAccount(response.result);
    }

    public async activateUser(token: string): Promise<boolean> {
        const request = new JsonRequest<boolean>('POST', 'activate').setJson({
            token
        });
        const response = await this._requestExecutor.execute(request);

        return response.result;
    }

    public async unfollow(accountId: number): Promise<boolean> {
        const request = new JsonRequest<boolean>('POST', `me/followings/${accountId}/unfollow`);
        const response = await this._requestExecutor.execute(request);

        return response.result;
    }

    public async block(accountId: number): Promise<boolean> {
        const request = new JsonRequest<boolean>('POST', `me/followers/${accountId}/block`);
        const response = await this._requestExecutor.execute(request);

        return response.result;
    }

    public async followUser(userId: number): Promise<boolean> {
        const request = new JsonRequest<boolean>('POST', `follow-request/${userId}`);
        const response = await this._requestExecutor.execute(request);

        return response.result;
    }

    public async followers(): Promise<ServerPublicAccountWithNames[]> {
        const request = new JsonRequest<ApiAccount[]>('GET', 'me/followers');
        const response = await this._requestExecutor.execute(request);

        return ApiAccountTransformer.toServerPublicAccountsWithNames(response.result);
    }

    public async followings(): Promise<ServerPublicAccountWithNames[]> {
        const request = new JsonRequest<ApiAccount[]>('GET', 'me/followings');
        const response = await this._requestExecutor.execute(request);

        return ApiAccountTransformer.toServerPublicAccountsWithNames(response.result);
    }

    public async acceptFollowRequest(token: string): Promise<boolean> {
        const request = new JsonRequest<boolean>('POST', 'follow-request-accept').setJson({token});
        const response = await this._requestExecutor.execute(request);

        return response.result;
    }

    public async recoverPassword(email: string): Promise<boolean> {
        const request = new JsonRequest<boolean>('POST', 'recover-password').setJson({email});
        const response = await this._requestExecutor.execute(request);

        return response.result;
    }

    public async resetPassword(token: string, password: string, passwordRepeat: string): Promise<boolean> {
        const request = new JsonRequest<boolean>('POST', 'reset-password').setJson({token, password, passwordRepeat});
        const response = await this._requestExecutor.execute(request);

        return response.result;
    }
}
