import * as React from 'react';

import '../../styles/components/Divider.css';

interface OwnProps {}

export class Divider extends React.PureComponent<OwnProps> {
    public render() {
        return (
            <div className="Divider">
                <span />
            </div>
        );
    }
}
