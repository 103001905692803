import {TranslationObject} from '../trans';

export const enTranslations: TranslationObject = {
    onboarding: {
        continue: 'Record your first Travel Moment',
        page1: {
            title: 'Record your trip',
            subtitle: 'Capture all moments of your trip with My Travel Moments.'
        },
        page2: {
            title: 'Share your trip',
            subtitle: 'Easily share your trip with friends and family by sending a secret link.'
        },
        page3: {
            title: 'Order a photo album',
            subtitle: 'Easily order a photo album with the photos of your Travel Moments.'
        }
    },
    photoalbum: {
        explanation: {
            title: 'View and order your photoalbum',
            subtitle:
                'How does it work? We have made a photo album of all your trips. You can select, view and order below.',
            no_shipping_costs: 'No shippings costs'
        },
        preview: {
            title: 'Preview of your photoalbum',
            details: 'Order details',
            dialog: {
                title: 'Price indication',
                error: 'Something went wrong, please try again later.',
                subtitle: 'The photoalbum gets delivered with the following specifications:',
                labels: {
                    photoalbum: 'Photoalbum',
                    format: 'Fomrat',
                    pages_count: 'Amount of pages',
                    price: 'Price',
                    shipping: 'Shipping costs',
                    coupon: 'Coupon code',
                    total: 'Total:',
                    expected_delivery: 'Expected delivery time: 4-6 workingdays'
                },
                values: {
                    photoalbum: 'Hardcover with 200 grams glossy paper',
                    format: '29,7cm x 21,0cm (A4 landscape)',
                    shipping_costs: '€4.95',
                    no_shipping_costs: '(currently no shipping costs)',
                    coupon_explanation: 'Any discount will be settled in the next step.'
                }
            }
        }
    },
    errors: {
        trip_not_found: 'Could not find trip.',
        moment_not_found: 'Could not find moment.',
        image_not_found: 'Could not find image.',
        unauthorized: 'You are unauthorized to view this page'
    },
    nav: {
        title: 'My Travel Moments',
        moments_index: 'Discover',
        trips: 'Trips',
        photo_album: 'Photo album',
        account: 'My account',
        register: 'Create account',
        login: 'Login',
        logout: 'Logout'
    },
    sync: {
        server: 'Synchronizing with server'
    },
    navigator_share: {
        title: 'Follow invite',
        text: 'Someone has invited you to follow his/her moments on my TravelMoments'
    },
    placeholders: {
        share_input: 'Follow url',
        title: 'Title',
        description: 'Description',
        type: 'Select the type that best describes this Travel Moment',
        date: 'Date',
        visibility: 'Visibility',
        destination: 'Destination',
        departure_date: 'Departure date',
        return_date: 'Return date',
        location: 'Location',
        email: 'email@example.com',
        password: 'Password',
        password_repeat: 'Repeat password',
        first_name: 'First name',
        last_name: 'Last name',
        images: 'Images',
        search_location: 'Search location (eg place, beach, hotel...)',
        coupon: 'Coupon code',
        comment_content: 'Your comment.'
    },
    labels: {
        photos: 'Photos',
        about_moment: 'Description',
        category: 'Category',
        drag_images: 'Drag photos into this area or click in this area to select your photos.',
        title: 'Title',
        description: 'Description',
        type: 'Type',
        date: 'Date',
        visibility: 'Visibility',
        destination: 'Destination',
        departure_date: 'Departure date',
        return_date: 'Return date',
        location: 'Location',
        email: 'Email',
        password: 'Password',
        password_repeat: 'Repeat password',
        first_name: 'First name',
        last_name: 'Last name',
        images: 'Images',
        accept_terms_first: "By signing up, I agree to My Travel Moments'",
        accept_terms_service: 'Terms of Service',
        accept_terms_middle: 'and',
        accept_terms_privacy: 'Privacy Policy.',
        accept_terms_end: '',
        coupon: 'If you have a coupon code you can fill it in below.',
        first_comment: 'There are no comments yet. Be the first to leave a comment.',
        comment: 'Fill in the form below to leave your comment.',
        reply_comment: (args: {[key: string]: any}) => `Fill in the form below to reply to: ${args.name}`,
        edit_comment: 'Edit your comment below.'
    },
    default: {
        empty_description: 'No description'
    },
    buttons: {
        back: 'Back',
        block: 'Block',
        cancel: 'Cancel',
        close: 'Close',
        change_password: 'Change password',
        create: 'Create',
        create_moment: 'New Travel Moment',
        create_moment_disabled_titel: 'Trip has the limited amount of 100 Travel Moments.',
        create_first_moment: 'Create your first Travel Moment',
        create_trip: 'New trip',
        create_trip_disabled_title: 'Your account already has the limited amount of 25 trips.',
        share_trip: 'Share trip',
        delete: 'Delete',
        discover: 'Discover Travel Moments',
        edit: 'Edit',
        edit_moment: 'Edit Travel Moment',
        edit_trip: 'Edit trip',
        follow_user: (args: {[key: string]: any}) => `Follow ${args.name}`,
        login: 'Login',
        ok: 'Ok',
        register: 'Create an account',
        retry: 'Retry',
        save: 'Save',
        send_email: 'Send email',
        show_map: 'View larger map and zoom',
        unfollow: 'Unfollow',
        next: 'Next',
        previous: 'Previous',
        forgot_password: 'I forgot my password',
        create_account: 'Create an My Travel Moments account',
        login_account: 'Login',
        order_photoalbum: 'Order photoalbum',
        reply: 'Reply',
        downloadApp: 'Download My Travel Moments App'
    },

    //Pages
    accept_follow_request: {
        title: 'Accept follow request',
        dialog_success: 'Follow request accepted, other user can now see your follow-only moments.',
        dialog_failed: 'Failed accepting follow request, please try again.',
        dialog_error: 'Something went wrong accepting the follow request.'
    },

    activate_account: {
        title: 'Activate account',
        no_account_found: 'No unactive account found matching this link, is your account already activated?',
        dialog_error: 'Something went wrong while activating your account, please retry or create a new account.'
    },
    type: {
        viewpoint: 'Viewpoint',
        food: 'Food & drinks',
        culture: 'Culture & art',
        accommodation: 'Accommodation',
        nightlife: 'Nightlife',
        shopping: 'Shopping',
        transport: 'Transport',
        excursion: 'Excursion',
        activity: 'Sport & activity',
        nature: 'Nature',
        architecture: 'Architecture',
        relaxing: 'Relaxing',
        other: 'Other'
    },
    visibility: {
        public: 'Yes, I would like to inspire other people with my Travel Moment',
        followers: 'Yes, but only my followers can see my Travel Moment',
        private: 'No, my Travel Moment is only visible for me'
    },
    create_moment: {
        title: 'Create new Travel Moment',
        info_change_image:
            'First select the photos you want to share and then indicate who you want to inspire others.',
        info_visibility:
            'Travel Moments can have three types of visibility public, visible for followers and private. Private Travel Moments cannot be shared with or seen by others.',
        dialog_error: 'Something went wrong, please try again later.',
        about_info:
            'Tell something about this Travel Moments. When was it and what is the subject? The description is optional but can be inspiring for others.',
        type_info:
            'What describes this Travel Moment the best? To easily find Travel Moments we would like to know what kind of moment this is.',
        pick_location: 'Pick a location where this Travel Moment happened.'
    },
    edit_moment: {
        title: 'Edit Travel Moment',
        title_delete: 'Delete Travel Moment',
        info_delete: 'You can delete a moment. This action will remove all contents of this moment.',
        info_change_image_web:
            'To change the featured image, or the location of an image, please click on the image to edit. You can reorder the images by dragging them. A maximum of 6 images can be added below.',
        info_change_image_app:
            'To change the featured image, or the location of an image, please click on the image to edit. You can reorder the images by clicking on the buttons below them. A maximum of 6 images can be added below.',
        info_visibility:
            'Moments can have three types of visibility public, visible for followers and private. Private moments cannot be shared with or seen by others',
        dialog_error: 'Something went wrong, please try again later.',
        delete_confirm: 'Are you sure you want to delete this moment?'
    },
    moment_comment: {
        title: 'Comments',
        edited: (args: {[key: string]: any}) => `edited: ${args.date}`
    },
    create_trip: {
        title: 'Create new trip',
        dialog_error: 'Something went wrong, please try again later.'
    },
    edit_trip: {
        title: 'Edit trip',
        dialog_error: 'Something went wrong, please try again later.'
    },
    edit_image: {
        title: 'Edit image data',
        title_delete: 'Delete image',
        info_delete: 'You can delete an image. This action will remove all image data.',
        dialog_error: 'Something went wrong, please try again later.',
        delete_confirm: 'Are you sure you want to delete this image?'
    },
    follow_user: {
        title: 'Follow user',
        dialog_success: 'Email send to other user, please wait till the other user has accepted your request.',
        dialog_failed: 'Failed sending follow request, please try again.',
        dialog_error: 'Something went wrong following the other user.'
    },
    home: {
        title: 'Capture Travel Moments, Discover the world',
        intro_left:
            'Discover new places. Share your favourite places with others. That is what My Travel Moments is about. Our platform perfectly works together with Travelia, making sharing memories as easy as possible.',
        intro_right:
            ' In our believe, you never travel alone and there is always more to explore than you can find yourselves. That is why we offer you the perfect solution to capture your trip.'
    },
    index_moments: {
        title: 'Discover the world',
        title_empty: 'There are no Travel Moments yet.',
        intro:
            'My Travel Moments is the place to tell your story. Use the My Travel Moments application, or our integration with Travelia app to capture your stories. On this page you find the latest travelmoments.',
        info_empty: 'Please log in or use Travelia App to create moments.'
    },
    login: {
        title: 'Login to experience more',
        dialog_error: 'Something went wrong while logging in, please try again.'
    },
    auth_dialog: {
        start: {
            title: 'Log in or create an account to discover more.',
            subtitle: 'To perform this step you must be logged in. Log in or create an account.'
        }
    },
    my_account: {
        title_followers: 'Followers',
        title_followings: 'People you follow',
        title_follow_options: 'Follow options',
        info_followers: 'Below you find the people that follow you.',
        info_followings: 'Below you find the people that you follow.',
        info_follow_options:
            ' You can invite friends to follow your account. All moments that are public or followers only can be seen by your followers. The list of followers and people you follow is manageable on this page.',
        info_follow_url:
            'To invite people to follow you, please create a follow link with the button below. A follow link expires in seven days.',
        error_followers: 'Failed loading followers',
        error_followings: 'Failed loading accounts you follow',
        dialog_error: 'Something went wrong with creating a new follow link, please try again.',
        version: 'Version:'
    },
    my_trips: {
        title: 'My trips',
        title_followings: 'Trips of people I follow',
        intro_my_trips:
            'Here you find all your trips. Both trips that are created using the Travelia app, and trips that you created online are shown.',
        intro_following_trips: 'Here you find all the trips of the people that sent you a follow request.'
    },
    recover_password: {
        title: 'Reset your password',
        dialog_success: 'Please check your inbox to reset your password.'
    },
    register: {
        title: 'Create an account',
        check_inbox: 'Je hebt een mail gekregen met een link om je account te activeren.'
    },
    reset_password: {
        title: 'Reset your password'
    },
    select_image: {
        title: 'Change featured image',
        intro: 'Please select the primary image below.',
        dialog_error: 'Something went wrong, please try again later.'
    },
    show_moment: {
        title: 'My Travel Moment ',
        title_unsynchronized_images: 'Unsynchronized images',
        stats_comments: 'Comments',
        stats_photos: 'Photos',
        stats_offline: 'Offline',
        stats_not_synced: 'Not synced',
        stats_images: 'Photos',
        stats_likes: 'Likes',
        image_locations: 'Image locations'
    },
    show_trip: {
        title: 'My Travel Moments',
        title_empty: 'There are no Travel Moments yet.',
        title_create_moments: 'Do you want to create Travel Moments?',
        info_empty: 'Please log in or use Travelia App to create Travel Moments.',
        info_create_moments:
            'Discover new places. Share your favourite places with others. That is what My Travel Moments is about. Create Travel Moments within the app, and share them by using our web app on mytravelmoments.com.',
        day: 'Day',
        stats_days: 'Days',
        stats_moments: 'Moments',
        stats_images: 'Photos',
        share_title: 'Share trip',
        share_message:
            "You can use the link below to share this trip with other users. Even if they don't have an account them-self they will still be able to see your public and follower-only moments. Beware that anyone who has this link can view your public and follower-only moments for this trip."
    },
    uppy: {
        custom: {
            unloadWhileUploading: "There are still some file's being uploaded, are you sure you want to exit?",
            uploadingFailed:
                "Uploading of the images failed, we will try again in the background. Other users aren't able to see your new images just yet.",
            partialFailed:
                "Some images failed to upload, we will try those again in the background. Other users aren't able to see those images yet."
        },
        core: {
            youCanOnlyUploadX: {
                0: 'You can only upload %{smart_count} file',
                1: 'You can only upload %{smart_count} files'
            },
            youHaveToAtLeastSelectX: {
                0: 'You have to select at least %{smart_count} file',
                1: 'You have to select at least %{smart_count} files'
            },
            exceedsSize: 'This file exceeds maximum allowed size of',
            youCanOnlyUploadFileTypes: 'You can only upload:',
            uppyServerError: 'Connection with Uppy Server failed',
            failedToUpload: 'Failed to upload %{file}',
            noInternetConnection: 'No Internet connection',
            connectedToInternet: 'Connected to the Internet',
            noFilesFound: 'You have no files or folders here'
        },
        dashboard: {
            selectToUpload: 'Select files to upload',
            closeModal: 'Close Modal',
            upload: 'Upload',
            importFrom: 'Import from %{name}',
            dashboardWindowTitle: 'Uppy Dashboard Window (Press escape to close)',
            dashboardTitle: 'Uppy Dashboard',
            copyLinkToClipboardSuccess: 'Link copied to clipboard',
            copyLinkToClipboardFallback: 'Copy the URL below',
            copyLink: 'Copy link',
            fileSource: 'File source: %{name}',
            done: 'Done',
            name: 'Name',
            removeFile: 'Remove file',
            editFile: 'Edit file',
            editing: 'Editing %{file}',
            finishEditingFile: 'Finish editing file',
            saveChanges: 'Save changes',
            cancel: 'Cancel',
            localDisk: 'Local Disk',
            myDevice: 'My Device',
            dropPasteImport: 'Drop files here, paste, import from one of the locations above or %{browse}',
            dropPaste: 'Click %{browse} to upload your images',
            browse: 'here',
            fileProgress: 'File progress: upload speed and ETA',
            numberOfSelectedFiles: 'Number of selected files',
            uploadAllNewFiles: 'Upload all new files',
            emptyFolderAdded: 'No files were added from empty folder',
            uploadComplete: 'Upload complete',
            resumeUpload: 'Resume upload',
            pauseUpload: 'Pause upload',
            retryUpload: 'Retry upload',
            uploadXFiles: {
                0: 'Upload %{smart_count} file',
                1: 'Upload %{smart_count} files'
            },
            uploadXNewFiles: {
                0: 'Upload +%{smart_count} file',
                1: 'Upload +%{smart_count} files'
            },
            folderAdded: {
                0: 'Added %{smart_count} file from %{folder}',
                1: 'Added %{smart_count} files from %{folder}'
            }
        }
    },
    quota: {
        small: "There isn't enough space left on your device for My Travel Moments to function correctly."
    },
    validation: {
        length: (args: {[key: string]: any}) => `${args.name} should be at least ${args.length} characters long.`,
        array_length: (args: {[key: string]: any}) => `${args.name} should contain more than ${args.length} elements.`,
        max_length: (args: {[key: string]: any}) =>
            `${args.name} should be less than ${args.maxLength} characters long.`,
        array_max_length: (args: {[key: string]: any}) =>
            `${args.name} should contain less than ${args.maxLength} elements.`,
        required: (args: {[key: string]: any}) => `${args.name} is required.`,
        checked: (args: {[key: string]: any}) => (args.name ? `${args.name} should be checked.` : `Should be checked.`),
        email: `Should be an valid email.`,
        uppercase_characters: (args: {[key: string]: any}) =>
            `${args.name} should at least contain ${args.amount} uppercase ${
                args.amount > 1 ? 'characters' : 'character'
            }.`,
        numeric_characters: (args: {[key: string]: any}) =>
            `${args.name} should at least contain ${args.amount} numeric ${
                args.amount > 1 ? 'characters' : 'character'
            }.`,
        match: (args: {[key: string]: any}) => `${args.name} should match ${args.otherName}.`
    }
};
