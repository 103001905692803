import {observable} from 'mobx';

import {Presenter} from '../support/with_presenter';

export enum AuthenticateDialogStep {
    Start = 'start',
    Login = 'login',
    Register = 'register',
    PasswordRecovery = 'password-recovery'
}

export class AuthenticateDialogPresenter implements Presenter {
    @observable
    public step = AuthenticateDialogStep.Start;

    public mount() {
        /* Noop */
    }
    public unmount() {
        /* Noop */
    }

    public onStepChange = (step: AuthenticateDialogStep) => {
        this.step = step;
    };
}
