import * as LocalForage from 'localforage';
import {extendPrototype as extendPrototypeGetItems} from 'localforage-getitems';
import {extendPrototype as extendPrototypeRemoveItems} from 'localforage-removeitems';
import {extendPrototype as extendPrototypeSetItems} from 'localforage-setitems';

extendPrototypeSetItems(LocalForage);
extendPrototypeRemoveItems(LocalForage);
extendPrototypeGetItems(LocalForage);

export class LocalForageDriver<T> {
    private _store: LocalForage;
    constructor(name: string) {
        this._store = LocalForage.createInstance({name});
    }

    public async store(key: string, toBeStoredObject: T): Promise<T> {
        return await this._store.setItem<T>(key, toBeStoredObject);
    }

    public async storeMultiple(pairs: Array<{key: string; value: T}>): Promise<T[]> {
        return (this._store.setItems(pairs) as any) as Promise<T[]>;
    }

    public async get(key: string): Promise<T | null> {
        return (await this._store.getItem<T>(key)) || null;
    }

    public async getMultiple(keys: string[]): Promise<T[]> {
        const map = await this._store.getItems(keys);
        return Object.values(map);
    }

    public async delete(key: string): Promise<void> {
        return this._store.removeItem(key);
    }

    public async deleteMultiple(keys: string[]): Promise<void> {
        return this._store.removeItems(keys);
    }

    public async all(): Promise<T[]> {
        const map = await this._store.getItems();
        return Object.values(map);
    }

    public async clear(): Promise<void> {
        return this._store.clear();
    }
}
