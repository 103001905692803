import * as Cookie from 'js-cookie';
import {BehaviorSubject, Observable} from 'rxjs';

import {ServerPublicAccount, ServerPublicAccountWithNames} from '../models/account';
import {ServerAppAuth} from '../models/app_auth';
import {AccountApi, AccountApiImpl} from '../network/authorization/account_api';
import {LocalForageDriver} from '../repositories/drivers/localforage_driver';

export class AccountInteractor {
    private appAuthSubject = new BehaviorSubject<ServerAppAuth | null>(null);
    private _accountApi: AccountApi;

    constructor() {
        this._accountApi = new AccountApiImpl(this);

        //check if we have a AppAuth in our cookies
        const cookieAppAuth = Cookie.getJSON('app_auth');
        if (cookieAppAuth) {
            this.appAuthSubject.next(cookieAppAuth as ServerAppAuth);
        }

        this.appAuthSubject.subscribe(appAuth => {
            if (appAuth) {
                Cookie.set('app_auth', appAuth, {
                    expires: 7,
                    secure: window.location.hostname !== 'localhost' && process.env.NODE_ENV === 'production'
                });
            }
        });
    }

    public appAuth(): Observable<ServerAppAuth | null> {
        return this.appAuthSubject.asObservable();
    }

    public async acceptFollowRequest(token: string): Promise<boolean> {
        return await this._accountApi.acceptFollowRequest(token);
    }

    public async followUser(userId: number): Promise<boolean> {
        return await this._accountApi.followUser(userId);
    }

    public async followers(): Promise<ServerPublicAccountWithNames[]> {
        return this._accountApi.followers();
    }

    public async followings(): Promise<ServerPublicAccountWithNames[]> {
        return this._accountApi.followings();
    }

    public async unfollow(accountId: number): Promise<boolean> {
        return this._accountApi.unfollow(accountId);
    }

    public async block(accountId: number): Promise<boolean> {
        return this._accountApi.block(accountId);
    }

    public async authorizeToken(token: string): Promise<ServerAppAuth> {
        try {
            const previousCookie = Cookie.getJSON('app_auth') as ServerAppAuth;

            const previousLoginIsSame =
                previousCookie && previousCookie.sourceToken ? previousCookie.sourceToken === token : false;
            if (previousLoginIsSame) {
                this.appAuthSubject.next(previousCookie);
                return previousCookie;
            }

            const authData = await this._accountApi.authorizeToken(token);
            if (previousCookie && previousCookie.account.id !== authData.account.id) {
                await this.logout();
            }

            this.appAuthSubject.next({
                ...authData,
                sourceToken: token
            });

            return authData;
        } catch (error) {
            //if we catched an error, we are probably offline, try to get the authData from a cookie
            const authData = Cookie.getJSON('app_auth') as ServerAppAuth;
            if (authData) {
                return authData;
            } else {
                console.warn(error);
                throw new Error("Couldn't resolve token, maybe we never once authorized with this token?");
            }
        }
    }

    public async authorizeUser(email: string, password: string): Promise<ServerAppAuth> {
        const authData = await this._accountApi.authorizeUser(email, password);
        this.appAuthSubject.next(authData);

        return authData;
    }

    public async registerUser(
        email: string,
        firstName: string,
        lastName: string,
        password: string,
        passwordRepeat: string,
        reCaptcha: string
    ): Promise<ServerPublicAccount> {
        return await this._accountApi.registerUser(email, firstName, lastName, password, passwordRepeat, reCaptcha);
    }

    public async activateUser(token: string): Promise<boolean> {
        return await this._accountApi.activateUser(token);
    }

    public async logout() {
        Cookie.remove('app_auth');
        const imagesClearPromise = new LocalForageDriver('images').clear();
        const tripsClearPromise = new LocalForageDriver('trips').clear();
        const momentsClearPromise = new LocalForageDriver('moments').clear();
        await Promise.all([imagesClearPromise, tripsClearPromise, momentsClearPromise]);
        this.appAuthSubject.next(null);
    }

    public async recoverPassword(email: string) {
        return await this._accountApi.recoverPassword(email);
    }

    public async resetPassword(token: string, password: string, passwordRepeat: string) {
        return await this._accountApi.resetPassword(token, password, passwordRepeat);
    }
}
