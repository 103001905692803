import * as React from 'react';

import '../../../styles/components/form/Checkbox.css';

interface OwnProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
}

export class Checkbox extends React.Component<OwnProps, {}> {
    public render() {
        return <input type="checkbox" className="Checkbox form-control" {...this.props} />;
    }
}
