import * as LocalForage from 'localforage';
import {toJS} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';

import {AuthFlowHandler} from './auth_flow_handler';
import {NavigationState, PageEnum} from './business/page_interactor';
import {Component} from './component';
import {trans} from './i18n/trans';
import {AppPresenter} from './presenters/app_presenter';
import './styles/App.css';
import './styles/scaffold/PageContainer.css';
import {registerAutoTrack} from './support/autotrack';
import {classNames} from './support/classnames';
import {PresenterProps, withPresenter} from './support/with_presenter';
import {Footer} from './ui/components/web/footer';
import Nav from './ui/components/web/nav';
import ImageProvider from './ui/image_provider';
import ImagesProvider from './ui/images_provider';
import MomentProvider from './ui/moment_provider';
import TripWithMomentsProvider from './ui/trip_with_moments_provider';

//Register google analytics
registerAutoTrack();

//#region Lazy screen imports
const CreateMomentAppScreen = React.lazy(async () =>
    import(/* webpackChunkName: "app/create_moment" */ './ui/screens/app/create_moment')
);
const EditImageAppScreen = React.lazy(async () =>
    import(/* webpackChunkName: "app/edit_image" */ './ui/screens/app/edit_image')
);
const EditMomentAppScreen = React.lazy(async () =>
    import(/* webpackChunkName: "app/edit_moment" */ './ui/screens/app/edit_moment')
);
const OnboardingScreen = React.lazy(async () =>
    import(/* webpackChunkName: "app/onboarding" */ './ui/screens/app/onboarding')
);
const SelectMomentImageAppScreen = React.lazy(async () =>
    import(/* webpackChunkName: "app/select_moment_image" */ './ui/screens/app/select_moment_image')
);
const ShowMapsScreen = React.lazy(async () =>
    import(/* webpackChunkName: "app/show_maps" */ './ui/screens/app/show_maps')
);
const ShowMomentAppScreen = React.lazy(async () =>
    import(/* webpackChunkName: "app/show_moment" */ './ui/screens/app/show_moment')
);
const AcceptFollowRequestScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/accept_follow_request" */ './ui/screens/web/accept_follow_request')
);
const ActivateAccountScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/activate_account" */ './ui/screens/web/activate_account')
);
const CreateMomentWebScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/create_moment" */ './ui/screens/web/create_moment')
);
const CreateTripScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/create_trip" */ './ui/screens/web/create_trip')
);
const EditImageWebScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/edit_image" */ './ui/screens/web/edit_image')
);
const EditMomentWebScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/edit_moment" */ './ui/screens/web/edit_moment')
);
const IndexMomentsScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/index_moments" */ './ui/screens/web/index_moments')
);
const LoginScreen = React.lazy(async () => import(/* webpackChunkName: "web/login" */ './ui/screens/web/login'));
const MyAccountScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/my_account" */ './ui/screens/web/my_account')
);
const MyTripsScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/my_trips" */ './ui/screens/web/my_trips')
);
const RecoverPasswordScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/recover_password" */ './ui/screens/web/recover_password')
);
const RegisterScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/register" */ './ui/screens/web/register')
);
const ResetPasswordScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/reset_password" */ './ui/screens/web/reset_password')
);
const SelectMomentImageWebScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/select_moment_image" */ './ui/screens/web/select_moment_image')
);
const ShowMomentWebScreen = React.lazy(async () =>
    import(/* webpackChunkName: "web/show_moment" */ './ui/screens/web/show_moment')
);
const PhotoalbumExplanation = React.lazy(async () =>
    import(/* webpackChunkName: "web/photoalbum_explanation" */ './ui/screens/web/photoalbum_explanation')
);
const PhotoalbumPreview = React.lazy(async () =>
    import(/* webpackChunkName: "web/photoalbum_preview" */ './ui/screens/web/photoalbum_preview')
);

const ShowSharedTripAppScreen = React.lazy(async () => {
    return {
        default: (await import(/* webpackChunkName: "app/show_trip" */ './ui/screens/app/show_trip')).ShowSharedTrip
    };
});
const ShowSharedTripWebScreen = React.lazy(async () => {
    return {
        default: (await import(/* webpackChunkName: "web/show_trip" */ './ui/screens/web/show_trip')).ShowSharedTrip
    };
});

const ShowTripAppScreen = React.lazy(async () => {
    return {default: (await import(/* webpackChunkName: "app/show_trip" */ './ui/screens/app/show_trip')).ShowTrip};
});
const ShowTripWebScreen = React.lazy(async () => {
    return {default: (await import(/* webpackChunkName: "web/show_trip" */ './ui/screens/web/show_trip')).ShowTrip};
});

const EditTripScreen = React.lazy(async () => {
    return {default: (await import(/* webpackChunkName: "web/edit_trip" */ './ui/screens/web/edit_trip')).EditTrip};
});

const SelectTripImageWebScreen = React.lazy(async () => {
    return {
        default: (await import(/* webpackChunkName: "web/select_trip_image" */ './ui/screens/web/select_trip_image'))
            .SelectTripImage
    };
});
//#endregion

interface OwnProps {}

@observer
class App extends React.Component<OwnProps & PresenterProps<AppPresenter>> {
    constructor(props: OwnProps & PresenterProps<AppPresenter>) {
        super(props);

        LocalForage.config({
            driver: LocalForage.WEBSQL,
            size: 50 * 1024 * 1024,
            storeName: 'keyvaluepairs'
        });

        (window as any).__SomehowWeNeedThis__ = () => {
            //Somehow if we don't import the AuthFlowHandler here and do something with it
            //All the imported modules below and including AuthFlowHandler will resolve to undefined,
            //Super weird >:(
            console.info(AuthFlowHandler);
        };
    }

    private renderScreen(UIMode: 'app' | 'web') {
        const currentNavigationState = this.props.presenter.currentNavigationState;
        if (currentNavigationState === null || this.props.presenter.appLoginBusy) {
            //TODO add loader, there will always a navigation state after it has been constructed
            return null;
        }

        switch (currentNavigationState.page) {
            case PageEnum.PHOTOALBUM_EXPLANATION:
                return <PhotoalbumExplanation />;
            case PageEnum.PHOTOALBUM_PREVIEW:
                return <PhotoalbumPreview tripServerId={currentNavigationState.parameters[0]} />;
            case PageEnum.RESET_PASSWORD_SHOW:
                return <ResetPasswordScreen token={currentNavigationState.parameters[0]} />;
            case PageEnum.RECOVER_PASSWORD_SHOW:
                return <RecoverPasswordScreen />;
            case PageEnum.ACCEPT_FOLLOW_REQUEST:
                return <AcceptFollowRequestScreen token={currentNavigationState.parameters[0]} />;
            case PageEnum.ACTIVATE_ACCOUNT:
                return <ActivateAccountScreen token={currentNavigationState.parameters[0]} />;
            case PageEnum.MY_ACCOUNT:
                return <MyAccountScreen />;
            case PageEnum.REGISTER_SHOW:
                return <RegisterScreen />;
            case PageEnum.LOGIN_SHOW: {
                const redirectState = currentNavigationState.queryParameters.redirect;
                const onCompleteCallback = !redirectState
                    ? undefined
                    : () => {
                          if (redirectState) {
                              const navigationState: NavigationState = JSON.parse(atob(redirectState));
                              this.props.presenter.navigateToState(navigationState);
                          }
                      };
                return <LoginScreen onCompleteCallback={onCompleteCallback} />;
            }
            case PageEnum.MOMENTS_INDEX:
                return <IndexMomentsScreen />;
            case PageEnum.TRIPS_INDEX:
                return <MyTripsScreen />;
            case PageEnum.TRIP_CREATE:
                if (this.props.presenter.account === null) {
                    //TODO Olav make pretty?
                    return 'Unauthorized';
                }
                return <CreateTripScreen account={toJS(this.props.presenter.account)} />;
            case PageEnum.TRIP_EDIT:
                return (
                    <TripWithMomentsProvider
                        requiresOwnership
                        tripUuid={currentNavigationState.parameters[0]}
                        render={(trip, momentsMap, imagesMap) => (
                            <EditTripScreen trip={trip} momentsMap={momentsMap} imagesMap={imagesMap} />
                        )}
                    />
                );
            case PageEnum.SHARED_MOMENT_SHOW:
            case PageEnum.MOMENT_SHOW:
                return (
                    <MomentProvider
                        momentUuid={currentNavigationState.parameters[0]}
                        render={(moment, hasOwnership) =>
                            UIMode === 'web' ? (
                                <ShowMomentWebScreen moment={moment} hasOwnership={hasOwnership} />
                            ) : (
                                <ShowMomentAppScreen moment={moment} hasOwnership={hasOwnership} />
                            )
                        }
                    />
                );
            case PageEnum.MOMENT_IMAGE_SELECT:
                return (
                    <MomentProvider
                        requiresOwnership
                        momentUuid={currentNavigationState.parameters[0]}
                        render={moment =>
                            UIMode === 'web' ? (
                                <SelectMomentImageWebScreen moment={moment} />
                            ) : (
                                <SelectMomentImageAppScreen moment={moment} />
                            )
                        }
                    />
                );
            case PageEnum.TRIP_IMAGE_SELECT:
                return (
                    <TripWithMomentsProvider
                        requiresOwnership
                        tripUuid={currentNavigationState.parameters[0]}
                        render={(trip, momentsMap, imagesMap) => (
                            <SelectTripImageWebScreen trip={trip} momentsMap={momentsMap} imagesMap={imagesMap} />
                        )}
                    />
                );
            case PageEnum.MAPS_SHOW:
                return (
                    <MomentProvider
                        momentUuid={currentNavigationState.parameters[0]}
                        render={moment => <ShowMapsScreen moment={moment} />}
                    />
                );
            case PageEnum.IMAGE_EDIT:
                return (
                    <MomentProvider
                        requiresOwnership
                        momentUuid={currentNavigationState.parameters[0]}
                        render={moment => (
                            <ImageProvider
                                imageId={currentNavigationState.parameters[1]}
                                render={image =>
                                    UIMode === 'web' ? (
                                        <EditImageWebScreen moment={moment} image={image} />
                                    ) : (
                                        <EditImageAppScreen moment={moment} image={image} />
                                    )
                                }
                            />
                        )}
                    />
                );
            case PageEnum.MOMENT_EDIT:
                return (
                    <MomentProvider
                        requiresOwnership
                        momentUuid={currentNavigationState.parameters[0]}
                        render={moment => (
                            <ImagesProvider
                                momentUuid={currentNavigationState.parameters[0]}
                                render={images =>
                                    UIMode === 'web' ? (
                                        <EditMomentWebScreen moment={moment} images={images} />
                                    ) : (
                                        <EditMomentAppScreen moment={moment} images={images} />
                                    )
                                }
                            />
                        )}
                    />
                );
            case PageEnum.MOMENT_CREATE:
                return (
                    <TripWithMomentsProvider
                        tripUuid={currentNavigationState.parameters[0]}
                        render={trip => {
                            if (this.props.presenter.account === null) {
                                //TODO Olav make pretty?
                                return 'Unauthorized';
                            }
                            return UIMode === 'web' ? (
                                <CreateMomentWebScreen account={toJS(this.props.presenter.account)} trip={trip} />
                            ) : (
                                <CreateMomentAppScreen account={toJS(this.props.presenter.account)} trip={trip} />
                            );
                        }}
                    />
                );
            case PageEnum.TRIP_SHOW:
                return (
                    <TripWithMomentsProvider
                        tripUuid={currentNavigationState.parameters[0]}
                        render={(trip, momentsMap, imagesMap, hasOwnership) =>
                            UIMode === 'web' ? (
                                <ShowTripWebScreen
                                    trip={trip}
                                    momentsMap={momentsMap}
                                    imagesMap={imagesMap}
                                    hasOwnership={hasOwnership}
                                />
                            ) : (
                                <ShowTripAppScreen trip={trip} momentsMap={momentsMap} imagesMap={imagesMap} />
                            )
                        }
                    />
                );
            case PageEnum.SHARED_TRIP_SHOW:
                return UIMode === 'web' ? (
                    <ShowSharedTripWebScreen secretTripUuid={currentNavigationState.parameters[0]} />
                ) : (
                    <ShowSharedTripAppScreen secretTripUuid={currentNavigationState.parameters[0]} />
                );
            default:
                return UIMode === 'app' ? null : <IndexMomentsScreen />;
        }
    }

    public render() {
        const UIMode = this.props.presenter.currentUIMode;
        const divClassName = classNames('App', {
            MobileApp: UIMode === 'app',
            DesktopApp: UIMode === 'web'
        });

        const spanClassName = classNames('beta', {
            desktop: UIMode === 'web',
            mobile: UIMode === 'app'
        });

        if (this.props.presenter.showOnboarding) {
            return (
                <React.Suspense fallback={<span className="loading">{trans('sync.server')}</span>}>
                    <div className={divClassName}>
                        <OnboardingScreen onFinish={this.props.presenter.onOnboardingFinish} />
                    </div>
                </React.Suspense>
            );
        }

        return (
            <div className={divClassName}>
                {UIMode === 'web' ? <Nav /> : null}
                <div className={spanClassName}>
                    <span>Beta</span>
                </div>

                {this.props.presenter.isSynchronizing || this.props.presenter.appLoginBusy ? (
                    <span className="loading">{trans('sync.server')}</span>
                ) : null}

                {this.props.presenter.smallQuota ? (
                    <span className="notification top">{trans('quota.small')}</span>
                ) : null}

                <div className="PageContainer">
                    <React.Suspense fallback={<span className="loading">{trans('sync.server')}</span>}>
                        {this.renderScreen(UIMode)}
                    </React.Suspense>
                </div>

                {UIMode === 'web' ? (
                    <Footer
                        className={classNames({
                            small:
                                this.props.presenter.currentNavigationState &&
                                this.props.presenter.currentNavigationState.page === PageEnum.MOMENT_SHOW
                        })}
                    />
                ) : null}
            </div>
        );
    }
}

export default withPresenter<AppPresenter, OwnProps>(
    (props, component: Component) =>
        new AppPresenter(
            component.accountInteractor,
            component.business.pageInteractor,
            component.business.tripInteractor,
            component.business.colorsInteractor,
            component.business.momentSynchroniser,
            component.business.imageSynchroniser,
            component.network.momentApi
        ),
    App
);
