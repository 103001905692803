import {ServerMoment, UnpersistedMoment} from '../models/moment';

export function toUnpersistedMoment(serverMoment: ServerMoment, tripId: string | null): UnpersistedMoment {
    return {
        uuid: serverMoment.uuid,
        serverId: serverMoment.serverId,
        tripUuid: tripId,
        serverTripId: serverMoment.serverTripId,
        lat: serverMoment.lat,
        long: serverMoment.long,
        title: serverMoment.title,
        description: serverMoment.description,
        visibility: serverMoment.visibility,
        type: serverMoment.type,
        likes: serverMoment.likes,
        comments: serverMoment.comments,
        updatedAt: serverMoment.updatedAt,
        timestamp: serverMoment.timestamp,
        primaryImageId: serverMoment.primaryImageId,
        requiresSyncWithServer: false,
        account: serverMoment.account
    };
}
