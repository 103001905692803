import {computed, observable} from 'mobx';

import {AccountInteractor} from '../business/account_interactor';
import {ColorsInteractor} from '../business/colors_interactor';
import {NavigationState, PageEnum, PageInteractor} from '../business/page_interactor';
import {CompositeSubscription} from '../support/composit_subscription';
import {Presenter} from '../support/with_presenter';

export class NavPresenter implements Presenter {
    @observable
    public currentNavigationState: NavigationState | null = null;
    @observable
    public isAuthenticated: boolean = false;
    @observable
    public menuOpen: boolean = false;

    private _accountInteractor: AccountInteractor;
    private _pageInteractor: PageInteractor;
    private _colorsInteractor: ColorsInteractor;
    private _subscriptions = new CompositeSubscription();

    constructor(
        accountInteractor: AccountInteractor,
        pageInteractor: PageInteractor,
        colorsInteractor: ColorsInteractor
    ) {
        this._accountInteractor = accountInteractor;
        this._pageInteractor = pageInteractor;
        this._colorsInteractor = colorsInteractor;
    }

    @computed
    get navigationBarColor(): string | null {
        return this._colorsInteractor.navigationBarColor();
    }
    @computed
    get navigationBarForegroundColor(): string | null {
        return this._colorsInteractor.navigationBarForegroundColor();
    }

    public mount() {
        this._subscriptions.add(
            this._accountInteractor.appAuth().subscribe(appAuth => {
                this.isAuthenticated = appAuth !== null;
            })
        );
        this._subscriptions.add(
            this._pageInteractor.currentPage().subscribe(page => {
                this.currentNavigationState = page;
            })
        );
    }

    public unmount() {
        this._subscriptions.clear();
    }

    public menuTriggerClicked = () => {
        this.menuOpen = !this.menuOpen;
    };

    public closeMenu = () => {
        this.menuOpen = false;
    };

    public logoutClicked = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        await this._accountInteractor.logout();
        this._pageInteractor.navigate(PageEnum.LOGIN_SHOW);
        this.menuOpen = false;
    };
}
