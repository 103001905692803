import {ServerPhotoalbumPreview} from '../../models/photoalbum_preview';

import {ApiPhotoalbumPreview} from './photoalbum_api';

export class ApiPhotoalbumTransformer {
    public static toServerPhotoalbumPreview(preview: ApiPhotoalbumPreview): ServerPhotoalbumPreview {
        return {
            numOfPages: preview.num_of_pages,
            sprites: preview.sprites,
            hash: preview.hash,
            price: preview.price
        };
    }
}
