import {Observable, Subject} from 'rxjs';
import {debounceTime, finalize, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {PersistedMoment, UnpersistedMoment} from '../../models/moment';
import {LocalForageDriver} from '../drivers/localforage_driver';
import {MomentRepository} from '../moment_repository';

export class LocalForageMomentRepository implements MomentRepository {
    private _changesSubject = new Subject<null>();
    private momentStore = new LocalForageDriver<PersistedMoment>('moments');

    private _stream?: Observable<PersistedMoment[]>;
    public get stream(): Observable<PersistedMoment[]> {
        if (this._stream === undefined) {
            this._stream = this._changesSubject.pipe(
                startWith(this.all()),
                debounceTime(100),
                switchMap(async () => this.all()),
                shareReplay(1)
            );
        }
        return this._stream;
    }

    private _byKeyMap: Map<string, Observable<PersistedMoment | null>> = new Map();
    public byKey(key: string): Observable<PersistedMoment | null> {
        let observable = this._byKeyMap.get(key);
        if (observable === undefined) {
            observable = this._changesSubject.pipe(
                startWith(this.momentStore.get(key)),
                switchMap(async () => this.momentStore.get(key)),
                finalize(() => this._byKeyMap.delete(key)),
                shareReplay(1)
            );
            this._byKeyMap.set(key, observable);
        }
        return observable;
    }

    public async all(): Promise<PersistedMoment[]> {
        return this.momentStore.all();
    }

    public async store(moment: UnpersistedMoment): Promise<PersistedMoment> {
        const persistedMoment = await this.momentStore.store(moment.uuid, moment);
        this._changesSubject.next(null);

        return persistedMoment;
    }

    public async storeMultiple(moments: UnpersistedMoment[]): Promise<PersistedMoment[]> {
        const momentsMap = moments.map(m => ({key: m.uuid, value: m}));
        const persistedMoments = await this.momentStore.storeMultiple(momentsMap);
        this._changesSubject.next(null);

        return persistedMoments;
    }

    public async update(moment: PersistedMoment): Promise<PersistedMoment> {
        const updatedMoment = await this.momentStore.store(moment.uuid, moment);
        this._changesSubject.next(null);

        return updatedMoment;
    }

    public async destroy(momentUuid: string): Promise<void> {
        await this.momentStore.delete(momentUuid);
        this._changesSubject.next(null);
    }

    public async destroyMultiple(momentUuids: string[]): Promise<void> {
        await this.momentStore.deleteMultiple(momentUuids);
        this._changesSubject.next(null);
    }
}
