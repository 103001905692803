import {Observable, Subject} from 'rxjs';
import {debounceTime, finalize, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {LocalImage} from '../../models/image';
import {LocalForageDriver} from '../drivers/localforage_driver';
import {ImageRepository} from '../image_repository';

export class LocalforageImageRepository implements ImageRepository {
    private _changesSubject = new Subject<null>();
    private imageStore = new LocalForageDriver<LocalImage>('images');

    private _stream?: Observable<LocalImage[]>;
    public get stream(): Observable<LocalImage[]> {
        if (this._stream === undefined) {
            this._stream = this._changesSubject.pipe(
                debounceTime(100),
                startWith(this.all()),
                switchMap(async () => this.all()),
                shareReplay(1)
            );
        }
        return this._stream;
    }

    private _byKeyMap: Map<string, Observable<LocalImage | null>> = new Map();
    public byKey(key: string): Observable<LocalImage | null> {
        let observable = this._byKeyMap.get(key);
        if (observable === undefined) {
            observable = this._changesSubject.pipe(
                startWith(this.imageStore.get(key)),
                switchMap(async () => this.imageStore.get(key)),
                finalize(() => this._byKeyMap.delete(key)),
                shareReplay(1)
            );
            this._byKeyMap.set(key, observable);
        }
        return observable;
    }

    public async all(): Promise<LocalImage[]> {
        return this.imageStore.all();
    }

    public async update(image: LocalImage): Promise<LocalImage> {
        return this.store(image);
    }

    public async updateMultiple(images: LocalImage[]): Promise<LocalImage[]> {
        return this.storeMultiple(images);
    }

    public async store(image: LocalImage): Promise<LocalImage> {
        const persistedImage = await this.imageStore.store(image.uuid, image);
        this._changesSubject.next(null);

        return persistedImage;
    }

    public async storeMultiple(images: LocalImage[]): Promise<LocalImage[]> {
        const imagesMap = images.map(img => ({key: img.uuid, value: img}));
        const persistedImages = await this.imageStore.storeMultiple(imagesMap);
        this._changesSubject.next(null);

        return persistedImages;
    }

    public async get(imageUuid: string): Promise<LocalImage | null> {
        const image = await this.imageStore.get(imageUuid);
        return image || null;
    }

    public async getMultiple(imageUuids: string[]): Promise<LocalImage[]> {
        return this.imageStore.getMultiple(imageUuids);
    }

    public async destroy(imageUuid: string): Promise<void> {
        await this.imageStore.delete(imageUuid);
        this._changesSubject.next(null);
    }

    public async destroyMultiple(imageUuids: string[]): Promise<void> {
        await this.imageStore.deleteMultiple(imageUuids);
        this._changesSubject.next(null);
    }

    public async exists(imageUuid: string): Promise<boolean> {
        return this.imageStore.get(imageUuid) !== undefined;
    }
}
