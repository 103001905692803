export function registerAutoTrack() {
    window.addEventListener('load', () => {
        import('autotrack').then(() => {
            window.ga =
                window.ga ||
                function() {
                    (window.ga.q = window.ga.q || []).push(arguments);
                };
            window.ga.l = +new Date();
            window.ga('create', 'UA-111638720-1', 'auto');

            // Replace the following lines with the plugins you want to use.
            window.ga('require', 'cleanUrlTracker');
            window.ga('require', 'eventTracker');
            window.ga('require', 'outboundLinkTracker');
            window.ga('require', 'pageVisibilityTracker');
            window.ga('require', 'urlChangeTracker');
            window.ga('send', 'pageview');
        });
    });
}
