import {observer} from 'mobx-react';
import * as React from 'react';

import {Component} from '../../../../../component';
import {trans} from '../../../../../i18n/trans';
import {AuthenticateDialogStep} from '../../../../../presenters/authenticate_dialog_presenter';
import {RecoverPasswordPresenter} from '../../../../../presenters/recover_password_presenter';
import {PresenterProps, withPresenter} from '../../../../../support/with_presenter';
import {Button} from '../../../../components/button';
import {Loader} from '../../../../components/loader';
import {Logo} from '../../../../components/logo';
import {Panel} from '../../../../components/web/panel';
import {PanelHeading} from '../../../../components/web/panel_heading';
import {RecoverPasswordForm} from '../../../../partials/web/recover_password_form';

export interface OwnProps {
    onStepChange: (step: AuthenticateDialogStep) => void;
    closeDialog: () => void;
}

@observer
export class PasswordRecoveryStepComp extends React.Component<OwnProps & PresenterProps<RecoverPasswordPresenter>, {}> {
    public render() {
        if (this.props.presenter.showLoader === true) {
            return <Loader />;
        }

        return (
            <Panel border={false} padding={false} center={true}>
                <Button
                    className="close"
                    floatDirection="right"
                    appearance="icon"
                    icon="ion-md-close"
                    title={trans('buttons.close')}
                    action="Close-auth-dialog"
                    onClick={this.props.closeDialog}
                />
                <Button
                    className="back"
                    floatDirection="left"
                    appearance="icon"
                    icon="ion-ios-arrow-back"
                    title={trans('buttons.back')}
                    action="Back"
                    onClick={() => this.props.onStepChange(AuthenticateDialogStep.Login)}
                />

                {this.props.presenter.dialogSucceededMessage !== null && (
                    <div>
                        <Logo />
                        <PanelHeading align="center" title={this.props.presenter.dialogSucceededMessage} />
                        <Button
                            floatDirection="right"
                            appearance="primary"
                            width="full"
                            action="ok"
                            onClick={this.props.closeDialog}>
                            {trans('buttons.ok')}
                        </Button>
                    </div>
                )}

                {this.props.presenter.dialogFailedMessage !== null && (
                    <div>
                        <Logo />
                        <PanelHeading align="center" title={this.props.presenter.dialogFailedMessage} />
                        <Button
                            floatDirection="right"
                            appearance="primary"
                            width="full"
                            action="ok"
                            onClick={this.props.presenter.clearErrorMessage}>
                            {trans('buttons.ok')}
                        </Button>
                    </div>
                )}

                {this.props.presenter.dialogSucceededMessage === null &&
                    this.props.presenter.dialogFailedMessage === null && (
                        <RecoverPasswordForm presenter={this.props.presenter} renderCancelButton={false} />
                    )}
            </Panel>
        );
    }
}

export const PasswordRecoveryStep = withPresenter<RecoverPasswordPresenter, OwnProps>(
    (props: OwnProps, component: Component) =>
        new RecoverPasswordPresenter(component.accountInteractor, component.business.pageInteractor),
    PasswordRecoveryStepComp
);
