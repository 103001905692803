import {ServerMomentComment, UnpersistedMomentComment} from '../../models/moment_comment';
import {ApiAccount, ApiAccountTransformer} from '../authorization/api_account_transformer';

export interface ApiMomentComment {
    id?: number;
    account_id: number;
    moment_id: number;
    parent_comment_id: number | null;
    content: string;
    created_at?: string;
    updated_at?: string;
}

export interface ApiMomentCommentWithAccount extends ApiMomentComment {
    account: ApiAccount;
}

export class ApiMomentCommentsTransformer {
    public static toServerMomentComment(apiMomentComment: ApiMomentCommentWithAccount): ServerMomentComment {
        return {
            serverId: apiMomentComment.id as number,
            serverAccountId: apiMomentComment.account_id,
            serverMomentId: apiMomentComment.moment_id,
            serverParentCommentId: apiMomentComment.parent_comment_id,
            content: apiMomentComment.content,
            createdAt: apiMomentComment.created_at ? new Date(apiMomentComment.created_at) : new Date(),
            updatedAt: apiMomentComment.updated_at ? new Date(apiMomentComment.updated_at) : new Date(),
            account: ApiAccountTransformer.toServerPublicAccountWithNames(apiMomentComment.account)
        };
    }

    public static toServerMomentComments(apiMomentLikes: ApiMomentCommentWithAccount[]): ServerMomentComment[] {
        return apiMomentLikes.map(apiMomentLike => {
            return ApiMomentCommentsTransformer.toServerMomentComment(apiMomentLike);
        });
    }

    public static fromComment(comment: UnpersistedMomentComment): ApiMomentComment {
        return {
            id: comment.serverId,
            account_id: comment.serverAccountId,
            moment_id: comment.serverMomentId,
            parent_comment_id: comment.serverParentCommentId,
            content: comment.content
        };
    }
}
