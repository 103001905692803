import {UppyFile} from '@uppy/core';
import {Observable, Subject} from 'rxjs';
import {debounceTime, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {UppyMetaData} from './uppy_interactor';

const UNSYNCED_CACHE_NAME = 'unsynced-images';

export class ImageCacheInteractor {
    private _changesSubject = new Subject<null>();

    private _stream?: Observable<Array<UppyFile<UppyMetaData>>>;

    public get stream(): Observable<Array<UppyFile<UppyMetaData>>> {
        if (this._stream === undefined) {
            this._stream = this._changesSubject.pipe(
                debounceTime(100),
                startWith(this.getUnsyncedBlobs()),
                switchMap(async () => this.getUnsyncedBlobs()),
                shareReplay(1)
            );
        }
        return this._stream;
    }

    private async getUnsyncedBlobsMap(): Promise<{[key: string]: UppyFile<UppyMetaData>}> {
        if (window.caches === undefined) {
            return {};
        }

        const momentCache = await caches.open(UNSYNCED_CACHE_NAME);

        const images: {[key: string]: UppyFile<UppyMetaData>} = {};

        const imageDataResponses = await momentCache.matchAll('/images/data', {ignoreSearch: true});
        await Promise.all(
            imageDataResponses.map(async resp => {
                const imgJson = await resp.json();
                images[resp.statusText] = imgJson;
            })
        );

        const imageBlobResponses = await momentCache.matchAll('/images/blob', {ignoreSearch: true});
        await Promise.all(
            imageBlobResponses.map(async resp => {
                const imgBlob = await resp.blob();
                const image = images[resp.statusText];
                const fileD = {
                    lastModified: image.data instanceof File ? image.data.lastModified : undefined,
                    type: image.type
                };
                image.data = new File([imgBlob], image.name, fileD);
            })
        );

        return images;
    }

    public async getUnsyncedBlobs(): Promise<Array<UppyFile<UppyMetaData>>> {
        const images = await this.getUnsyncedBlobsMap();
        return Object.keys(images).map(k => images[k]);
    }

    private async getUnsyncedBlobsForMomentUuid(momentUuid: string) {
        const images = await this.getUnsyncedBlobsMap();
        return Object.keys(images).reduce((acc: Array<UppyFile<UppyMetaData>>, key) => {
            const image = images[key];
            if (image.meta.momentUuid === momentUuid) {
                acc.push(image);
            }
            return acc;
        }, []);
    }

    public async storeUnsyncedBlob(file: UppyFile<UppyMetaData>) {
        if (window.caches === undefined) {
            return;
        }

        const momentUuid = file.meta.momentUuid;
        const fileId = file.meta.fileId;

        const unsyncedCache = await caches.open(UNSYNCED_CACHE_NAME);

        const data = Object.assign({}, file, {
            lastModified: file.data instanceof File ? file.data.lastModified : undefined,
            momentUuid: momentUuid
        });
        const dataResponse = new Response(JSON.stringify(data), {
            statusText: fileId
        });
        const blobResponse = new Response(file.data, {statusText: fileId});

        await unsyncedCache.put('/images/data?fileId=' + fileId, dataResponse);
        await unsyncedCache.put('/images/blob?fileId=' + fileId, blobResponse);

        this._changesSubject.next(null);
    }

    public async removeUnsyncedBlob(fileId: string) {
        const unsyncedCache = await caches.open(UNSYNCED_CACHE_NAME);
        await unsyncedCache.delete('/images/data?fileId=' + fileId);
        await unsyncedCache.delete('/images/blob?fileId=' + fileId);

        this._changesSubject.next(null);
    }

    public async removeUnsyncedBlobsForMomentUuid(momentUuid: string) {
        if (window.caches === undefined) {
            return;
        }

        const images = await this.getUnsyncedBlobsForMomentUuid(momentUuid);

        for (const image of images) {
            await this.removeUnsyncedBlob(image.meta.fileId);
        }

        this._changesSubject.next(null);
    }
}
