import * as React from 'react';

import '../../styles/components/Loader.css';
import {Article} from '../scaffold/article';
import {Container} from '../scaffold/container';
import {Page} from '../scaffold/page';
import {PageTitle} from '../scaffold/page_title';
import {Section} from '../scaffold/section';

interface OwnProps {
    message: string;
}

export class Error extends React.Component<OwnProps> {
    public render() {
        return (
            <Page name="error-page">
                <Section>
                    <Article>
                        <Container>
                            <PageTitle title={this.props.message} />
                        </Container>
                    </Article>
                </Section>
            </Page>
        );
    }
}
