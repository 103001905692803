import {AccountInteractor} from '../../business/account_interactor';
import {PersistedMoment, ServerMoment} from '../../models/moment';
import {UnpersistedMomentComment} from '../../models/moment_comment';
import {ApiMomentCommentsTransformer} from '../moment_comment/api_moment_comments_transformer';
import {AuthorizationInterceptor} from '../request/interceptors/authorization_interceptor';
import {JsonRequest} from '../request/json_request';
import {RequestExecutor} from '../request/request_executor';

import {ApiMomentTransformer, ApiMomentWithAccount} from './api_moment_transformer';

export interface MomentApi {
    create(moment: PersistedMoment): Promise<ServerMoment>;
    update(moment: PersistedMoment): Promise<ServerMoment>;
    // get(tripId: number, momentId: number): Promise<ServerMoment>;
    delete(tripId: number, momentId: number): Promise<void>;
    // getByTripId(tripId: number): Promise<ServerMoment[]>;
    getMyMoments(): Promise<ServerMoment[]>;
    getPublicMoments(): Promise<ServerMoment[]>;
    likeMoment(tripId: number, momentId: number): Promise<ServerMoment>;
    deleteMomentLike(tripId: number, momentId: number): Promise<ServerMoment>;

    commentMoment(tripId: number, momentId: number, comment: UnpersistedMomentComment): Promise<ServerMoment>;
    updateMomentComment(tripId: number, momentId: number, commentId: number, content: string): Promise<ServerMoment>;
}

export class MomentApiImpl implements MomentApi {
    private _requestExecutor: RequestExecutor;

    constructor(accountInteractor: AccountInteractor) {
        this._requestExecutor = new RequestExecutor();
        this._requestExecutor.addInterceptor(new AuthorizationInterceptor(accountInteractor));
    }

    public async create(moment: PersistedMoment): Promise<ServerMoment> {
        const request = new JsonRequest<ApiMomentWithAccount>('POST', `trips/${moment.serverTripId}/moments`).setJson(
            ApiMomentTransformer.fromMoment(moment)
        );

        const response = await this._requestExecutor.execute<ApiMomentWithAccount>(request);
        return ApiMomentTransformer.toServerMoment(response.result);
    }

    public async update(moment: PersistedMoment): Promise<ServerMoment> {
        const request = new JsonRequest<ApiMomentWithAccount>(
            'POST',
            `trips/${moment.serverTripId}/moments/${moment.serverId}`
        ).setJson(ApiMomentTransformer.fromMoment(moment));

        const response = await this._requestExecutor.execute<ApiMomentWithAccount>(request);
        return ApiMomentTransformer.toServerMoment(response.result);
    }

    public async delete(tripId: number, momentId: number): Promise<void> {
        const request = new JsonRequest<void>('DELETE', `trips/${tripId}/moments/${momentId}`);
        const response = await this._requestExecutor.execute<void>(request);
        return response.result;
    }

    public async getMyMoments(): Promise<ServerMoment[]> {
        const request = new JsonRequest<ApiMomentWithAccount[]>('GET', 'me/moments');

        const response = await this._requestExecutor.execute<ApiMomentWithAccount[]>(request);
        return ApiMomentTransformer.toServerMomentArray(response.result);
    }

    public async getPublicMoments(lat?: number, long?: number, radius?: number): Promise<ServerMoment[]> {
        const request = new JsonRequest<ApiMomentWithAccount[]>('GET', 'moments', {
            lat,
            long,
            radius
        });

        const response = await this._requestExecutor.execute<ApiMomentWithAccount[]>(request);
        return ApiMomentTransformer.toServerMomentArray(response.result);
    }

    public async likeMoment(tripId: number, momentId: number): Promise<ServerMoment> {
        const request = new JsonRequest<ApiMomentWithAccount>('POST', `trips/${tripId}/moments/${momentId}/like`);

        const response = await this._requestExecutor.execute<ApiMomentWithAccount>(request);
        return ApiMomentTransformer.toServerMoment(response.result);
    }

    public async deleteMomentLike(tripId: number, momentId: number): Promise<ServerMoment> {
        const request = new JsonRequest<ApiMomentWithAccount>('DELETE', `trips/${tripId}/moments/${momentId}/like`);

        const response = await this._requestExecutor.execute<ApiMomentWithAccount>(request);
        return ApiMomentTransformer.toServerMoment(response.result);
    }

    public async commentMoment(
        tripId: number,
        momentId: number,
        comment: UnpersistedMomentComment
    ): Promise<ServerMoment> {
        const request = new JsonRequest<ApiMomentWithAccount>(
            'POST',
            `trips/${tripId}/moments/${momentId}/comment`
        ).setJson(ApiMomentCommentsTransformer.fromComment(comment));

        const response = await this._requestExecutor.execute<ApiMomentWithAccount>(request);
        return ApiMomentTransformer.toServerMoment(response.result);
    }

    public async updateMomentComment(
        tripId: number,
        momentId: number,
        commentId: number,
        content: string
    ): Promise<ServerMoment> {
        const request = new JsonRequest<ApiMomentWithAccount>(
            'PUT',
            `trips/${tripId}/moments/${momentId}/comment/${commentId}`
        ).setJson({content});

        const response = await this._requestExecutor.execute<ApiMomentWithAccount>(request);
        return ApiMomentTransformer.toServerMoment(response.result);
    }
}
