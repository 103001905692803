import * as React from 'react';

import '../../styles/scaffold/Section.css';

interface OwnProps {
    title?: string;
    className?: string;
}

export class Section extends React.PureComponent<OwnProps, {}> {
    public render() {
        return (
            <section className={this.props.className}>
                {this.props.title ? <h2>{this.props.title}</h2> : null}
                {this.props.children}
            </section>
        );
    }
}
