import * as Cookie from 'js-cookie';
import {observable} from 'mobx';
import {first} from 'rxjs/operators';

import {AccountInteractor} from '../business/account_interactor';
import {ColorsInteractor} from '../business/colors_interactor';
import {ImageSynchroniser} from '../business/image/image_synchroniser';
import {MomentSynchroniser} from '../business/moment/moment_synchroniser';
import {NavigationState, PageEnum, PageInteractor} from '../business/page_interactor';
import {TripInteractor} from '../business/trip_interactor';
import {ServerPublicAccount} from '../models/account';
import {ServerAppAuth} from '../models/app_auth';
import {MomentApi} from '../network/moment/moment_api';
import {CompositeSubscription} from '../support/composit_subscription';
import {getQueryVariableByName} from '../support/url_params';
import {Presenter} from '../support/with_presenter';

type UIMode = 'app' | 'web';

export class AppPresenter implements Presenter {
    @observable
    public isSynchronizing = false;

    @observable
    public appLoginBusy = false;

    @observable
    public currentNavigationState: NavigationState | null = null;
    @observable
    public currentUIMode: UIMode = 'web'; // Render pages for web or within Travelia app

    @observable
    public showOnboarding = false;

    @observable
    public account: ServerPublicAccount | null = null;
    @observable
    public smallQuota = false;

    private _accountInteractor: AccountInteractor;
    private _pageInteractor: PageInteractor;
    private _tripInteractor: TripInteractor;
    private _colorsInteractor: ColorsInteractor;

    private _momentSynchroniser: MomentSynchroniser;
    private _imageSynchroniser: ImageSynchroniser;
    private _momentApi: MomentApi;
    private _subscriptions = new CompositeSubscription();

    private _syncInterval: number | null = null;
    private _syncQueue: Array<null | ServerAppAuth> = [];

    constructor(
        accountInteractor: AccountInteractor,
        pageInteractor: PageInteractor,
        tripInteractor: TripInteractor,
        colorsInteractor: ColorsInteractor,
        momentSynchroniser: MomentSynchroniser,
        imageSynchroniser: ImageSynchroniser,
        momentApi: MomentApi
    ) {
        this._accountInteractor = accountInteractor;
        this._pageInteractor = pageInteractor;
        this._tripInteractor = tripInteractor;
        this._colorsInteractor = colorsInteractor;
        this._momentSynchroniser = momentSynchroniser;
        this._imageSynchroniser = imageSynchroniser;
        this._momentApi = momentApi;

        this.currentUIMode = this.isInAppMode() ? 'app' : 'web';
        if (localStorage) {
            localStorage.setItem('ui-mode', this.currentUIMode);
            this.appLoginBusy = this.currentUIMode === 'app';

            if (this.currentUIMode === 'app') {
                const didIntro = localStorage.getItem('did-intro');
                if (didIntro === null) {
                    localStorage.setItem('did-intro', 'true');
                    this.showOnboarding = true;
                }
            }
        }
    }

    public async mount() {
        this._syncInterval = window.setInterval(async () => await this.synchronise(), 500);

        //TODO add some loading state to this one
        try {
            if (this.isInAppMode()) {
                await this.loadAppTrip();
            } else {
                if (Cookie.get('app_auth') === undefined) {
                    this.onOnline(async () => this._syncQueue.push(null));
                    this._syncQueue.push(null);
                }
            }
        } catch (error) {
            //probably network errors, don't worry about those since we are offline first :)
            //todo gotta figure out what to do when its not a network error tho
            console.warn(error);
        }

        window.addEventListener('popstate', this.listener.bind(this));
        this._subscriptions.add(
            this._pageInteractor.currentPage().subscribe(async navigationState => {
                this.currentNavigationState = navigationState;
                window.scrollTo(0, 0); //Scroll back to top on new page render

                if (this.currentNavigationState.page === PageEnum.TRIPS_INDEX && this.isInAppMode()) {
                    await this.loadAppTrip();
                }
            })
        );
        this._subscriptions.add(
            this._accountInteractor.appAuth().subscribe(async appAuth => {
                this.account = appAuth !== null ? appAuth.account : null;
                if (appAuth !== null && this.appLoginBusy === false) {
                    this._syncQueue.push(appAuth);
                }
            })
        );
        this._colorsInteractor.init();

        //check if we have enough storage available
        const anyNavigator = navigator as any;
        if (anyNavigator.storage !== undefined && anyNavigator.storage.estimate !== undefined) {
            const {usage, quota}: {usage: number; quota: number} = await anyNavigator.storage.estimate();
            const deltaUsage = quota - usage;

            //if we have less then 100mb left
            if (deltaUsage < 100000000) {
                this.smallQuota = true;
            }
        }

        // Check te ui mode to prevent app from switching ui mode
        if (localStorage && localStorage.getItem('ui-mode') === 'app') {
            this.currentUIMode = 'app';
        } else {
            this.currentUIMode = 'web';
        }
    }

    public unmount() {
        window.removeEventListener('popstate', this.listener.bind(this));
        if (this._syncInterval) {
            window.clearInterval(this._syncInterval);
        }
        this._subscriptions.clear();
    }
    private onOnline(listener: () => void) {
        window.addEventListener('online', listener, false);
    }

    private _isLoadingTrip = false;
    private async loadAppTrip() {
        if (this._isLoadingTrip === true) {
            return;
        }

        this._isLoadingTrip = true;
        this.appLoginBusy = true;
        this.currentUIMode = 'app';

        // Store the ui mode to keep loading app mode
        if (localStorage) {
            localStorage.setItem('ui-mode', this.currentUIMode);
        }

        const token = this.getAuthToken();
        if (token === null) {
            this._isLoadingTrip = false;
            this.appLoginBusy = false;
            throw new Error('No token found while in app mode');
        }
        const authData = await this._accountInteractor.authorizeToken(token);

        try {
            this.onOnline(async () => this._syncQueue.push(authData));
            this._syncQueue.push(authData);
        } catch (error) {
            //somehow synchronise can throw if no internet, even tho it has an try/catch inside of it
            console.warn(error);
        }

        if (authData.trip === null) {
            this._isLoadingTrip = false;
            this.appLoginBusy = false;
            throw new Error("Account doesn't have any trips");
        }

        const interval = window.setTimeout(async () => {
            console.warn(`Trip with id ${authData!.trip!.serverId} not found after 10s, retry load page.`);
            await this.loadAppTrip();
        }, 30000);

        this._tripInteractor
            .tripByUuid(authData.trip.uuid)
            .pipe(first(trip => !!trip))
            .subscribe(trip => {
                if (trip) {
                    window.clearInterval(interval);
                    this._isLoadingTrip = false;
                    this.appLoginBusy = false;

                    if (this.currentNavigationState && this.currentNavigationState.page === PageEnum.TRIPS_INDEX) {
                        this._pageInteractor.navigate(PageEnum.TRIPS_INDEX);
                    }
                }
            });
    }

    private isInAppMode() {
        return this.getAuthToken() !== null;
    }

    private getAuthToken(): string | null {
        let token = null;
        if ('Bridge' in window) {
            token = (window as any).Bridge.getToken() || null;
        }

        if (token === null) {
            token = getQueryVariableByName('auth-token');
        }

        if (token !== null && localStorage) {
            localStorage.setItem('app_token', token);
        }

        if (token === null && localStorage) {
            token = localStorage.getItem('app_token');
        }

        return token;
    }

    private previousUserIdSync?: null | number;
    private async synchronise() {
        if (this._syncQueue.length === 0 || this.isSynchronizing === true) {
            return;
        }
        const appAuth = this._syncQueue.pop();
        if (appAuth === undefined) {
            return;
        }

        if (this.previousUserIdSync !== undefined) {
            if (appAuth === null && this.previousUserIdSync === null) {
                return;
            }
            if (appAuth !== null && appAuth.account.id === this.previousUserIdSync) {
                return;
            }
        }

        if (
            this.currentNavigationState === null ||
            this.currentNavigationState.page === PageEnum.SHARED_TRIP_SHOW ||
            this.currentNavigationState.page === PageEnum.SHARED_MOMENT_SHOW
        ) {
            return;
        }

        try {
            this.isSynchronizing = true;

            const serverMoments = appAuth
                ? await this._momentApi.getMyMoments()
                : await this._momentApi.getPublicMoments();
            if (appAuth !== null) {
                const syncedTrips = await this._tripInteractor.synchronise();
                const syncedMoments = await this._momentSynchroniser.synchronise(syncedTrips, serverMoments);
                await this._imageSynchroniser.synchronise(syncedMoments, serverMoments);
                this.previousUserIdSync = appAuth.account.id;
            } else {
                const existingTrips = await this._tripInteractor.all();
                const syncedMoments = await this._momentSynchroniser.synchronise(existingTrips, serverMoments);
                await this._imageSynchroniser.synchronise(syncedMoments, serverMoments);
                this.previousUserIdSync = null;
            }
        } finally {
            this.isSynchronizing = false;
        }
    }

    private listener() {
        this._pageInteractor.touch();
    }

    public navigateToState = (navigationState: NavigationState) => {
        this._pageInteractor.navigateToState(navigationState);
    };

    public onOnboardingFinish = () => {
        this.showOnboarding = false;
    };
}
