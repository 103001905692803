import * as React from 'react';
import {MouseEvent} from 'react';

import '../../styles/components/Dialog.css';
import {classNames} from '../../support/classnames';

interface OwnProps {
    open: boolean;
    handleOnClose?: () => void;
    className?: string;
    animateKey: any;
}

export class HeightAnimatingDialog extends React.PureComponent<OwnProps> {
    private _dialogRef = React.createRef<HTMLDivElement>();

    private handleOnClose(event: MouseEvent<HTMLElement>) {
        event.stopPropagation();
        event.preventDefault();
        if (this.props.handleOnClose) {
            this.props.handleOnClose();
        }
    }

    public getSnapshotBeforeUpdate(prevProps: OwnProps, prevState: {}) {
        const current = this._dialogRef.current;
        if (prevProps.animateKey !== this.props.animateKey && current !== null) {
            return {height: current.clientHeight};
        }
        return null;
    }

    public componentDidUpdate(prevProps: OwnProps, prevState: {}, snapshot: {height: number}) {
        const current = this._dialogRef.current;
        if (snapshot !== null && current !== null && 'animate' in current) {
            const heightAnimation = [
                {height: snapshot.height + 'px', overflowY: 'hidden', alignContent: ''},
                {height: current.clientHeight + 'px', overflowY: 'hidden', alignContent: ''}
            ];
            const hightAnimationTiming = {
                duration: 150,
                easing: 'ease-in-out'
            };
            current.animate(heightAnimation, hightAnimationTiming);
        }
    }

    public render() {
        if (!this.props.open) {
            return null;
        }

        return (
            <div className="Dialog-backdrop" onClick={event => this.handleOnClose(event)}>
                <div
                    ref={this._dialogRef}
                    className={classNames('Dialog', this.props.className)}
                    onClick={e => e.stopPropagation()}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
