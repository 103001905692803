import {PersistedTrip, ServerTrip} from '../../models/trip';

import {ApiAccountTransformer} from './../authorization/api_account_transformer';
import {ApiTrip, ApiTripWithAccount} from './trip_api';

export class ApiTripTransformer {
    public static toServerTrip(apiTrip: ApiTripWithAccount): ServerTrip {
        return {
            uuid: apiTrip.uuid,
            serverPrimaryImageId: apiTrip.primary_image_id,
            secretUuid: apiTrip.secret_uuid,
            serverId: apiTrip.id as number,
            destination: apiTrip.destination,
            departureDate: apiTrip.departure_date,
            returnDate: apiTrip.return_date,
            accountId: apiTrip.account_id,
            bookingId: apiTrip.booking_id,
            updatedAt: apiTrip.updated_at,
            account: ApiAccountTransformer.toServerPublicAccountWithNames(apiTrip.account)
        };
    }
    public static toServerTrips(apiTrips: ApiTripWithAccount[]): ServerTrip[] {
        return apiTrips.map(t => this.toServerTrip(t));
    }

    public static fromTrip(trip: PersistedTrip, reCaptcha?: string): ApiTrip {
        return {
            id: trip.serverId,
            primary_image_id: trip.serverPrimaryImageId,
            uuid: trip.uuid,
            destination: trip.destination,
            departure_date: trip.departureDate,
            return_date: trip.returnDate,
            account_id: trip.accountId,
            booking_id: trip.bookingId,
            updated_at: trip.updatedAt,
            re_captcha: reCaptcha
        };
    }
}
