import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {first} from 'rxjs/operators';

import {AccountInteractor} from './business/account_interactor';
import {ServerAppAuth} from './models/app_auth';
import {AuthenticateDialog} from './ui/screens/web/authenticated_dialog/authenticate_dialog';

export class AuthFlowHandler {
    private _accountInteractor: AccountInteractor;
    constructor(accountInteractor: AccountInteractor) {
        this._accountInteractor = accountInteractor;
    }

    public getUser = async (): Promise<ServerAppAuth | null> => {
        const existingAuth = await this._accountInteractor
            .appAuth()
            .pipe(first())
            .toPromise();

        if (existingAuth !== null) {
            return existingAuth;
        }

        return await this.getAuthFromDialog();
    };

    private async getAuthFromDialog(): Promise<ServerAppAuth | null> {
        return new Promise<ServerAppAuth | null>(resolve => {
            const mountPoint = document.getElementById('auth-dialog-root') as HTMLElement;
            ReactDOM.unmountComponentAtNode(mountPoint);

            const unmountAndResolveNull = () => {
                ReactDOM.unmountComponentAtNode(mountPoint);
                resolve(null);
            };
            const unmountAndResolve = (appAuth: ServerAppAuth) => {
                ReactDOM.unmountComponentAtNode(mountPoint);
                resolve(appAuth);
            };

            ReactDOM.render(
                <AuthenticateDialog loggedInCallback={unmountAndResolve} closeDialog={unmountAndResolveNull} />,
                mountPoint
            );
        });
    }
}
