import * as React from 'react';

import '../../../styles/components/form/Input.css';

interface OwnProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    value: string;
    placeholder?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    innerRef?: (el: HTMLInputElement | null) => void;
    type?: string;
}

export class Input extends React.Component<OwnProps, {}> {
    public render() {
        const {innerRef, ...rest} = this.props;
        return <input {...rest} type={rest.type || 'text'} className="Input form-control" ref={innerRef} />;
    }
}
