import {observer} from 'mobx-react';
import * as React from 'react';

import {Component} from '../../../../component';
import {ServerAppAuth} from '../../../../models/app_auth';
import {
    AuthenticateDialogPresenter,
    AuthenticateDialogStep
} from '../../../../presenters/authenticate_dialog_presenter';
import '../../../../styles/components/web/AuthenticateDialog.css';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {HeightAnimatingDialog} from '../../../components/height_animating_dialog';

import {LoginStep} from './components/login_step';
import {PasswordRecoveryStep} from './components/password_recover_step';
import {RegisterStep} from './components/register_step';
import {StartStep} from './components/start_step';

interface OwnProps {
    closeDialog: () => void;
    loggedInCallback: (appAuth: ServerAppAuth) => void;
}

@observer
class AuthenticateDialogComp extends React.Component<OwnProps & PresenterProps<AuthenticateDialogPresenter>, {}> {
    private renderStep(step: AuthenticateDialogStep) {
        switch (step) {
            case AuthenticateDialogStep.Start:
                return (
                    <StartStep closeDialog={this.props.closeDialog} onStepChange={this.props.presenter.onStepChange} />
                );
            case AuthenticateDialogStep.Login:
                return (
                    <LoginStep
                        closeDialog={this.props.closeDialog}
                        loggedInCallback={this.props.loggedInCallback}
                        onStepChange={this.props.presenter.onStepChange}
                    />
                );
            case AuthenticateDialogStep.Register:
                return (
                    <RegisterStep
                        closeDialog={this.props.closeDialog}
                        onStepChange={this.props.presenter.onStepChange}
                    />
                );
            case AuthenticateDialogStep.PasswordRecovery:
                return (
                    <PasswordRecoveryStep
                        closeDialog={this.props.closeDialog}
                        onStepChange={this.props.presenter.onStepChange}
                    />
                );
            default:
                return null as never;
        }
    }

    public render() {
        return (
            <HeightAnimatingDialog animateKey={this.props.presenter.step} className="AuthenticateDialog" open={true}>
                {this.renderStep(this.props.presenter.step)}
            </HeightAnimatingDialog>
        );
    }
}

export const AuthenticateDialog = withPresenter<AuthenticateDialogPresenter, OwnProps>(
    (props: OwnProps, component: Component) => new AuthenticateDialogPresenter(),
    AuthenticateDialogComp
);
