import {ServerImage} from '../models/image';
import {TripApi} from '../network/trips/trip_api';
import {arrayToMapObject} from '../support/array_to_generic_map';

import {ImageSynchroniser} from './image/image_synchroniser';
import {MomentSynchroniser} from './moment/moment_synchroniser';

export class SharedTripInteractor {
    private _tripApi: TripApi;
    private _momentSynchroniser: MomentSynchroniser;
    private _imageSynchroniser: ImageSynchroniser;

    constructor(tripApi: TripApi, momentSynchroniser: MomentSynchroniser, imageSynchroniser: ImageSynchroniser) {
        this._tripApi = tripApi;
        this._momentSynchroniser = momentSynchroniser;
        this._imageSynchroniser = imageSynchroniser;
    }

    public async tripBySecretUuid(secretUuid: string) {
        const sharedTripdata = await this._tripApi.getSharedTrip(secretUuid);

        const persistedMoments = await this._momentSynchroniser.updateOrStoreServerMoments(
            sharedTripdata.moments,
            null,
            {
                [sharedTripdata.trip.serverId]: sharedTripdata.trip
            }
        );
        const persistedMomentsMap = arrayToMapObject(persistedMoments, m => m.serverId);

        const images = sharedTripdata.moments.reduce((acc: ServerImage[], moment) => [...acc, ...moment.images], []);
        const persistedImages = await this._imageSynchroniser.updateOrStoreServerImages(
            images,
            null,
            persistedMomentsMap
        );

        return {
            trip: sharedTripdata.trip,
            moments: persistedMoments,
            images: persistedImages
        };
    }
}
