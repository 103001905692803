import {trans} from '../i18n/trans';

export enum ServerVisibility {
    Public = 1,
    Followers = 2,
    Private = 3
}

export enum Visibility {
    Public = 'Public',
    Followers = 'Followers only',
    Private = 'Private'
}

export const VisibilityOptions = [
    {
        value: Visibility.Public,
        label: trans('visibility.public')
    },
    {
        value: Visibility.Followers,
        label: trans('visibility.followers')
    },
    {
        value: Visibility.Private,
        label: trans('visibility.private')
    }
];

export function visibilityFromServer(visibility: ServerVisibility): Visibility {
    switch (visibility) {
        case ServerVisibility.Public:
            return Visibility.Public;
        case ServerVisibility.Followers:
            return Visibility.Followers;
        case ServerVisibility.Private:
            return Visibility.Private;
        default:
            return null as never;
    }
}

export function visibilityToServer(visibility: Visibility): ServerVisibility {
    switch (visibility) {
        case Visibility.Public:
            return ServerVisibility.Public;
        case Visibility.Followers:
            return ServerVisibility.Followers;
        case Visibility.Private:
            return ServerVisibility.Private;
        default:
            return null as never;
    }
}
