import * as React from 'react';

import '../../styles/components/Logo.css';

interface OwnProps {}

export class Logo extends React.PureComponent {
    public render() {
        return <div className="Logo" />;
    }
}
