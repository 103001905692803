import {NetworkComponent} from '../network/network_components';
import {PersistenceComponent} from '../repositories/PersistenceComponent';

import {AccountInteractor} from './account_interactor';
import {ColorsInteractor} from './colors_interactor';
import {ImageSynchroniser} from './image/image_synchroniser';
import {ImageCacheInteractor} from './image_cache_interactor';
import {ImageInteractor} from './image_interactor';
import {MomentSynchroniser} from './moment/moment_synchroniser';
import {MomentInteractor} from './moment_interactor';
import {PageInteractor} from './page_interactor';
import {SharedTripInteractor} from './shared_trip_interactor';
import {TripSynchroniser} from './trip/trip_synchroniser';
import {TripInteractor} from './trip_interactor';

export class BusinessComponent {
    private _accountInteractor: AccountInteractor;
    private _persistenceComponent: PersistenceComponent;
    private _networkComponent: NetworkComponent;

    private _momentInteractor?: MomentInteractor;
    private _tripInteractor?: TripInteractor;
    private _pageInteractor?: PageInteractor;
    private _imageInteractor?: ImageInteractor;
    private _colorsInteractor?: ColorsInteractor;
    private _imageCacheInteractor?: ImageCacheInteractor;
    private _tripSynchroniser?: TripSynchroniser;
    private _momentSynchroniser?: MomentSynchroniser;
    private _imageSynchroniser?: ImageSynchroniser;
    private _sharedTripInteractor?: SharedTripInteractor;

    constructor(
        accountInteractor: AccountInteractor,
        persistenceComponent: PersistenceComponent,
        networkComponent: NetworkComponent
    ) {
        this._accountInteractor = accountInteractor;
        this._persistenceComponent = persistenceComponent;
        this._networkComponent = networkComponent;
    }

    get momentInteractor(): MomentInteractor {
        if (this._momentInteractor === undefined) {
            this._momentInteractor = new MomentInteractor(
                this._persistenceComponent.momentRepository,
                this._networkComponent.momentApi
            );
        }
        return this._momentInteractor;
    }

    get tripInteractor(): TripInteractor {
        if (this._tripInteractor === undefined) {
            this._tripInteractor = new TripInteractor(
                this._persistenceComponent.tripRepository,
                this._networkComponent.tripApi,
                this.tripSynchroniser
            );
        }
        return this._tripInteractor;
    }

    get sharedTripInteractor(): SharedTripInteractor {
        if (this._sharedTripInteractor === undefined) {
            this._sharedTripInteractor = new SharedTripInteractor(
                this._networkComponent.tripApi,
                this.momentSynchroniser,
                this.imageSynchroniser
            );
        }
        return this._sharedTripInteractor;
    }

    get pageInteractor(): PageInteractor {
        if (this._pageInteractor === undefined) {
            this._pageInteractor = new PageInteractor();
        }
        return this._pageInteractor;
    }

    get imageInteractor(): ImageInteractor {
        if (this._imageInteractor === undefined) {
            this._imageInteractor = new ImageInteractor(
                this._persistenceComponent.imageRepository,
                this._networkComponent.imageApi
            );
        }
        return this._imageInteractor;
    }

    get momentSynchroniser(): MomentSynchroniser {
        if (this._momentSynchroniser === undefined) {
            this._momentSynchroniser = new MomentSynchroniser(
                this._persistenceComponent.momentRepository,
                this.momentInteractor
            );
        }
        return this._momentSynchroniser;
    }

    get tripSynchroniser(): TripSynchroniser {
        if (this._tripSynchroniser === undefined) {
            this._tripSynchroniser = new TripSynchroniser(
                this._persistenceComponent.tripRepository,
                this._networkComponent.tripApi
            );
        }
        return this._tripSynchroniser;
    }

    get imageCacheInteractor(): ImageCacheInteractor {
        if (this._imageCacheInteractor === undefined) {
            this._imageCacheInteractor = new ImageCacheInteractor();
        }
        return this._imageCacheInteractor;
    }

    get imageSynchroniser(): ImageSynchroniser {
        if (this._imageSynchroniser === undefined) {
            this._imageSynchroniser = new ImageSynchroniser(
                this._accountInteractor,
                this._persistenceComponent.imageRepository,
                this.imageInteractor,
                this.imageCacheInteractor
            );
        }
        return this._imageSynchroniser;
    }

    get colorsInteractor(): ColorsInteractor {
        if (this._colorsInteractor === undefined) {
            this._colorsInteractor = new ColorsInteractor();
        }
        return this._colorsInteractor;
    }
}
