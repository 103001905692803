import {AccountInteractor} from '../../business/account_interactor';
import {LocalImage, ServerImage} from '../../models/image';
import {MomentImageOrder} from '../../models/moment_image_order';
import {AuthorizationInterceptor} from '../request/interceptors/authorization_interceptor';
import {JsonRequest} from '../request/json_request';
import {Request} from '../request/request';
import {RequestExecutor} from '../request/request_executor';

import {ApiImage, ApiImageTransformer} from './api_image_transformer';

export interface S3SignRequest {
    name: string;
    type: string;
}
export interface S3SignObject {
    method: string;
    url: string;
    fields: string[];
}

export interface S3ImageCreate {
    uuid: string;
    order: number;
    original_file_name: string;
    original_extension: string;
    original_mime: string;
    file_name: string;
    public_url: string;

    lon: number;
    lat: number;
    title: string;
    description: string;
    date: Date;
}

export interface ImageApi {
    createImages(s3Images: S3ImageCreate[], tripId: number, momentId: number): Promise<ServerImage[]>;
    updateImage(image: LocalImage, tripId: number, momentId: number): Promise<LocalImage>;
    delete(tripId: number, momentId: number, imageId: number): Promise<void>;
    requestSignedUrl(serverTripId: number, file: S3SignRequest): Promise<S3SignObject>;
    updateMomentImagesOrder(tripId: number, momentId: number, imagesOrder: MomentImageOrder[]): Promise<ServerImage[]>;
}

export class ImageApiImpl implements ImageApi {
    private _requestExecutor: RequestExecutor;

    constructor(accountInteractor: AccountInteractor) {
        this._requestExecutor = new RequestExecutor();
        this._requestExecutor.addInterceptor(new AuthorizationInterceptor(accountInteractor));
    }

    public async createImages(images: S3ImageCreate[], tripId: number, momentId: number): Promise<ServerImage[]> {
        const request = new Request<ApiImage[]>('POST', `trips/${tripId}/moments/${momentId}/s3images`)
            .addHeader('Accept', 'application/json')
            .setJson<S3ImageCreate[]>(images);

        const response = await this._requestExecutor.execute<ApiImage[]>(request);
        return ApiImageTransformer.toImages(response.result);
    }

    public async updateImage(image: LocalImage, tripId: number, momentId: number): Promise<LocalImage> {
        const request = new JsonRequest<ApiImage>(
            'POST',
            `trips/${tripId}/moments/${momentId}/images/${image.serverId}`
        ).setJson(ApiImageTransformer.fromImage(image));

        const response = await this._requestExecutor.execute(request);
        const serverImage = ApiImageTransformer.toImage(response.result);
        return {
            ...image,
            ...serverImage
        };
    }

    public async delete(tripId: number, momentId: number, imageId: number): Promise<void> {
        const request = new JsonRequest<null>('DELETE', `trips/${tripId}/moments/${momentId}/images/${imageId}`);
        await this._requestExecutor.execute<null>(request);
        return;
    }

    public async requestSignedUrl(serverTripId: number, file: S3SignRequest): Promise<S3SignObject> {
        const request = new JsonRequest<S3SignObject>('POST', `${serverTripId}/s3-upload-sign`).setJson({
            filename: file.name,
            contentType: file.type
        });

        const response = await this._requestExecutor.execute(request);

        return {
            method: response.result.method,
            url: response.result.url,
            fields: response.result.fields
        };
    }

    public async updateMomentImagesOrder(
        tripId: number,
        momentId: number,
        imagesOrder: MomentImageOrder[]
    ): Promise<ServerImage[]> {
        const request = new JsonRequest<ApiImage[]>('PUT', `/trips/${tripId}/moments/${momentId}/images-order`).setJson(
            imagesOrder
        );

        const response = await this._requestExecutor.execute(request);
        return ApiImageTransformer.toImages(response.result);
    }
}
