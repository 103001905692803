import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {tryGetElementById} from '../support/find_element';
import {createPath} from '../support/path';

export enum PageEnum {
    MOMENTS_INDEX = 'moments-index',
    MOMENT_SHOW = 'moment-show',
    SHARED_MOMENT_SHOW = 'shared-moment-show',
    MOMENT_CREATE = 'moment-create',
    MOMENT_EDIT = 'moment-edit',
    MOMENT_IMAGE_SELECT = 'moment-image-select',
    TRIP_IMAGE_SELECT = 'trip-image-select',
    IMAGE_EDIT = 'image-edit',
    MAPS_SHOW = 'maps-show',
    TRIP_CREATE = 'trip-create',
    TRIP_EDIT = 'trip-edit',
    TRIP_SHOW = 'trip-show',
    SHARED_TRIP_SHOW = 'shared-trip-show',
    TRIPS_INDEX = 'trips-index',
    LOGIN_SHOW = 'login-show',
    REGISTER_SHOW = 'register-show',
    ACTIVATE_ACCOUNT = 'activate-account',
    MY_ACCOUNT = 'my-account',
    ACCEPT_FOLLOW_REQUEST = 'accept-follow-request',
    RECOVER_PASSWORD_SHOW = 'recover-password',
    RESET_PASSWORD_SHOW = 'reset-password',
    PHOTOALBUM_EXPLANATION = 'photoalbum-explanation',
    PHOTOALBUM_PREVIEW = 'photoalbum-preview'
}

export interface NavigationState {
    page: PageEnum;
    parameters: string[];
    queryParameters: {[name: string]: string};
}

const valueInEnum = (value: string) => {
    return Object.keys(PageEnum)
        .map(key => PageEnum[key])
        .some(page => page === value);
};

export class PageInteractor {
    private _historySubject = new BehaviorSubject<NavigationState[]>([this.getInitialPage()]);
    constructor() {
        window.addEventListener('popstate', async () => {
            await this.scrollToHash(2500);
        });
        //normally we only try to scroll for 2.5s but on first page load try 30s since the app needs to load
        this.scrollToHash(30000).catch(console.warn);
    }
    private getInitialPage(): NavigationState {
        return this.getNavigationStateForPathAndSearch(window.location.pathname, window.location.search);
    }

    public async scrollToHash(timeout: number) {
        const targetElement = await tryGetElementById(window.location.hash.replace('#', ''), timeout);
        window.requestAnimationFrame(() => {
            if (window.scrollY < 500) {
                if (targetElement) {
                    targetElement.scrollIntoView({block: 'center'});
                }
            }
        });
    }

    public currentPage(): Observable<NavigationState> {
        return this._historySubject.asObservable().pipe(map(states => states[states.length - 1]));
    }

    public previousPage(): NavigationState | null {
        const states = this._historySubject.getValue();
        if (states.length > 2) {
            return states[states.length - 2];
        }
        return null;
    }

    public touch() {
        const historyValue: NavigationState[] = this._historySubject.getValue();
        const previousPage: NavigationState | undefined = historyValue[historyValue.length - 2];
        const navigationStateForPathname = this.getNavigationStateForPathAndSearch(
            window.location.pathname,
            window.location.search
        );
        if (previousPage !== undefined && this.navigationStatesAreEqual(previousPage, navigationStateForPathname)) {
            this.popLastPageFromHistory();
        } else if (historyValue.length === 1) {
            this.replaceHistory(navigationStateForPathname);
        }
    }

    public back = () => {
        this.popLastPageFromHistory();
        window.history.back();
    };

    public navigateToState(navigationState: NavigationState) {
        this.navigate(navigationState.page, navigationState.parameters, navigationState.queryParameters);
    }

    public navigate(page: PageEnum, parameters: string[] = [], queryParameters: {[name: string]: string} = {}) {
        const history = this._historySubject.getValue();

        window.history.pushState({}, page, createPath(page, parameters, queryParameters));

        this._historySubject.next([
            ...history,
            {
                page,
                parameters,
                queryParameters
            }
        ]);
    }
    private popLastPageFromHistory() {
        const historyValue = this._historySubject.getValue();
        historyValue.pop();
        if (historyValue.length === 0) {
            historyValue.push(PageInteractor.getTripsPage());
        }
        this._historySubject.next([...historyValue]);
    }

    private getNavigationStateForPathAndSearch(pathname: string, search: string): NavigationState {
        const parts: string[] = pathname.split('/').filter(part => part !== '');
        let navigationState: NavigationState = PageInteractor.getTripsPage();

        if (parts.length > 0 && valueInEnum(parts[0])) {
            const page = parts.shift();
            const queryParameters = {};
            search
                .replace('?', '')
                .split('&')
                .forEach(kv => {
                    const keyValue = kv.split('=');
                    if (keyValue.length === 2) {
                        queryParameters[decodeURIComponent(keyValue[0])] = decodeURIComponent(keyValue[1]);
                    }
                });

            navigationState = {
                page: page as PageEnum,
                parameters: parts,
                queryParameters
            };
        }

        return navigationState;
    }

    private navigationStatesAreEqual(a: NavigationState, b: NavigationState) {
        return (
            a.page === b.page &&
            JSON.stringify(a.parameters) === JSON.stringify(b.parameters) &&
            JSON.stringify(a.queryParameters) === JSON.stringify(b.queryParameters)
        );
    }

    private replaceHistory(navigationStateForPathname: NavigationState) {
        this._historySubject.next([navigationStateForPathname]);
    }

    private static getTripsPage(): NavigationState {
        return {page: PageEnum.TRIPS_INDEX, parameters: [], queryParameters: {}};
    }
}
