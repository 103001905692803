import {observer} from 'mobx-react';
import * as React from 'react';

import {trans} from '../i18n/trans';
import {LocalImage} from '../models/image';
import {PersistedMoment} from '../models/moment';
import {PersistedTrip} from '../models/trip';
import {PresenterProps, withPresenter} from '../support/with_presenter';

import {Error} from './components/error';
import {Loader} from './components/loader';
import {Page} from './scaffold/page';
import {TripWithMomentsProviderPresenter} from './trip_with_moments_provider_presenter';

interface OwnProps {
    tripUuid: string;
    requiresOwnership?: boolean;
    silent?: boolean;
    render: (
        trip: PersistedTrip,
        momentsMap: Map<string, PersistedMoment[]>,
        imagesMap: Map<string, LocalImage[]>,
        hasOwnership: boolean
    ) => React.ReactNode;
}

@observer
class TripWithMomentsProvider extends React.Component<OwnProps & PresenterProps<TripWithMomentsProviderPresenter>> {
    public render() {
        if (this.props.presenter.loading) {
            return (
                <Page>
                    <Loader />
                </Page>
            );
        }
        if (this.props.presenter.trip === null) {
            return (
                <Page>
                    <Error message={trans('errors.trip_not_found')} />
                </Page>
            );
        }
        if (this.props.requiresOwnership === true && !this.props.presenter.canEditTrip) {
            if (this.props.silent === true) {
                return null;
            }
            return (
                <Page>
                    <Error message={trans('errors.unauthorized')} />
                </Page>
            );
        }
        return this.props.render(
            this.props.presenter.trip,
            this.props.presenter.momentsMap,
            this.props.presenter.imagesMap,
            this.props.presenter.canEditTrip
        );
    }
}

export default withPresenter<TripWithMomentsProviderPresenter, OwnProps>(
    (props, component) =>
        new TripWithMomentsProviderPresenter(
            props.tripUuid,
            component.business.tripInteractor,
            component.business.momentInteractor,
            component.accountInteractor,
            component.business.imageInteractor,
            component.business.pageInteractor
        ),
    TripWithMomentsProvider
);
