import {Subscription} from 'rxjs';

export class CompositeSubscription {
    private _list: Subscription[] = [];

    public add(...subscriptions: Subscription[]) {
        subscriptions.forEach(s => this._list.push(s));
    }

    public clear() {
        this._list.forEach(subscription => {
            subscription.unsubscribe();
        });

        this._list = [];
    }
}
