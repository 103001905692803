import {PageEnum} from '../business/page_interactor';

export function createPath(page: PageEnum, parameters: string[] = [], queryParameters: {[name: string]: string} = {}) {
    let path = '/' + page;
    if (parameters.length > 0) {
        path += '/' + parameters.join('/');
    }
    const keys = Object.keys(queryParameters);
    if (keys.length > 0) {
        const queries = keys.reduce(
            (acc, key) => {
                if (key && queryParameters[key]) {
                    return [...acc, `${encodeURIComponent(key)}=${encodeURIComponent(queryParameters[key])}`];
                }
                return acc;
            },
            [] as string[]
        );

        if (queries.length > 0) {
            path = path + '?' + queries.join('&');
        }
    }

    return path;
}
