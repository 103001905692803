import {ServerAppAuth} from '../../models/app_auth';
import {ApiTripTransformer} from '../trips/api_trip_transformer';

import {ApiTripWithAccount} from './../trips/trip_api';
import {ApiAccountTransformer, ApiPrivateAccount} from './api_account_transformer';

export interface ApiAppAuth {
    token: string;
    account: ApiPrivateAccount;
    trip?: ApiTripWithAccount;
}

export class ApiAuthTransformer {
    public static toServerAppAuth(response: ApiAppAuth): ServerAppAuth {
        return {
            token: response.token,
            account: ApiAccountTransformer.toServerPrivateAccount(response.account),
            trip: response.trip ? ApiTripTransformer.toServerTrip(response.trip) : null
        };
    }
}
