export const mapsApiKey: string = 'AIzaSyBCieenGpoEOuInju05XHlxUW4zpAIsztI';

export const baseURL: string =
    process.env.REACT_APP_STAGE === 'release'
        ? 'https://api-dev.mytravelmoments.nl/dev-new/'
        : 'https://api.mytravelmoments.com/dev/';

export const imageBaseUrl: string =
    process.env.REACT_APP_STAGE === 'release'
        ? 'https://images-dev.mytravelmoments.nl/fit-in/1500x1500/'
        : 'https://images.mytravelmoments.com/fit-in/1500x1500/';

export const websiteUrl: string =
    process.env.REACT_APP_STAGE === 'release' ? 'https://www.mytravelmoments.nl' : 'https://www.mytravelmoments.com';
