import {observer} from 'mobx-react';
import * as React from 'react';

import {PageEnum} from '../../business/page_interactor';
import {Component} from '../../component';
import {NavigationPresenter} from '../../presenters/navigation_presenter';
import {PresenterProps, withPresenter} from '../../support/with_presenter';

interface OwnProps {
    page: PageEnum;
    action: string;
    parameters?: string[];
    queryParameters?: {[name: string]: string};
    className?: string;
}

@observer
class LinkComp extends React.Component<OwnProps & PresenterProps<NavigationPresenter>, {}> {
    public render() {
        const {page, action, parameters, queryParameters, className, presenter, children} = this.props;
        return (
            <a
                ga-on="click"
                ga-event-category={page}
                ga-event-action={action}
                rel={action}
                aria-label={action}
                className={className}
                href={presenter.path(page, parameters, queryParameters)}
                onClick={e => presenter.navigate(e, page, parameters, queryParameters)}>
                {children}
            </a>
        );
    }
}

export const Link = withPresenter<NavigationPresenter, OwnProps>(
    (props: OwnProps, component: Component) => new NavigationPresenter(component.business.pageInteractor),
    LinkComp
);
