import {computed, observable} from 'mobx';

import {AccountInteractor} from '../business/account_interactor';
import {PageInteractor} from '../business/page_interactor';
import {trans} from '../i18n/trans';
import {Presenter} from '../support/with_presenter';

export class RecoverPasswordPresenter implements Presenter {
    @observable
    public isDirty = false;
    @observable
    public showLoader = false;
    @observable
    public dialogFailedMessage: string | null = null;
    @observable
    public dialogSucceededMessage: string | null = null;
    @observable
    public email: string = '';

    @computed
    get validates(): boolean {
        return this.isDirty && this.email.length > 0;
    }

    private _accountInteractor: AccountInteractor;
    private _pageInteractor: PageInteractor;

    constructor(accountInteractor: AccountInteractor, pageInteractor: PageInteractor) {
        this._accountInteractor = accountInteractor;
        this._pageInteractor = pageInteractor;
    }

    public mount() {
        /* Noop */
    }

    public unmount() {
        /* Noop */
    }

    public emailChanged = (email: string) => {
        this.isDirty = true;
        this.email = email;
    };

    public clearErrorMessage = () => {
        this.dialogFailedMessage = null;
        this.dialogSucceededMessage = null;
    };

    public onCancelClick = () => {
        this._pageInteractor.back();
    };

    public submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (this.validates) {
            this.showLoader = true;
            try {
                this.clearErrorMessage();
                await this._accountInteractor.recoverPassword(this.email);
                this.dialogSucceededMessage = trans('recover_password.dialog_success');
            } catch (error) {
                this.dialogFailedMessage = error.result.message;
            } finally {
                this.showLoader = false;
            }
        }
    };
}
