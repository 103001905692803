import {AccountInteractor} from '../../business/account_interactor';
import {ServerPhotoalbumPreview} from '../../models/photoalbum_preview';
import {AuthorizationInterceptor} from '../request/interceptors/authorization_interceptor';
import {JsonRequest} from '../request/json_request';
import {RequestExecutor} from '../request/request_executor';

import {ApiPhotoalbumTransformer} from './api_photoalbum_transformer';

export interface PhotoalbumApi {
    getPreview(tripServerId: number): Promise<ServerPhotoalbumPreview>;
    getCheckoutUrl(tripServerId: number, hash: string, coupon: string): Promise<string>;
}

export interface ApiPhotoalbumPreview {
    num_of_pages: number;
    sprites: string[];
    hash: string;
    price: string;
}

export class PhotoalbumApiImpl implements PhotoalbumApi {
    private _requestExecutor: RequestExecutor;

    constructor(accountInteractor: AccountInteractor) {
        this._requestExecutor = new RequestExecutor();
        this._requestExecutor.addInterceptor(new AuthorizationInterceptor(accountInteractor));
    }

    public async getPreview(tripServerId: number): Promise<ServerPhotoalbumPreview> {
        const request = new JsonRequest<ApiPhotoalbumPreview>('GET', `trip/${tripServerId}/album/preview`);
        const response = await this._requestExecutor.execute<ApiPhotoalbumPreview>(request);

        return ApiPhotoalbumTransformer.toServerPhotoalbumPreview(response.result);
    }

    public async getCheckoutUrl(tripServerId: number, hash: string, coupon: string) {
        const url = `trip/${tripServerId}/album/order/${hash}` + (coupon ? `?coupon=${coupon}` : '');
        const request = new JsonRequest<string>('GET', url);
        const response = await this._requestExecutor.execute<string>(request);

        return response.result;
    }
}
