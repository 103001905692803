import {baseURL} from '../../app_config';

export interface RequestResult<T> {
    statusCode: number;
    result: T;
}

export class Request<T> {
    private _headers = new Headers();
    private _method: string;
    public _url: string;
    private _body: any;

    constructor(method: string, url: string, queryParams?: {[key: string]: any}) {
        this._method = method;
        this._url = url;
        if (queryParams) {
            const keys = Object.keys(queryParams);

            const queries = keys.reduce(
                (acc, key) => {
                    if (key && queryParams[key]) {
                        return [...acc, `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`];
                    }
                    return acc;
                },
                [] as string[]
            );

            if (queries.length > 0) {
                this._url = url + '?' + queries.join('&');
            }
        }
    }

    public hasHeader(key: string): boolean {
        return this._headers.has(key);
    }

    public addHeader(key: string, value: string) {
        this._headers.append(key, value);
        return this;
    }

    public setBody(body: string | FormData) {
        this._body = body;
        return this;
    }

    public setJson<U extends {}>(body: U) {
        this._body = JSON.stringify(body);
        return this;
    }

    public async execute(): Promise<RequestResult<T>> {
        return new Promise<RequestResult<T>>(async (resolve, reject) => {
            const url = baseURL + this._url;
            try {
                const result = await window.fetch(url, {
                    method: this._method,
                    headers: this._headers,
                    body: this._body
                });

                const jsonBody: RequestResult<T> = await result.json();
                if (result.ok) {
                    resolve(jsonBody);
                } else {
                    reject(jsonBody);
                }
            } catch (e) {
                reject(e);
            }
        });
    }
}
