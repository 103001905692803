import * as React from 'react';

import '../../styles/scaffold/Page.css';
import {classNames} from '../../support/classnames';

interface OwnProps {
    name?: string;
}

export class Page extends React.PureComponent<OwnProps, {}> {
    public render() {
        return <div className={classNames('Page', this.props.name)}>{this.props.children}</div>;
    }
}
