import {ServerMomentLike} from '../../models/moment_like';
import {ApiAccount, ApiAccountTransformer} from '../authorization/api_account_transformer';

export interface ApiMomentLike {
    id?: number;
    account_id: number;
    account: ApiAccount;
    moment_id: number;
    created_at: string;
    updated_at: string;
}

export class ApiMomentLikesTransformer {
    public static toServerMomentLike(apiMomentLike: ApiMomentLike): ServerMomentLike {
        return {
            serverId: apiMomentLike.id as number,
            serverAccountId: apiMomentLike.account_id,
            serverMomentId: apiMomentLike.moment_id,
            createdAt: new Date(apiMomentLike.created_at),
            updatedAt: new Date(apiMomentLike.updated_at),
            account: ApiAccountTransformer.toServerPublicAccountWithNames(apiMomentLike.account)
        };
    }

    public static toServerMomentLikes(apiMomentLikes: ApiMomentLike[]): ServerMomentLike[] {
        return apiMomentLikes.map(apiMomentLike => {
            return ApiMomentLikesTransformer.toServerMomentLike(apiMomentLike);
        });
    }
}
