import * as React from 'react';

import '../../../styles/components/web/PanelHeading.css';
import {classNames} from '../../../support/classnames';

interface OwnProps {
    title?: string;
    align?: string;
    buttonLeft?: boolean;
    buttonRight?: boolean;
}

export class PanelHeading extends React.PureComponent<OwnProps, {}> {
    public render() {
        return (
            <div
                className={classNames('PanelHeading', {
                    'has-button-left': this.props.buttonLeft,
                    'has-button-right': this.props.buttonRight
                })}>
                {this.props.title ? <h2 className={this.props.align}>{this.props.title}</h2> : null}
                {this.props.buttonLeft ? this.props.buttonLeft : null}
                {this.props.buttonRight ? this.props.buttonRight : null}

                {this.props.children}
            </div>
        );
    }
}
