import {ServerPrivateAccount, ServerPublicAccount, ServerPublicAccountWithNames} from './../../models/account';

export interface ApiAccount {
    id: number;
    gravatar_url: string;
    initials: string;
    first_name?: string;
    last_name?: string;
}

export interface ApiPrivateAccount extends ApiAccount {
    email: string;
}

export interface ApiAccountWithPasswords {
    email: string;
    first_name: string;
    last_name: string;
    password: string;
    password_repeat: string;
    re_captcha?: string;
}

export class ApiAccountTransformer {
    public static toServerPublicAccount(account: ApiAccount): ServerPublicAccount {
        return {
            id: account.id,
            gravatarUrl: account.gravatar_url,
            initials: account.initials
        };
    }

    public static toServerPublicAccounts(accounts: ApiAccount[]): ServerPublicAccount[] {
        return accounts.map(a => this.toServerPublicAccount(a));
    }

    public static toServerPublicAccountWithNames(account: ApiAccount): ServerPublicAccountWithNames {
        return {
            ...this.toServerPublicAccount(account),
            firstName: account.first_name,
            lastName: account.last_name
        };
    }

    public static toServerPublicAccountsWithNames(accounts: ApiAccount[]): ServerPublicAccountWithNames[] {
        return accounts.map(a => this.toServerPublicAccountWithNames(a));
    }

    public static toServerPrivateAccount(account: ApiPrivateAccount): ServerPrivateAccount {
        return {
            ...this.toServerPublicAccountWithNames(account),
            email: account.email
        };
    }
    public static toApiAccountWithPasswords(
        email: string,
        firstName: string,
        lastName: string,
        password: string,
        passwordRepeat: string,
        reCaptcha: string
    ): ApiAccountWithPasswords {
        return {
            email: email,
            first_name: firstName,
            last_name: lastName,
            password: password,
            password_repeat: passwordRepeat,
            re_captcha: reCaptcha
        };
    }
}
