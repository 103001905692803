import {getQueryVariableByName} from '../support/url_params';

export class ColorsInteractor {
    public init() {
        this.navigationBarColor();
        this.navigationBarForegroundColor();
        this.primaryColor();
    }

    public navigationBarColor(): string | null {
        //Color for Android
        if ('Colors' in window) {
            const colorFromColors: string | null = (window as any).Colors.getNavigationBarColor();
            if (colorFromColors) {
                return colorFromColors;
            }
        }

        // Color from url
        const persistedColor = localStorage.getItem('navigation-bar-color');
        const colorFromUrl = getQueryVariableByName('navigation-bar-color');
        let newColor: string | null = null;
        if (colorFromUrl) {
            newColor = colorFromUrl;
        }

        if (newColor && newColor !== persistedColor) {
            localStorage.setItem('navigation-bar-color', newColor);
        }

        return localStorage.getItem('navigation-bar-color');
    }

    public navigationBarForegroundColor(): string | null {
        //Color for Android
        if ('Colors' in window) {
            const colorFromColors: string | null = (window as any).Colors.getNavigationBarForegroundColor();
            if (colorFromColors) {
                return colorFromColors;
            }
        }

        // Color from url
        const persistedColor = localStorage.getItem('navigation-bar-foreground-color');
        const colorFromUrl = getQueryVariableByName('navigation-bar-foreground-color');
        let newColor: string | null = null;
        if (colorFromUrl) {
            newColor = colorFromUrl;
        }

        if (newColor && newColor !== persistedColor) {
            localStorage.setItem('navigation-bar-foreground-color', newColor);
        }

        return localStorage.getItem('navigation-bar-foreground-color');
    }

    public primaryColor(): string | null {
        //Color for Android
        if ('Colors' in window) {
            const colorFromColors: string | null = (window as any).Colors.getPrimaryColor();
            if (colorFromColors) {
                return colorFromColors;
            }
        }

        // Color from url
        const persistedColor = localStorage.getItem('primary-color');
        const colorFromUrl = getQueryVariableByName('primary-color');
        let newColor: string | null = null;
        if (colorFromUrl) {
            newColor = colorFromUrl;
        }

        if (newColor && newColor !== persistedColor) {
            localStorage.setItem('primary-color', newColor);
        }

        return localStorage.getItem('primary-color');
    }
}
