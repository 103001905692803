import {observer} from 'mobx-react';
import * as React from 'react';

import {ColorsInteractor} from '../../business/colors_interactor';
import {PageEnum} from '../../business/page_interactor';
import {Component} from '../../component';
import {NavigationPresenter} from '../../presenters/navigation_presenter';
import '../../styles/components/Button.css';
import {classNames} from '../../support/classnames';
import {PresenterProps, withPresenter} from '../../support/with_presenter';

type Appearance = 'primary' | 'secondary' | 'tertiary' | 'danger' | 'icon' | 'fab' | 'link' | 'icon-squared';

interface OwnProps {
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    floatDirection?: 'left' | 'right' | 'centered';
    title?: string;
    action: string;
    width?: 'full' | 'half';
    appearance?: Appearance | Appearance[];
    type?: 'button' | 'submit';
    icon?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    className?: string;
    page?: PageEnum;
    parameters?: string[];
    queryParameters?: {[name: string]: string};
}

@observer
class ButtonComp extends React.Component<OwnProps & PresenterProps<NavigationPresenter>, {}> {
    private handleOnButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const {presenter, page, parameters, queryParameters, onClick, type} = this.props;
        if (page !== undefined) {
            presenter.navigate(e, page, parameters, queryParameters);
        } else if (onClick !== undefined) {
            onClick(e);
        } else if (type !== 'submit') {
            console.warn('No valid action for button', this.props);
        }
    };

    public render() {
        let style = {};
        const primaryColor = new ColorsInteractor().primaryColor();
        if (primaryColor && this.props.appearance === 'primary') {
            style = {
                background: primaryColor
            };
        }

        return (
            <button
                ga-on="click"
                ga-event-category={this.props.presenter.currentPage}
                ga-event-action={this.props.action}
                className={classNames(
                    'Button',
                    this.props.floatDirection,
                    this.props.width,
                    this.props.icon,
                    this.props.appearance || 'primary',
                    this.props.className
                )}
                style={style}
                onClick={this.handleOnButtonClick}
                disabled={this.props.disabled || false}
                autoFocus={this.props.autoFocus || false}
                type={this.props.type || 'button'}
                title={this.props.title}>
                {this.props.children}
            </button>
        );
    }
}

export const Button = withPresenter<NavigationPresenter, OwnProps>(
    (props: OwnProps, component: Component) => new NavigationPresenter(component.business.pageInteractor),
    ButtonComp
);
