import {merge, Observable} from 'rxjs';
import {filter, sampleTime, takeUntil} from 'rxjs/operators';

/**
 * If the passed observable emits null wait x sec before emitting it,
 * if the value !== null emit immediately and following null values
 */
export function delayedNull<T>(observable: Observable<T | null>, timeout: number = 5000): Observable<T | null> {
    const $value = observable.pipe(filter(m => m !== null));
    const $null = observable.pipe(
        filter(m => m === null),
        sampleTime(timeout),
        takeUntil($value)
    );

    return merge($value, $null);
}
