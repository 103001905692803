import * as React from 'react';

import {PersistedMoment} from '../../../models/moment';
import '../../../styles/components/web/Panel.css';
import {classNames} from '../../../support/classnames';

interface OwnProps {
    moment?: PersistedMoment;
    onClick?: any;
    border?: boolean;
    padding?: boolean;
    center?: boolean;
}

export class Panel extends React.PureComponent<OwnProps, {}> {
    public render() {
        const className = classNames({
            Panel: true,
            'no-border': this.props.border === false,
            'no-padding': this.props.padding === false,
            center: this.props.center === true
        });
        return (
            <div
                className={className}
                onClick={() => {
                    if (this.props.onClick && this.props.moment) {
                        this.props.onClick(this.props.moment);
                    }
                }}>
                {this.props.children}
            </div>
        );
    }
}
