import {Request, RequestResult} from '../request/request';

import {RequestInterceptor} from './interceptor';

export class RequestExecutor {
    private _interceptors: RequestInterceptor[] = [];

    public addInterceptor(interceptor: RequestInterceptor) {
        this._interceptors.push(interceptor);
    }

    public async execute<T>(request: Request<T>): Promise<RequestResult<T>> {
        try {
            for (const interceptor of this._interceptors) {
                request = await interceptor.next(request);
            }
            return request.execute();
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }
}
