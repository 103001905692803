import {MapLocation} from '../../models/map_location';

const loadImage = require('blueimp-load-image');

export interface GPSDegree {
    degree: number[];
    ref: string;
}

export class ImageExif {
    public static async getImageCoordinates(img: File | Blob) {
        return new Promise<MapLocation | null>(resolve => {
            loadImage.parseMetaData(
                img,
                (data: {exif: any[] | undefined}) => {
                    resolve(ImageExif.getGPSCoordinatesFromExif(data.exif));
                },
                {
                    disableImageHead: true
                }
            );
        });
    }

    public static getGPSCoordinatesFromExif(exif: any[] | undefined): MapLocation | null {
        if (!exif) {
            return null;
        }

        const lat = ImageExif.degreeToCoordinate({
            degree: exif[2],
            ref: exif[1]
        });
        const long = ImageExif.degreeToCoordinate({
            degree: exif[4],
            ref: exif[3]
        });

        if (lat === 0 && long === 0) {
            return null;
        }

        return {lat, long};
    }

    private static degreeToCoordinate(degree: GPSDegree): number {
        if (!degree.degree || degree.degree.length !== 3) {
            return 0;
        }

        let coordinate = degree.degree[0] + degree.degree[1] / 60 + degree.degree[2] / (60 * 60);

        if (degree.ref === 'S' || degree.ref === 'W') {
            coordinate = -coordinate;
        }

        return coordinate;
    }
}
