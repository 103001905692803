import * as React from 'react';

import {websiteUrl} from '../../../app_config';
import '../../../styles/components/Footer.css';

interface OwnProps {
    className?: string;
}

interface OwnState {
    showIframe: boolean;
}

export class Footer extends React.PureComponent<OwnProps, OwnState> {
    private _interval?: number;

    public state = {
        showIframe: false
    };

    public componentDidMount() {
        this._interval = window.setTimeout(() => {
            this.setState({showIframe: true});
            this._interval = undefined;
        }, 2500);
    }

    public componentWillUnmount() {
        if (this._interval) {
            window.clearTimeout(this._interval);
        }
    }

    public render() {
        return (
            <footer className={this.props.className} key={window.location.href}>
                {this.state.showIframe && <iframe title="footer" src={websiteUrl + '/footer.html'} />}
            </footer>
        );
    }
}
