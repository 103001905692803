import {Observable, Subject} from 'rxjs';
import {debounceTime, finalize, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {PersistedTrip, UnpersistedTrip} from '../../models/trip';
import {LocalForageDriver} from '../drivers/localforage_driver';
import {TripRepository} from '../trip_repository';

export class LocalForageTripRepository implements TripRepository {
    private _changesSubject = new Subject<null>();
    private tripStore = new LocalForageDriver<PersistedTrip>('trips');

    private _stream?: Observable<PersistedTrip[]>;
    public get stream(): Observable<PersistedTrip[]> {
        if (this._stream === undefined) {
            this._stream = this._changesSubject.pipe(
                startWith(this.all()),
                debounceTime(100),
                switchMap(() => this.all()),
                shareReplay(1)
            );
        }

        return this._stream;
    }

    public async all(): Promise<PersistedTrip[]> {
        return this.tripStore.all();
    }

    private _byKeyMap: Map<string, Observable<PersistedTrip | null>> = new Map();
    public byKey(key: string): Observable<PersistedTrip | null> {
        let observable = this._byKeyMap.get(key);
        if (observable === undefined) {
            observable = this._changesSubject.pipe(
                startWith(this.tripStore.get(key)),
                switchMap(async () => this.tripStore.get(key)),
                finalize(() => this._byKeyMap.delete(key)),
                shareReplay(1)
            );
            this._byKeyMap.set(key, observable);
        }
        return observable;
    }

    /**
     * @param {UnpersistedTrip} unpersistedTrip
     * @returns {Promise<PersistedTrip>}
     */
    public async store(trip: UnpersistedTrip): Promise<PersistedTrip> {
        const savedTrip = await this.tripStore.store(trip.uuid, trip);
        this._changesSubject.next(null);
        return savedTrip;
    }

    public async storeMultiple(unpersistedTrips: UnpersistedTrip[]): Promise<PersistedTrip[]> {
        const tripsMap = unpersistedTrips.map(trip => ({key: trip.uuid, value: trip}));
        const persistedTrips = await this.tripStore.storeMultiple(tripsMap);
        this._changesSubject.next(null);

        return persistedTrips;
    }

    public async update(trip: PersistedTrip): Promise<PersistedTrip> {
        if (!trip.uuid) {
            throw new Error('PersistedTrip didnt had an id in update method');
        }

        const exisitingTrip = await this.tripStore.get(trip.uuid);
        const updatedTrip = {
            ...exisitingTrip,
            ...trip
        };

        const result = await this.tripStore.store(updatedTrip.uuid, updatedTrip);
        this._changesSubject.next(null);

        return result;
    }

    public async destroy(tripUuid: string): Promise<void> {
        await this.tripStore.delete(tripUuid);
        this._changesSubject.next(null);
    }

    public async destroyMultiple(tripUuids: string[]): Promise<void> {
        await this.tripStore.deleteMultiple(tripUuids);
        this._changesSubject.next(null);
    }
}
